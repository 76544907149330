import LandingContainer from "src/containers/user/landing-container/landing-container";

function LandingPage() {
  return (
    <>
      <LandingContainer />
    </>
  );
}

export default LandingPage;
