import { InformationSpace } from "src/core/types/user.type";

export const initialValues: InformationSpace = {
  watertank: "",
  IsYourHomeOrFacilityConnectedToTheSewageSystem: "",
  WhatIsTheDurationBetweenEachLeachingProcessForTheAbsorptionPit: "",
  HowManyWaterTanksAreProvidedByTheAuthorityOrMinistryOfWater: "",
  AreGreywaterPipesSinksShowersSeparatedFromBlackwaterToilets: "",
  HowFarIsTheGreenAreaFromTheAbsorptionPit: "",
  WhatIsTheDurationBetweenEachIrrigationProcessForTheAbsorptionPit: "",
  TheSizeOfTheAbsorptionPitOnSite: "",
};
