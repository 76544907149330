import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import i18n from "src/core/configs/i18n";

export default function IfYouInterestComponent() {
  const { t } = useTranslation();

  return (
    <div
      className={`${i18n.language === "ar" ? "tablet:pt-0" : " tablet:pt-4"}`}
    >
      <div className="mobile:hidden tablet:hidden bg-intrest h-[360px] bg-cover flex flex-col pt-24 gap-10 laptop:px-12 px-24 largeDesktop:px-72">
        <div
          className={`w-[928px] ${
            i18n.language === "ar" ? "px-20" : ""
          } flex text-start text-4xl font-arRegular font-bold text-darkBlue`}
        >
          {t("component.IfYouInterestComponent.title")}
        </div>
        <div className="flex font-light laptop:text-[20px] text-[28px] text-cyan gap-2">
          {t("component.IfYouInterestComponent.description")
            .split(" ")
            .map((word, index) => (
              <span
                key={index}
                style={{
                  color:
                    word === "SOLVillion" || word === "سولفوليون"
                      ? "#3DC2F1"
                      : "inherit",
                }}
              >
                {word}{" "}
              </span>
            ))}
        </div>
        <div className="flex justify-end items-end px-16">
          <NavLink
            className={`text-base mb-0 items-start ml-6 largeDesktop:w-44 py-4 w-[169px] h-[56px] rounded-lg
         bg-darkBlue text-white cursor-pointer mobile:font-arRegular`}
            to={appRoutesObj.gettrainingAndDevelopmentPath()}
          >
            <p className="font-bold text-sm flex justify-center font-cairo">
              {t("component.button.learnMore")}
            </p>
          </NavLink>
        </div>
      </div>
      <div className="laptop:hidden largeLaptop:hidden largeDesktop:hidden mt-5 tablet:p-0 flex items-center justify-center content-center">
        <div className="pt-10 pb-10 px-5 flex text-start flex-col gap-y-10 bg-intrestMobile w-full tablet:px-6">
          <div className="flex text-3xl mobile:w-[251px] mobile:text-2xl font-arBold text-darkBlue tracking-tight">
            {t("component.IfYouInterestComponent.title")}
          </div>
          <div className="text-base leading-5 text-start text-cyan font-verdana">
            <div className="text-base w-[311px] tablet:w-auto leading-5 text-start text-cyan font-verdana">
              {t("component.IfYouInterestComponent.description")
                .split(" ")
                .map((word, index) => (
                  <span
                    key={index}
                    style={{
                      color:
                        word === "SOLVillion" || word === "سولفوليون"
                          ? "#3DC2F1"
                          : "inherit",
                    }}
                  >
                    {word}{" "}
                  </span>
                ))}
            </div>
          </div>
          <NavLink
            className="w-full flex justify-end items-end tablet:pt-6"
            to={appRoutesObj.gettrainingAndDevelopmentPath()}
          >
            <button className="w-[114px] h-[40px] bg-darkBlue text-white rounded-lg font-cairo mobile:font-arRegular">
              {t("component.button.learnMore")}
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
