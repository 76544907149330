import { useTranslation } from "react-i18next";

export default function EmptyNewsComponent() {
  const { t } = useTranslation();

  return (
    <div className="py-24 mobile:py-12 mobile:px-0  space-y-8 items-center justify-center flex flex-col gap-9">
      <img
        src="../assets/images/empty.png"
        alt=""
        className="h-[258px] w-[400px] mobile:h-[158px] mobile:w-[245px]"
      />
      <p className="text-center text-2xl leading-8 font-normal">
        {t("component.emptyNews.title")}
      </p>
    </div>
  );
}
