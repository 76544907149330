import { useState } from "react";
import { FaCircle } from "react-icons/fa";
import { IGall } from "src/core/types/user.type";

interface ImageGalleryComponentProp {
  slides: IGall[];
}
export default function ImageGalleryComponent(prop: ImageGalleryComponentProp) {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const goToSlide = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div
      style={{ backgroundImage: `url(${prop.slides[currentIndex].detail})` }}
      className={`flex w-full h-[552px] mobile:h-[275.24px] relative flex-col bg-center bg-cover duration-500`}
    >
      {/* dots */}
      <div className="flex flex-row gap-3 bg-blueImage h-20 mobile:h-12 justify-center items-center absolute py-2 w-full bottom-0">
        {prop.slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className="cursor-pointer"
          >
            <FaCircle
              className={`${currentIndex === slideIndex ? "text-lightBlue" : "text-white"}`}
            />
          </div>
        ))}
        <></>
      </div>
    </div>
  );
}
