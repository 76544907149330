import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "./App";
import { appRoutesObj } from "./app.paths";
import ChallengesPage from "./pages/user/challenges-deatils-page/challenges-deatils-page";
import LandingPage from "./pages/user/landing-page/landing-page";
import NewDetailsPage from "./pages/user/news/news-details-page";
import NewsPage from "./pages/user/news/news-page";
import GeneralInformationPage from "./pages/user/order-here-page/general-information-page/general-information-page";
import InformationSpacePage from "./pages/user/order-here-page/Information-space-page/Information-space-page";
import PaymentInformationPage from "./pages/user/order-here-page/payment-information-page/payment-information-page";
import OurProjectPage from "./pages/user/our-project-page/our-project-page";
import TrainingAndDevelopmentPage from "./pages/user/training-and-development-page/training-and-development-page";

const NotFoundPage = React.lazy(() => import("./pages/404"));

const withSuspense = (WrappedComponent: JSX.Element) => {
  return (
    <Suspense
      fallback={<div className="text-primary-200 pt-10">Loading...</div>}
    >
      {WrappedComponent}
    </Suspense>
  );
};

export function AppRouting() {
  return (
    <Suspense
      fallback={<div className="text-primary-200 pt-10">Loading...</div>}
    >
      <BrowserRouter>
        <Routes>
          <Route
            key="homepage"
            path={appRoutesObj.getBasePath()}
            element={withSuspense(<App />)}
          />
          <Route
            key="landingPage"
            path={appRoutesObj.getLandingPath()}
            element={withSuspense(<LandingPage />)}
          />
          <Route
            key="notDefined"
            path="*"
            element={withSuspense(<NotFoundPage />)}
          />
          <Route
            key="trainingAndDevelopmentPage"
            path={appRoutesObj.gettrainingAndDevelopmentPath()}
            element={withSuspense(<TrainingAndDevelopmentPage />)}
          />
          <Route
            key="challenges"
            path={appRoutesObj.getChallengePath()}
            element={withSuspense(<ChallengesPage />)}
          />
          <Route
            key="generalInformation"
            path={appRoutesObj.getorderherePath()}
            element={withSuspense(<GeneralInformationPage />)}
          />
          <Route
            key="informationSpace"
            path={appRoutesObj.getInformationSpacePath()}
            element={withSuspense(<InformationSpacePage />)}
          />
          <Route
            key="paymentInformation"
            path={appRoutesObj.getPaymentInformationPath()}
            element={withSuspense(<PaymentInformationPage />)}
          />
          <Route
            key="news"
            path={appRoutesObj.getNewsPath()}
            element={withSuspense(<NewsPage />)}
          />
          <Route
            key="newDetailsPage"
            path={appRoutesObj.getNewsDetailsPagePath()}
            element={withSuspense(<NewDetailsPage />)}
          />
          <Route
            key="ourProjectsPage"
            path={appRoutesObj.getOurProjectsPath()}
            element={withSuspense(<OurProjectPage />)}
          />
          <Route
            key="projectDetailsPage"
            path={appRoutesObj.getProjectDetailsPagePath()}
            element={withSuspense(<OurProjectPage />)}
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
