import { Form, Formik, useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import BackGroundTitleComponent from "src/components/user/back-ground-title-component/back-ground-title-component";
import CityDropDwonComponent from "src/components/user/city-drop-dwon-component/city-drop-dwon-component";
import InputFiledComponent from "src/components/user/input-filed-component/input-filed-component";
import { getHintText } from "src/core/helpers/example.helper";
import { radioDataGeneralInformation } from "src/core/statics/radio-results/radio-results";
import { GeneralInformation } from "src/core/types/user.type";

import { initialValues } from "./general-information-container.data";
import { validationSchemaGeneralInformation } from "./general-information-container.validation";

function GeneralInformationContainer() {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      sessionStorage.setItem("generalInformation", JSON.stringify(values));
      navigate(appRoutesObj.getInformationSpacePath());
    },
    validationSchema: validationSchemaGeneralInformation,
  });

  const cities = [
    "app.shared.all.places",
    "container.GeneralInformationContainer.Amman",
    "container.GeneralInformationContainer.Irbid",
    "container.GeneralInformationContainer.Jarash",
    "container.GeneralInformationContainer.Ajlun",
    "container.GeneralInformationContainer.Balqa",
    "container.GeneralInformationContainer.Madaba",
    "container.GeneralInformationContainer.Zarqa",
  ];
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div className="flex flex-col items-center justify-center mobile:gap-0">
      <BackGroundTitleComponent
        pading="px-28"
        title={"container.GeneralInformationContainer.order.here.title"}
      />
      <div className="w-full px-24 mobile:px-0">
        <div className="flex flex-col items-center w-full mt-[52px] shadow mobile:shadow-none rounded-lg">
          <Formik
            initialValues={initialValues}
            onSubmit={() => formik.submitForm()}
            validationSchema={formik.initialValues}
            enableReinitialize
          >
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <div className="flex justify-center items-center pt-[105px] mobile:pt-6 largeLaptop:px-6">
                <div className="flex items-center">
                  <div className="w-10 h-10 p-2 text-center text-white rounded-full bg-sky-500 font-verdana">
                    1
                  </div>
                  <div className="bg-gray-200 w-[360px] laptop:w-[260px] mobile:w-[100px] tablet:w-[230px] h-1.5"></div>
                </div>
                <div className="w-10 h-10 p-2 text-center bg-gray-200 rounded-full font-verdana">
                  2
                </div>
                <div className="flex items-center">
                  <div>
                    <div className="bg-gray-200 w-[360px] laptop:w-[260px] mobile:w-[100px] tablet:w-[230px] h-1.5"></div>
                  </div>
                  <div className="w-10 h-10 p-2 text-center bg-gray-200 rounded-full font-verdana">
                    3
                  </div>
                </div>
              </div>
              <div className="flex text-[19px] justify-between mobile:px-4 mobile:text-[11px] laptop:text-base font-verdana font-bold pt-4">
                <p className="text-sky-500">
                  {t("app.shared.general.information")}
                </p>
                <p className="text-cyan">{t("app.shared.Area.information")}</p>

                <p className="text-cyan">
                  {t("app.shared.payment.information")}
                </p>
              </div>
              <div className="flex gap-2 mobile:gap-0 pt-[115px] mobile:pt-10 mobile:px-3">
                <div>
                  <InputFiledComponent
                    name="mobilenum"
                    onChange={(e) => formik.handleChange(e)}
                    isValid={
                      formik.touched.mobilenum &&
                      Boolean(!formik.errors.mobilenum)
                    }
                    hint={getHintText(
                      formik.errors.mobilenum,
                      formik.touched.mobilenum,
                      formik.submitCount,
                    )}
                    aria-label="container.GeneralInformationContainer.phone.number"
                    placeholder="container.GeneralInformationContainer.please.enter.your.phone.number"
                  />
                  <InputFiledComponent
                    name="quadName"
                    onChange={(e) => formik.handleChange(e)}
                    isValid={
                      formik.touched.quadName &&
                      Boolean(!formik.errors.quadName)
                    }
                    hint={getHintText(
                      formik.errors.quadName,
                      formik.touched.quadName,
                      formik.submitCount,
                    )}
                    aria-label="container.GeneralInformationContainer.full.name"
                    placeholder="container.GeneralInformationContainer.enter.your.full.name"
                  />
                  <InputFiledComponent
                    name="loction"
                    onChange={(e) => formik.handleChange(e)}
                    isValid={
                      formik.touched.loction && Boolean(!formik.errors.loction)
                    }
                    hint={getHintText(
                      formik.errors.loction,
                      formik.touched.loction,
                      formik.submitCount,
                    )}
                    aria-label="container.GeneralInformationContainer.location"
                    placeholder="app.shared.your.location"
                  />
                  <CityDropDwonComponent
                    formik={formik}
                    name="city"
                    aria-label="component.CityDropDwonComponent.your.state"
                    onChange={(e) => formik.handleChange(e)}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    isValid={
                      formik.touched.city &&
                      Boolean(!formik.errors.city) &&
                      searchTerm !== ""
                    }
                    hint={getHintText(
                      formik.errors.city,
                      formik.touched.city,
                      formik.submitCount,
                    )}
                    cities={cities}
                  />
                  <div className="flex flex-col gap-4 pt-10 mobile:justify-center mobile:px-0">
                    {radioDataGeneralInformation[0].contentsWithTitle.map(
                      (question, index) => (
                        <div className="flex flex-col gap-3" key={index}>
                          <div className="text-[19px] mobile:text-base text-sky-500 font-normal font-verdana">
                            {t(`${question.title}`)}
                          </div>
                          {question.contents.map((option, innerIndex) => (
                            <div
                              className="flex gap-2 items-center font-normal font-verdana"
                              key={option}
                            >
                              <input
                                type="radio"
                                className="appearance-none border-gray-700 border-opacity-50 border ring-white checked:border-[6px] checked:border-sky-500 w-5 h-5 rounded-full ml-2"
                                id={`${question.name}-${innerIndex}`}
                                name={t(`${question.name}`)!}
                                value={option}
                                checked={
                                  formik.values[
                                    question.name as keyof GeneralInformation
                                  ] === option
                                }
                                onChange={formik.handleChange}
                              />
                              <label htmlFor={`${question.name}-${innerIndex}`}>
                                {t(`${option}`)}
                              </label>
                            </div>
                          ))}
                          {formik.errors[
                            question.name as keyof GeneralInformation
                          ] &&
                            formik.touched[
                              question.name as keyof GeneralInformation
                            ] && (
                              <p className="text-red-500 text-[13px] font-normal font-verdana">
                                {" "}
                                {t("app.shared.thisFieldIsRequired")}
                              </p>
                            )}
                        </div>
                      ),
                    )}
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center mt-10 mb-20">
                <button
                  onClick={scrollToTop}
                  type="submit"
                  className="flex text-white ml-16 bg-sky-500 w-[441px] mobile:w-[333px] mobile:ml-0 h-14 rounded-lg items-center justify-center"
                >
                  {t("app.shared.next")}
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default GeneralInformationContainer;
