import { IoChevronDown } from "react-icons/io5";
import { IMapRoutes } from "src/core/types/user.type";
type ItemCollapseProps = {
  item: IMapRoutes;
  isActive: boolean;
  setaActiveIndex: () => void;
  onClick: () => void;
};

function MultiLevelNavbarComponent(props: ItemCollapseProps) {
  return (
    <div
      className="flex items-center font-arRegular gap-7 text-base relative hover:opacity-70"
      onClick={() => {
        props.setaActiveIndex();
        props.onClick();
      }}
      data-te-dropdown-ref
    >
      <button
        className={`whitespace-nowrap ${
          props.isActive ? "text-darkBlue" : "text-white"
        }`}
      >
        {props.item.title}
      </button>
      <div className="flex justify-between items-center">
        <IoChevronDown
          className={`w-4 h-4 transition-transform ${
            props.isActive ? "rotate-180 duration-500" : "duration-500"
          } ${props.isActive ? "text-darkBlue" : "text-white"}`}
        />
      </div>
    </div>
  );
}

export default MultiLevelNavbarComponent;
