import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiCalendar, FiMic } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { TbTrophy } from "react-icons/tb";
import { Link, useParams } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import BackGroundTitleComponent from "src/components/user/back-ground-title-component/back-ground-title-component";
import i18n from "src/core/configs/i18n";
import { list } from "src/core/statics/challenges-mock-data/challenges-mock-data";
import { ChallengesList1 } from "src/core/types/user.type";

import NewChallengeDeatils from "./new-challenge-container";

function ChallengesDeattilsContainer() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [challengData, setchallengData] = useState<ChallengesList1 | null>(
    null
  );

  useEffect(() => {
    const selectedChallenge = list.find(
      (challenge) => challenge.id.toString() === id
    );
    setchallengData(selectedChallenge || null);
  }, [id]);

  if (!challengData) {
    return <div className="h-[1000px]">Loading...</div>;
  }

  switch (id) {
    case "1":
      return <NewChallengeDeatils challenge={challengData} />;
    default:
      return (
        <div className="flex flex-col gap-5 mobile:gap-0">
          <BackGroundTitleComponent
            pading="px-28"
            title={t(challengData?.title)}
          />
          <div className="px-32 mobile:px-0">
            <div className="flex gap-2 mobile:px-4 mt-10">
              <Link to={appRoutesObj.gettrainingAndDevelopmentPath()}>
                <p className="text-sm font-normal font-verdana text-neutral-400 leading-relaxed">
                  {t("container.challenges.title")}
                </p>
              </Link>
              <div className="text-sm flex font-normal font-verdana leading-relaxed gap-3">
                <p> {`>`}</p>
                {` ${t(challengData?.title)}`}
              </div>
            </div>
            <div className="h-auto mobile:h-auto pt-4 mobile:pt-2 mb-[68px] mobile:mb-2 mobile:px-4 tablet:pt-2 tablet:mb-2 tablet:px-4">
              <div className="h-[345px] flex justify-end items-end relative bg-cyan rounded-t-lg">
                {" "}
                <div
                  className={`flex flex-col justify-center ${
                    i18n.language === "ar" ? "rounded-tl-lg " : "rounded-tr-lg"
                  } items-center bg-cyan absolute z-10 mobile:items-center mobile:px-5 opacity-90 w-[802px] laptop:w-[600px] tablet:w-[400px] mobile:w-[307px] h-full`}
                >
                  <div
                    className={`flex flex-col   ${
                      i18n.language === "ar"
                        ? ""
                        : "px-6 mobile:px-0 mobile:w-auto"
                    }  gap-2 mobile:text-end"`}
                  >
                    {" "}
                    <p
                      className={` ${
                        i18n.language == "ar" ? "text-2xl" : "text-xl"
                      } text-sky-500 mb-4 font-arBold`}
                    >
                      {t(challengData?.subTitle)}
                      <p
                        className={`h-1 ${
                          i18n.language === "ar" ? "" : "text-sm"
                        } bg-sky-500 w-full rounded-xl mt-2`}
                      ></p>
                    </p>
                    <p
                      className={`flex gap-2 ${
                        i18n.language === "ar" ? "" : "text-sm"
                      } text-white font-normal font-verdana leading-relaxed text-[19px]`}
                    >
                      <FiCalendar
                        className={`w-6 h-6 ${
                          i18n.language === "ar" ? "mt-1" : "mt-0"
                        } text-sky-500`}
                      />{" "}
                      {t(challengData?.date)}
                    </p>
                    <div className={`flex gap-2`}>
                      <HiOutlineLocationMarker
                        className={`w-6 h-6  ${id === "4" && " absolute"}  ${
                          i18n.language === "ar" ? "mt-1" : "mt-0"
                        } text-sky-500`}
                      />
                      {id === "4" && <div className="w-14"></div>}
                      <p
                        className={`flex gap-2 text-white ${
                          i18n.language === "ar" ? "" : "text-sm"
                        } font-normal font-verdana leading-relaxed text-[19px]`}
                      >
                        {t(challengData?.locticon)}
                      </p>
                    </div>
                    <p
                      className={`flex leading-relaxed ${
                        i18n.language === "ar" ? "" : "text-sm"
                      } gap-2 text-white font-normal font-verdana text-[19px]`}
                    >
                      <p>
                        <FiMic
                          className={`w-6 h-6 text-sky-500  ${
                            i18n.language === "ar" ? "mt-1" : "mt-0"
                          }`}
                        />
                      </p>
                      <p> {t(challengData?.speaker)}</p>
                    </p>
                  </div>
                </div>
                <img
                  src={challengData.img}
                  className="rounded-t-lg opacity-55 h-[345px] w-full"
                  alt=""
                />
              </div>
              <div className="grid grid-cols-2 mobile:grid-cols-1 tablet:grid-cols-1 justify-center text-start shadow px-16 mobile:px-4 mobile:pb-4 tablet:pb-4 tablet:px-6 tablet:h-auto pt-[32px] mobile:h-auto">
                <span className="text-sky-500 flex flex-col gap-6 text-[23px] leading-loose font-normal font-verdana">
                  <span className="text-sky-500 text-[23px] leading-loose font-bold">
                    {t(challengData?.questionAndChallenge ?? "")}
                    <p className="text-[19px] flex flex-col w-[500px] mobile:w-auto text-cyan font-normal font-verdana leading-relaxed">
                      {t(challengData?.subQuestionAndChallenge ?? "")}
                      <p>{t(challengData?.subQuestionAndChallenge2 ?? "")}</p>
                      <p> {t(challengData?.subQuestionAndChallenge3 ?? "")}</p>
                    </p>
                  </span>{" "}
                  <span className="text-sky-500 text-[23px] leading-loose font-bold">
                    {t(challengData?.targetGroup ?? "")}
                    <p className="text-[19px] w-[426px] mobile:w-auto text-cyan leading-relaxed font-normal font-verdana">
                      {t(challengData?.subTargetGroup ?? "")}
                    </p>
                  </span>{" "}
                  <span className="text-sky-500 text-[23px] leading-loose font-bold">
                    {t(challengData?.partners ?? "")}
                    <p className="text-[19px] text-cyan font-normal font-verdana leading-relaxed">
                      {t(challengData?.subPartners ?? "")}
                    </p>
                  </span>
                </span>
                <span className="text-sky-500 flex flex-col gap-3 i text-[23px] leading-loose font-bold">
                  {t(challengData?.sessions ?? "")}
                  <div className="text-[19px] flex flex-col mobile:w-auto text-cyan font-normal font-verdana leading-relaxed">
                    <div className="flex gap-2">
                      <p
                        className={` ${
                          id === "6" || "11" ? " hidden" : "h-2 w-2 bg-cyan"
                        } rounded-full mt-3`}
                      ></p>
                      <p className="w-[380px]">
                        {" "}
                        {t(challengData?.subSessions ?? "")}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p
                        className={` ${
                          id === "6" || "11" ? " hidden" : "h-2 w-2 bg-cyan"
                        } rounded-full mt-3`}
                      ></p>
                      <p className="w-[380px]">
                        {" "}
                        {t(challengData?.subSession2 ?? "")}
                      </p>
                    </div>
                    {t(challengData?.subSessions3 ?? "")}
                  </div>
                  <span className="text-sky-500 text-[23px] leading-loose font-bold">
                    {t(challengData?.awards ?? "")}
                    <div className="text-[19px] w-[400px] mobile:w-[300px] gap-1 mb-4 flex flex-col pt-2 flex-flex-col text-cyan font-normal font-verdana leading-relaxed">
                      <p className="text-sky-500">
                        {t(challengData?.awards1 ?? "")}
                      </p>
                      <p className=" mobile:w-[200px]">
                        {t(challengData?.subAwards ?? "")}
                      </p>
                      <p className="text-sky-500">
                        {t(challengData?.awards2 ?? "")}
                      </p>
                      <p>{t(challengData?.subAwards2 ?? "")}</p>
                      <p className="text-sky-500">
                        {t(challengData?.awards3 ?? "")}
                      </p>
                      <p>{t(challengData?.subAwards3 ?? "")}</p>
                    </div>
                  </span>
                </span>
              </div>
              <div
                className={`h-[143px] mobile:h-auto tablet:h-auto tablet:px-2 mobile:pt-5 mobile:px-1 ${
                  i18n.language === "ar" ? "" : "px-6"
                } flex flex-col gap-4 justify-center items-center shadow-sm rounded-b-lg bg-whiteSolv`}
              >
                <p className="flex justify-center items-center gap-2 text-cyan text-[28px] font-bold font-verdana leading-[39.20px]">
                  {t(challengData?.winner ?? "")}
                  <TbTrophy className="w-8 h-8 text-sky-500" />
                </p>
                <div
                  className={`text-[19px] flex mobile:flex-col ${
                    i18n.language === "ar" ? "" : "text-sm"
                  } ${
                    id === "5" || "6" ? "flex-row" : "flex-col"
                  } gap-2 text-cyan font-normal font-verdana leading-relaxed`}
                >
                  <div
                    className={`flex gap-1 ${
                      i18n.language === "ar" ? "" : "flex-col"
                    }`}
                  >
                    <p className="text-sky-500">
                      {t(challengData?.awards1 ?? "")}
                    </p>
                    <p>{t(challengData?.nameWinner ?? "")}</p>
                  </div>
                  {i18n.language === "ar" ? (
                    <></>
                  ) : (
                    <div
                      className={`w-20 mobile:hidden ${
                        id === "2" && " hidden"
                      } ${id === "11" && " hidden"} ${id === "6" && " hidden"}`}
                    ></div>
                  )}
                  <div
                    className={`flex gap-1 ${
                      i18n.language === "ar" ? "" : "flex-col"
                    }`}
                  >
                    {" "}
                    <p className="text-sky-500">
                      {t(challengData?.awards2 ?? "")}
                    </p>
                    <p>{t(challengData?.nameWinner2 ?? "")}</p>
                  </div>
                  <div
                    className={`flex gap-1 ${
                      i18n.language === "ar" ? "" : "flex-col"
                    }`}
                  >
                    <p className="text-sky-500">
                      {t(challengData?.awards3 ?? "")}
                    </p>
                    <p>{t(challengData?.nameWinner3 ?? "")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

export default ChallengesDeattilsContainer;
