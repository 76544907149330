interface IProps {
  className?: string;
  onMouseOver?: (event: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
}
function Balqa(props: IProps) {
  return (
    <path
      className={props.className}
      onMouseOver={props.onMouseOver}
      d="M199.5 416.9l4.6-10.8 5.9-6.6-3.9-11.8 0-1.6 2-2.8-4.5-17.5 0.5-1.7 1.2-0.7 1.2-0.4 0.9-1 0.1-1.7-1.1-2.4-0.3-1.9-0.1-2.9-0.4-2.4-2-5.1-0.4-10.1 1.1-3.8 3.1-2.6-2-2.7 0.1-2.5 3.2-5.4-1-1.4 0-1.2 0.8-1 1.5-0.7 0-1.7-0.8-2.6 0.9-3.5 1.2-0.1 12.9 1.6 3 3.7 3.1 1.3 4.9 1 0.2 1.9 1.9 0.7 2.5 0.2 16.2-0.4 3.6 0.7 2.6 1.1 2.1 2.1 1.4 1.9 6.8 5.1 1.1 5.6 0.9 1.2 1.5 1.4 1.3 0.6 3.6 2.1-5.4 1.8-5.8 5.7-2.9 1.7-7.1 2.1-2.8 2.3-8.3 10-4.7 3.3-10.8 5.6-2.8 2.5-1.3 2.4 0.4 2 1.3 1.6 2 1 2.5 0.7 4.2 0.7 1.1 1.2 0.3 1.8-2.3 6.7-0.5 2.3-0.3 3.9-2.4 1.5-5 0.4-2.3 0.7-2.2 1.5-1.1 1.5-2.2 5.9-3.1 5.5-2.3 2.3-2.6 1.2-12.8-0.7-2.2-0.3z"
      id="JOBA"
      name="Balqa"
    />
  );
}

export default Balqa;
