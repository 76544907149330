import { Layout } from "src/components/common/layout/layout";
import InformationSpaceContainer from "src/containers/user/order-here-container/Information-space-container/Information-space-container";

function InformationSpacePage() {
  return (
    <Layout>
      <div>
        <InformationSpaceContainer />;
      </div>
    </Layout>
  );
}
export default InformationSpacePage;
