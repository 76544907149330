import { useTranslation } from "react-i18next";
import BackGroundTitleComponent from "src/components/user/back-ground-title-component/back-ground-title-component";
import EmptyNewsComponent from "src/components/user/news-component/empty-news-component";
import ListNewsComponent from "src/components/user/news-component/list-news-component";
import { newsMockData } from "src/core/statics/news-mock-data/news";

export default function NewsContainer() {
  const { t } = useTranslation();
  const dummyData = newsMockData;

  return (
    <div>
      <div className="h-24 mobile:h-0"></div>
      <BackGroundTitleComponent
        pading="px-28"
        mobileHighet="mobile:mb-14"
        title={t("app.shared.news")}
      />
      {dummyData.length === 0 ? <EmptyNewsComponent /> : <ListNewsComponent />}
    </div>
  );
}
