import { Layout } from "src/components/common/layout/layout";
import NewDetailsContainer from "src/containers/user/our-news-container/news-details-container";

export default function NewDetailsPage() {
  return (
    <Layout>
      <NewDetailsContainer />
    </Layout>
  );
}
