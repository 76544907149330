interface IProps {
  className?: string;
  onMouseOver?: (event: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
}

function Madaba(props: IProps) {
  return (
    <path
      className={props.className}
      onMouseOver={props.onMouseOver}
      d="M191 471.8l0.3-12.6-0.1-0.1 0.1 0.1 0.1-0.1 0-0.2-0.1-0.3 1.1-16.2 2.9-15.8 4.2-9.7 2.2 0.3 12.8 0.7 2.6-1.2 2.3-2.3 3.1-5.5 2.2-5.9 1.1-1.5 2.2-1.5 2.3-0.7 5-0.4 2.4-1.5 7-1.8 9.4 0 2.8-0.7 2.3-1 1.3 0.2 1.3 1.3 0.4 15.8-0.4 2-1.1 2-3.2 3.9-0.9 1.6-0.2 1.9 0.3 2.2 1.4 5.6 1.4 3.4 8 8.7 1.2 2.8-0.2 2.1-0.6 1.5 0.2 1.8 1.1 1.3 1.4 1.5 0.4 1.3-0.6 1.4-2.6 3.7-1.2 2.4-3.2 9.8-0.8 1.4-1.4 1.5-1.1 0.9-2 1.9-8.3-1.9-4-0.1-6.3 0.7-5.7 1.8-2.6 0-3.5-0.7-18.6-6.3-14.1-0.5-4 1z"
      id="JOMD"
      name="Madaba"
    />
  );
}

export default Madaba;
