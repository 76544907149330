import { Layout } from "src/components/common/layout/layout";
import HeroSectionComponent from "src/components/user/hero-section-component/hero-section-component";
import IfYouInterestComponent from "src/components/user/if-you-interest-component/if-you-interest-component";
import JordanMapComponent from "src/components/user/jordan-map-component/jordan-map-component/jordan-map-component";
import OurInfluenceComponent from "src/components/user/Our-Influence-component/Our-Influence-component";
import NewsComponent from "src/components/user/our-news-component/our-news-component/our-news-component";
import ServicesComponent from "src/components/user/our-services-component/our-services-component";
import SolvillionInNumbersComponent from "src/components/user/solv-in-numbers-component/solvillion-in-numbers-component";

function LandingContainer() {
  return (
    <Layout>
      <>
        <HeroSectionComponent />
        <SolvillionInNumbersComponent />
        <ServicesComponent />
        <JordanMapComponent />
        <IfYouInterestComponent />
        <NewsComponent />
        <OurInfluenceComponent />
      </>
    </Layout>
  );
}

export default LandingContainer;
