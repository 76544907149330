import { useTranslation } from "react-i18next";
import { DirectionEnum } from "src/core/enums/design-system.enum";
export interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isValid?: boolean;
  hint?: string;
}
export function InputFiledComponent({
  hint,
  isValid,
  ...props
}: InputFieldProps): JSX.Element {
  const getValidationPaddingClass = () => {
    switch (isValid) {
      default:
        return "px-2";
    }
  };
  const getValidationClass = () => {
    switch (isValid) {
      case true:
        return "";
      case false:
        return "border border-red-500 outline-none";
      default:
        return "";
    }
  };

  const getHintClass = () => {
    switch (isValid) {
      case true:
        return "text-success";
      case false:
        return "text-red-500";
      default:
        return "text-muted";
    }
  };

  const { t } = useTranslation();

  return (
    <div className="w-full mb-4">
      {props["aria-label"] && (
        <label
          className={`block px-2 w-full mobile:w-[361px] mobile:px-2 mb-2 text-[19px] mobile:text-base text-sky-500 font-normal font-sans `}
        >
          {t(`${props["aria-label"]}`)}
        </label>
      )}
      <div className="relative mobile:flex mobile:px-0">
        <input
          {...props}
          placeholder={t(`${props.placeholder}`)!}
          className={`bg-darkWhite text-start placeholder:text-neutral-400
           placeholder:text-[13px] placeholder:text-start placeholder:font-openSans
           text-black h-11 w-[385px] mobile:w-[361px] rounded-lg text-base
            ${getValidationClass()}
            disabled:bg-muted disabled:border-muted dark:bg-dark-700
             focus:outline-none ${
               props.className ?? ""
             }${getValidationPaddingClass()}`}
        />
        <div
          className={`absolute top-5 flex items-center justify-center space-x-1 ${
            props.dir === DirectionEnum.RTL ? "left-3" : "right-3"
          }`}
        >
          {props.type !== "date"}
        </div>
      </div>
      {hint && (
        <p
          className={`mobile:px-2 pt-1 font-light text-[13px] px-2 ${getHintClass()}`}
        >
          {hint}
        </p>
      )}
    </div>
  );
}

export default InputFiledComponent;
