interface IProps {
  className?: string;
  onMouseOver?: (event: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
}

function Mafraq(props: IProps) {
  return (
    <path
      className={props.className}
      onMouseOver={props.onMouseOver}
      d="M295.1 272.2l1.4-1.9 0.3-1.7-0.6-3.2-1-2.2-0.9-1.8-0.3-1.8 0.7-1.8 2.6-2.2 2.3-0.3 2.1 0.7 3.4 2.7 1.6 0.3 1.4-0.5 1.3-5.7-2.3-18 2.6 0.1 6.9-1 1.2 0.1 1.9 0.8 1.1 0.2 0.9-0.4 2.1-2 1-0.3 2 1.1 6.1 6.1 11.9 8.3 16.4 15.5 2.7 1.6 3.7 1.1 10.4 1.1 3 1.9 6.8 0.8 25.4 3.1 6.7 5.1 3.3-1.9 4 0.1 11.9 3.2 2.6 0.1 2.3-0.8 29.9-20.5 28.4-18.6 0.1 0 20.5-13.2 31.8-20.5 14.7-9.4 17.1-11 31.9-20.5 31.8-20.5 23.6-15.3 32.4-20.4 15.7-9.9 39.8-25.1 45.5-28.3 8.6 31.7 7.7 28.4 6.4 23.6 8.5 31.4 8.8 32.4 12.4 46.1-14.3 4.5-0.2 0.1-0.1 0.2 0.1 0.2 0.2 0.4 9.1 31.6 1.4 3.3 1.8 1.1 35.2-9.8 3.8 2.3 2.8 6.7 3.9 14.8-4.8 7-22.3 19.1 0 1.7-1.9 0.6-3.6 2.7-22 21-6.5 2.8-21.1 6.1-41.7 12.2-41.8 12.1-41.7 12.1-35.1 10.2-6.7 1.9-35.1 10.6-0.1-0.1-0.3-0.1-230.5-80.1-6.6-3.9-5.9-5.5-3.2-1.8-10.1-1-3.4 0.3-2.4 1.1-2 1.5-2.3 1.1-2.8 0.1-2.9-0.9-3.3-2.4-7.4-3.7-7.6-0.7-2.3 0.3-1.9 0.5-3.9 1.5-2.5 0.3-2.9-0.8-4.5-3.5-2.3-1.4-1.9-0.3-2.5 2.6-1.9 1-2.2 0.5-2-0.4-1.9-1.3-0.9-1.5-0.1-2.1 0.5-1.6 0.8-1.3 0.3-1.5-0.6-1.5-1.6-1.7-3.8-1.3-1.9-4.5-2.4-4.1-1.3-1.1-1.5-1-0.9-1.5 0.1-2.2 1.8-3.4 8.4-9.6 2.7-5.4z"
      id="JOMA"
      name="Mafraq"
    />
  );
}

export default Mafraq;
