import { t } from "i18next";
import React from "react";
import { SlCheck } from "react-icons/sl";
import { VscClose } from "react-icons/vsc";

interface PopupProps {
  onClose: () => void;
}
const PopupComponent: React.FC<PopupProps> = ({ onClose }) => {
  return (
    <div className="fixed z-50 inset-0 overflow-y-auto flex items-center justify-center w-full h-full bg-black bg-opacity-40">
      <div className="flex justify-center items-center mobile:w-[361px] laptop:w-[900px] w-[1118px] h-[813px]">
        <div>
          <img
            className="mobile:h-[512px] mobile:rounded-lg laptop:h-[700px] tablet:h-[512px] tablet:rounded-lg"
            src="/assets/images/backgraund-popup.png"
            alt=""
          />
        </div>
        <div className="w-[1015px] laptop:w-[700px] mobile:w-[310px] tablet:w-[310px] mobile:h-[423px] tablet:h-[423px] absolute h-[656px] bg-white shadow rounded-lg">
          <div className="flex justify-end items-end p-4">
            <button className="" onClick={onClose}>
              <VscClose className="w-8 h-8 text-darkBlue1" />
            </button>
          </div>
          <div className="flex mt-10 flex-col gap-20 mobile:gap-4 tablet:gap-4 mobile:mt-0 justify-center items-center">
            <SlCheck className="w-[255px] h-[255px] mobile:w-[181px] tablet:w-[181px] tablet:h-[181px] mobile:h-[181px] text-darkBlue1" />
            <div className="flex flex-col justify-center items-center gap-6">
              <p className="text-4xl mobile:text-2xl tablet:text-2xl font-arBold text-sky-500">
                {t("component.PopupComponent.title")}
              </p>
              <p className="text-[28px] font-light mobile:text-[23px] tablet:text-[23px] tablet:text-center mobile:text-center text-darkBlue1">
                {t("component.PopupComponent.subTitle")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupComponent;
