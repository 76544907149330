import { ChallengesList1 } from "src/core/types/user.type";

export const list: ChallengesList1[] = [
  {
    id: 1,
    title: "container.NewChallengeDeatilsContainer.new.challenge",
    subTitle:
      "container.NewChallengeDeatilsContainer.new.challenge.coming.soon",
    img: "/assets/images/bg-card-new.png",
    date: "container.NewChallengeDeatilsContainer.new.challenge.coming.soon.subTitle",
    locticon: "--",
    speaker: "--",
  },
  {
    id: 2,
    title: "container.ChallengesDeattilsContainer.first.challenge",
    subTitle: "container.ChallengesDeattilsContainer.first.challenge.subtitle",
    img: "/assets/images/bg-card-1.jpeg",
    date: "container.ChallengesDeattilsContainer.first.challenge.date",
    locticon: "container.ChallengesDeattilsContainer.first.challenge.locticon",
    speaker: "container.ChallengesDeattilsContainer.first.challenge.speaker",
    sessions: "container.ChallengesDeattilsContainer.first.challenge.sessions",
    subSessions:
      "container.ChallengesDeattilsContainer.first.challenge.subSessions",
    subSession2:
      "container.ChallengesDeattilsContainer.first.challenge.subSessions2",
    questionAndChallenge:
      "container.ChallengesDeattilsContainer.first.challenge.questionAndChallenge",
    subQuestionAndChallenge:
      "container.ChallengesDeattilsContainer.first.challenge.questionAndChallengeSub",
    targetGroup:
      "container.ChallengesDeattilsContainer.first.challenge.targetGroup",
    subTargetGroup:
      "container.ChallengesDeattilsContainer.first.challenge.subTargetGroup",
    partners: "container.ChallengesDeattilsContainer.first.challenge.partners",
    subPartners:
      "container.ChallengesDeattilsContainer.first.challenge.subPartners",
    awards: "container.ChallengesDeattilsContainer.first.challenge.awards",
    subAwards:
      "container.ChallengesDeattilsContainer.first.challenge.subAwards",
    subAwards2:
      "container.ChallengesDeattilsContainer.first.challenge.subAwards2",
    subAwards3:
      "container.ChallengesDeattilsContainer.first.challenge.subAwards3",
    winner: "container.ChallengesDeattilsContainer.first.challenge.winner",
    nameWinner:
      "container.ChallengesDeattilsContainer.first.challenge.subWinner",
  },
  {
    id: 3,
    title: "container.ChallengesDeattilsContainer.second.challenge",
    subTitle: "container.ChallengesDeattilsContainer.second.challenge.subtitle",
    img: "/assets/images/bg-card-2.jpeg",
    date: "container.ChallengesDeattilsContainer.second.challenge.date",
    locticon: "container.ChallengesDeattilsContainer.second.challenge.locticon",
    speaker: "container.ChallengesDeattilsContainer.second.challenge.speaker",
    sessions: "container.ChallengesDeattilsContainer.second.challenge.sessions",
    subSessions:
      "container.ChallengesDeattilsContainer.second.challenge.subSessions",
    subSession2:
      "container.ChallengesDeattilsContainer.second.challenge.subSessions2",
    questionAndChallenge:
      "container.ChallengesDeattilsContainer.second.challenge.questionAndChallenge",
    subQuestionAndChallenge:
      "container.ChallengesDeattilsContainer.second.challenge.questionAndChallengeSub",
    targetGroup:
      "container.ChallengesDeattilsContainer.second.challenge.targetGroup",
    subTargetGroup:
      "container.ChallengesDeattilsContainer.second.challenge.subTargetGroup",
    partners: "container.ChallengesDeattilsContainer.second.challenge.partners",
    subPartners:
      "container.ChallengesDeattilsContainer.second.challenge.subPartners",
    awards: "container.ChallengesDeattilsContainer.second.challenge.awards",
    subAwards:
      "container.ChallengesDeattilsContainer.second.challenge.subAwards",
    winner: "container.ChallengesDeattilsContainer.second.challenge.winner",
    awards1: "container.ChallengesDeattilsContainer.second.challenge.awards1",
    subAwards2:
      "container.ChallengesDeattilsContainer.second.challenge.subAwards2",
    awards2: "container.ChallengesDeattilsContainer.second.challenge.awards2",
    nameWinner:
      "container.ChallengesDeattilsContainer.second.challenge.awards.subWinners",
    nameWinner2:
      "container.ChallengesDeattilsContainer.second.challenge.awards2.subWinners",
  },
  {
    id: 4,
    title: "container.ChallengesDeattilsContainer.third.challenge",
    subTitle: "container.ChallengesDeattilsContainer.third.challenge.subtitle",
    img: "/assets/images/bg-card-3.jpeg",
    date: "container.ChallengesDeattilsContainer.third.challenge.date",
    locticon: "container.ChallengesDeattilsContainer.third.challenge.locticon",
    speaker: "container.ChallengesDeattilsContainer.third.challenge.speaker",
    sessions: "container.ChallengesDeattilsContainer.third.challenge.sessions",
    subSessions:
      "container.ChallengesDeattilsContainer.third.challenge.subSessions",
    subSession2:
      "container.ChallengesDeattilsContainer.third.challenge.subSessions2",
    questionAndChallenge:
      "container.ChallengesDeattilsContainer.third.challenge.questionAndChallenge",
    subQuestionAndChallenge:
      "container.ChallengesDeattilsContainer.third.challenge.questionAndChallengeSub",
    targetGroup:
      "container.ChallengesDeattilsContainer.third.challenge.targetGroup",
    subTargetGroup:
      "container.ChallengesDeattilsContainer.third.challenge.subTargetGroup",
    partners: "container.ChallengesDeattilsContainer.third.challenge.partners",
    subPartners:
      "container.ChallengesDeattilsContainer.third.challenge.subPartners",
    awards: "container.ChallengesDeattilsContainer.third.challenge.awards",
    subAwards:
      "container.ChallengesDeattilsContainer.third.challenge.subAwards",
    winner: "container.ChallengesDeattilsContainer.third.challenge.winner",
    awards1: "container.ChallengesDeattilsContainer.third.challenge.awards1",
    subAwards2:
      "container.ChallengesDeattilsContainer.third.challenge.subAwards2",
    awards2: "container.ChallengesDeattilsContainer.third.challenge.awards2",
    nameWinner:
      "container.ChallengesDeattilsContainer.third.challenge.awards.subWinners",
    nameWinner2:
      "container.ChallengesDeattilsContainer.third.challenge.awards2.subWinners",
  },
  {
    id: 5,
    title: "container.ChallengesDeattilsContainer.forth.challenge",
    subTitle: "container.ChallengesDeattilsContainer.forth.challenge.subtitle",
    img: "/assets/images/bg-card-4.jpeg",
    date: "container.ChallengesDeattilsContainer.forth.challenge.date",
    locticon: "container.ChallengesDeattilsContainer.forth.challenge.locticon",
    speaker: "container.ChallengesDeattilsContainer.forth.challenge.speaker",
    sessions: "container.ChallengesDeattilsContainer.forth.challenge.sessions",
    subSessions:
      "container.ChallengesDeattilsContainer.forth.challenge.subSessions",
    subSession2:
      "container.ChallengesDeattilsContainer.forth.challenge.subSessions2",
    questionAndChallenge:
      "container.ChallengesDeattilsContainer.forth.challenge.questionAndChallenge",
    subQuestionAndChallenge:
      "container.ChallengesDeattilsContainer.forth.challenge.questionAndChallengeSub",
    targetGroup:
      "container.ChallengesDeattilsContainer.forth.challenge.targetGroup",
    subTargetGroup:
      "container.ChallengesDeattilsContainer.forth.challenge.subTargetGroup",
    partners: "container.ChallengesDeattilsContainer.forth.challenge.partners",
    subPartners:
      "container.ChallengesDeattilsContainer.forth.challenge.subPartners",
    awards: "container.ChallengesDeattilsContainer.forth.challenge.awards",
    subAwards:
      "container.ChallengesDeattilsContainer.forth.challenge.subAwards",
    winner: "container.ChallengesDeattilsContainer.forth.challenge.winner",
    awards1: "container.ChallengesDeattilsContainer.forth.challenge.awards1",
    subAwards2:
      "container.ChallengesDeattilsContainer.forth.challenge.subAwards2",
    awards2: "container.ChallengesDeattilsContainer.forth.challenge.awards2",
    nameWinner:
      "container.ChallengesDeattilsContainer.forth.challenge.awards.subWinners",
    nameWinner2:
      "container.ChallengesDeattilsContainer.forth.challenge.awards2.subWinners",
  },
  {
    id: 6,
    title: "container.ChallengesDeattilsContainer.five.challenge",
    subTitle: "container.ChallengesDeattilsContainer.five.challenge.subtitle",
    img: "/assets/images/bg-card-4.jpeg",
    date: "container.ChallengesDeattilsContainer.five.challenge.date",
    locticon: "container.ChallengesDeattilsContainer.five.challenge.locticon",
    speaker: "container.ChallengesDeattilsContainer.five.challenge.speaker",
    sessions: "container.ChallengesDeattilsContainer.five.challenge.sessions",
    subSessions:
      "container.ChallengesDeattilsContainer.five.challenge.subSessions",
    subSession2:
      "container.ChallengesDeattilsContainer.five.challenge.subSessions2",
    questionAndChallenge:
      "container.ChallengesDeattilsContainer.five.challenge.questionAndChallenge",
    subQuestionAndChallenge:
      "container.ChallengesDeattilsContainer.five.challenge.questionAndChallengeSub",
    targetGroup:
      "container.ChallengesDeattilsContainer.five.challenge.targetGroup",
    subTargetGroup:
      "container.ChallengesDeattilsContainer.five.challenge.subTargetGroup",
    partners: "container.ChallengesDeattilsContainer.five.challenge.partners",
    subPartners:
      "container.ChallengesDeattilsContainer.five.challenge.subPartners",
    awards: "container.ChallengesDeattilsContainer.five.challenge.awards",
    subAwards: "container.ChallengesDeattilsContainer.five.challenge.subAwards",
    winner: "container.ChallengesDeattilsContainer.five.challenge.winner",
    awards1: "container.ChallengesDeattilsContainer.five.challenge.awards1",
    subAwards2:
      "container.ChallengesDeattilsContainer.five.challenge.subAwards2",
    awards2: "container.ChallengesDeattilsContainer.five.challenge.awards2",
    awards3: "container.ChallengesDeattilsContainer.five.challenge.awards3",
    subAwards3:
      "container.ChallengesDeattilsContainer.five.challenge.subAwards3",
    nameWinner:
      "container.ChallengesDeattilsContainer.five.challenge.awards.subWinners",
    nameWinner2:
      "container.ChallengesDeattilsContainer.five.challenge.awards2.subWinners",
    nameWinner3:
      "container.ChallengesDeattilsContainer.five.challenge.awards3.subWinners",
  },
  {
    id: 7,
    title: "container.ChallengesDeattilsContainer.sixth.challenge",
    subTitle: "container.ChallengesDeattilsContainer.sixth.challenge.subtitle",
    img: "/assets/images/bg-card-4.jpeg",
    date: "container.ChallengesDeattilsContainer.sixth.challenge.date",
    locticon: "container.ChallengesDeattilsContainer.sixth.challenge.locticon",
    speaker: "container.ChallengesDeattilsContainer.sixth.challenge.speaker",
    sessions: "container.ChallengesDeattilsContainer.sixth.challenge.sessions",
    subSessions:
      "container.ChallengesDeattilsContainer.sixth.challenge.subSessions",
    subSession2:
      "container.ChallengesDeattilsContainer.sixth.challenge.subSessions2",
    questionAndChallenge:
      "container.ChallengesDeattilsContainer.sixth.challenge.questionAndChallenge",
    subQuestionAndChallenge:
      "container.ChallengesDeattilsContainer.sixth.challenge.questionAndChallengeSub",
    targetGroup:
      "container.ChallengesDeattilsContainer.sixth.challenge.targetGroup",
    subTargetGroup:
      "container.ChallengesDeattilsContainer.sixth.challenge.subTargetGroup",
    partners: "container.ChallengesDeattilsContainer.sixth.challenge.partners",
    subPartners:
      "container.ChallengesDeattilsContainer.sixth.challenge.subPartners",
    awards: "container.ChallengesDeattilsContainer.sixth.challenge.awards",
    subAwards:
      "container.ChallengesDeattilsContainer.sixth.challenge.subAwards",
    winner: "container.ChallengesDeattilsContainer.sixth.challenge.winner",
    awards1: "container.ChallengesDeattilsContainer.sixth.challenge.awards1",
    subAwards2:
      "container.ChallengesDeattilsContainer.sixth.challenge.subAwards2",
    awards2: "container.ChallengesDeattilsContainer.sixth.challenge.awards2",
    nameWinner:
      "container.ChallengesDeattilsContainer.sixth.challenge.awards.subWinners",
    nameWinner2:
      "container.ChallengesDeattilsContainer.sixth.challenge.awards2.subWinners",
  },
  {
    id: 8,
    title: "container.ChallengesDeattilsContainer.seventh.challenge",
    subTitle:
      "container.ChallengesDeattilsContainer.seventh.challenge.subtitle",
    img: "/assets/images/bg-card-4.jpeg",
    date: "container.ChallengesDeattilsContainer.seventh.challenge.date",
    locticon:
      "container.ChallengesDeattilsContainer.seventh.challenge.locticon",
    speaker: "container.ChallengesDeattilsContainer.seventh.challenge.speaker",
    sessions:
      "container.ChallengesDeattilsContainer.seventh.challenge.sessions",
    subSessions:
      "container.ChallengesDeattilsContainer.seventh.challenge.subSessions",
    subSession2:
      "container.ChallengesDeattilsContainer.seventh.challenge.subSessions2",
    questionAndChallenge:
      "container.ChallengesDeattilsContainer.seventh.challenge.questionAndChallenge",
    subQuestionAndChallenge:
      "container.ChallengesDeattilsContainer.seventh.challenge.questionAndChallengeSub",
    subQuestionAndChallenge2:
      "container.ChallengesDeattilsContainer.seventh.challenge.questionAndChallengeSub2",
    subQuestionAndChallenge3:
      "container.ChallengesDeattilsContainer.seventh.challenge.questionAndChallengeSub3",
    targetGroup:
      "container.ChallengesDeattilsContainer.seventh.challenge.targetGroup",
    subTargetGroup:
      "container.ChallengesDeattilsContainer.seventh.challenge.subTargetGroup",
    partners:
      "container.ChallengesDeattilsContainer.seventh.challenge.partners",
    subPartners:
      "container.ChallengesDeattilsContainer.seventh.challenge.subPartners",
    awards: "container.ChallengesDeattilsContainer.seventh.challenge.awards",
    subAwards:
      "container.ChallengesDeattilsContainer.seventh.challenge.subAwards",
    winner: "container.ChallengesDeattilsContainer.seventh.challenge.winner",
    awards1: "container.ChallengesDeattilsContainer.seventh.challenge.awards1",
    subAwards2:
      "container.ChallengesDeattilsContainer.seventh.challenge.subAwards2",
    awards2: "container.ChallengesDeattilsContainer.seventh.challenge.awards2",
    nameWinner:
      "container.ChallengesDeattilsContainer.seventh.challenge.awards.subWinners",
    nameWinner2:
      "container.ChallengesDeattilsContainer.seventh.challenge.awards2.subWinners",
  },
  {
    id: 9,
    title: "container.ChallengesDeattilsContainer.eighth.challenge",
    subTitle: "container.ChallengesDeattilsContainer.eighth.challenge.subtitle",
    img: "/assets/images/bg-card-4.jpeg",
    date: "container.ChallengesDeattilsContainer.eighth.challenge.date",
    locticon: "container.ChallengesDeattilsContainer.eighth.challenge.locticon",
    speaker: "container.ChallengesDeattilsContainer.eighth.challenge.speaker",
    sessions: "container.ChallengesDeattilsContainer.eighth.challenge.sessions",
    subSessions:
      "container.ChallengesDeattilsContainer.eighth.challenge.subSessions",
    subSession2:
      "container.ChallengesDeattilsContainer.eighth.challenge.subSessions2",
    questionAndChallenge:
      "container.ChallengesDeattilsContainer.eighth.challenge.questionAndChallenge",
    subQuestionAndChallenge:
      "container.ChallengesDeattilsContainer.eighth.challenge.questionAndChallengeSub",
    subQuestionAndChallenge2:
      "container.ChallengesDeattilsContainer.eighth.challenge.questionAndChallengeSub2",
    subQuestionAndChallenge3:
      "container.ChallengesDeattilsContainer.eighth.challenge.questionAndChallengeSub3",
    targetGroup:
      "container.ChallengesDeattilsContainer.eighth.challenge.targetGroup",
    subTargetGroup:
      "container.ChallengesDeattilsContainer.eighth.challenge.subTargetGroup",
    partners: "container.ChallengesDeattilsContainer.eighth.challenge.partners",
    subPartners:
      "container.ChallengesDeattilsContainer.eighth.challenge.subPartners",
    awards: "container.ChallengesDeattilsContainer.eighth.challenge.awards",
    subAwards:
      "container.ChallengesDeattilsContainer.eighth.challenge.subAwards",
    winner: "container.ChallengesDeattilsContainer.eighth.challenge.winner",
    awards1: "container.ChallengesDeattilsContainer.eighth.challenge.awards1",
    subAwards2:
      "container.ChallengesDeattilsContainer.eighth.challenge.subAwards2",
    awards2: "container.ChallengesDeattilsContainer.eighth.challenge.awards2",
    nameWinner:
      "container.ChallengesDeattilsContainer.eighth.challenge.awards.subWinners",
    nameWinner2:
      "container.ChallengesDeattilsContainer.eighth.challenge.awards2.subWinners",
  },
  {
    id: 10,
    title: "container.ChallengesDeattilsContainer.ninth.challenge",
    subTitle: "container.ChallengesDeattilsContainer.ninth.challenge.subtitle",
    img: "/assets/images/bg-card-4.jpeg",
    date: "container.ChallengesDeattilsContainer.ninth.challenge.date",
    locticon: "container.ChallengesDeattilsContainer.ninth.challenge.locticon",
    speaker: "container.ChallengesDeattilsContainer.ninth.challenge.speaker",
    sessions: "container.ChallengesDeattilsContainer.ninth.challenge.sessions",
    subSessions:
      "container.ChallengesDeattilsContainer.ninth.challenge.subSessions",
    subSession2:
      "container.ChallengesDeattilsContainer.ninth.challenge.subSessions2",
    questionAndChallenge:
      "container.ChallengesDeattilsContainer.ninth.challenge.questionAndChallenge",
    subQuestionAndChallenge:
      "container.ChallengesDeattilsContainer.ninth.challenge.questionAndChallengeSub",
    subQuestionAndChallenge2:
      "container.ChallengesDeattilsContainer.ninth.challenge.questionAndChallengeSub2",
    subQuestionAndChallenge3:
      "container.ChallengesDeattilsContainer.ninth.challenge.questionAndChallengeSub3",
    targetGroup:
      "container.ChallengesDeattilsContainer.ninth.challenge.targetGroup",
    subTargetGroup:
      "container.ChallengesDeattilsContainer.ninth.challenge.subTargetGroup",
    partners: "container.ChallengesDeattilsContainer.ninth.challenge.partners",
    subPartners:
      "container.ChallengesDeattilsContainer.ninth.challenge.subPartners",
    awards: "container.ChallengesDeattilsContainer.ninth.challenge.awards",
    subAwards:
      "container.ChallengesDeattilsContainer.ninth.challenge.subAwards",
    winner: "container.ChallengesDeattilsContainer.ninth.challenge.winner",
    awards1: "container.ChallengesDeattilsContainer.ninth.challenge.awards1",
    subAwards2:
      "container.ChallengesDeattilsContainer.ninth.challenge.subAwards2",
    awards2: "container.ChallengesDeattilsContainer.ninth.challenge.awards2",
    nameWinner:
      "container.ChallengesDeattilsContainer.ninth.challenge.awards.subWinners",
    nameWinner2:
      "container.ChallengesDeattilsContainer.ninth.challenge.awards2.subWinners",
  },
  {
    id: 11,
    title: "container.ChallengesDeattilsContainer.tenth.challenge",
    subTitle: "container.ChallengesDeattilsContainer.tenth.challenge.subtitle",
    img: "/assets/images/bg-card-4.jpeg",
    date: "container.ChallengesDeattilsContainer.tenth.challenge.date",
    locticon: "container.ChallengesDeattilsContainer.tenth.challenge.locticon",
    speaker: "container.ChallengesDeattilsContainer.tenth.challenge.speaker",
    sessions: "container.ChallengesDeattilsContainer.tenth.challenge.sessions",
    subSessions:
      "container.ChallengesDeattilsContainer.tenth.challenge.subSessions",
    subSession2:
      "container.ChallengesDeattilsContainer.tenth.challenge.subSessions2",
    subSessions3:
      "container.ChallengesDeattilsContainer.tenth.challenge.subSessions3",
    questionAndChallenge:
      "container.ChallengesDeattilsContainer.tenth.challenge.questionAndChallenge",
    subQuestionAndChallenge:
      "container.ChallengesDeattilsContainer.tenth.challenge.questionAndChallengeSub",
    targetGroup:
      "container.ChallengesDeattilsContainer.tenth.challenge.targetGroup",
    subTargetGroup:
      "container.ChallengesDeattilsContainer.tenth.challenge.subTargetGroup",
    partners: "container.ChallengesDeattilsContainer.tenth.challenge.partners",
    subPartners:
      "container.ChallengesDeattilsContainer.tenth.challenge.subPartners",
    awards: "container.ChallengesDeattilsContainer.tenth.challenge.awards",
    subAwards:
      "container.ChallengesDeattilsContainer.tenth.challenge.subAwards",
    subAwards2:
      "container.ChallengesDeattilsContainer.tenth.challenge.subAwards2",
    winner: "container.ChallengesDeattilsContainer.tenth.challenge.winner",
    nameWinner:
      "container.ChallengesDeattilsContainer.tenth.challenge.subWinner",
  },
];
