import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import i18n from "src/core/configs/i18n";

import TitleComponent from "../title-component/title-component";

export default function SolvillionInNumbersComponent() {
  const { t } = useTranslation();
  const Data = [
    {
      title: t(
        "component.SolvillionInNumbersComponent.systemsInstalledInJordann",
      ),
      animateTo: 64,
    },
    {
      title: t(
        "component.SolvillionInNumbersComponent.beneficiariesOfOurProducts",
      ),
      animateTo: 400,
      string: "K",
    },
    {
      title: t("component.SolvillionInNumbersComponent.amountOfWaterSaved"),
      animateTo: 345,
      string: "K",
    },
    {
      title: t("component.SolvillionInNumbersComponent.trainersInSolvillion"),
      animateTo: 30,
    },
    {
      title: t("app.shared.solvillionChalange"),
      animateTo: 11,
    },
  ];
  const MobileData = [
    {
      title: t("component.SolvillionInNumbersComponent.amountOfWaterSaved"),
      animateTo: 345,
      string: "K",
    },
    {
      title: t(
        "component.SolvillionInNumbersComponent.beneficiariesOfOurProducts",
      ),
      animateTo: 400,
      string: "K",
    },
    {
      title: t(
        "component.SolvillionInNumbersComponent.systemsInstalledInJordann",
      ),
      animateTo: 64,
    },
    {
      title: t("app.shared.solvillionChalange"),
      animateTo: 11,
    },
    {
      title: t("component.SolvillionInNumbersComponent.trainersInSolvillion"),
      animateTo: 30,
    },
  ];

  return (
    <div className="antialiased h-[60vh] w-full flex justify-center items-center bg-white mobile:h-auto tablet:h-auto tablet:pt-5">
      <div className="items-center justify-center space-y-28 mobile:space-y-8 mobile:py-16 tablet:space-y-12 tablet:py-16">
        <TitleComponent
          title={t("component.SolvillionInNumbersComponent.solvillionInNumber")}
          width={20}
          widthMobile={24}
        />
        <div className="flex gap-4 items-center font-verdanaBold justify-center mobile:hidden tablet:hidden">
          {Data.map((item, index) => (
            <>
              <div
                key={index}
                className="flex flex-col items-center gap-10 laptop:gap-6"
              >
                <div
                  dir="ltr"
                  className="flex justify-center items-center gap-2"
                >
                  <CountUp
                    className="text-5xl pt-2 font-black justify-center text-darkBlue"
                    end={item.animateTo}
                    useGrouping={false}
                  />
                  <p className="text-5xl text-darkBlue pt-2 font-black justify-center">
                    {item.string}
                  </p>
                </div>
                <p
                  className={`laptop:text-lg text-cyan font-arBold px-4 ${
                    i18n.language === "ar"
                      ? "text-2xl"
                      : "text-xl laptop:text-base laptop:px-0"
                  }`}
                >
                  {item.title}
                </p>
              </div>
              {index === Data.length - 1 ? null : (
                <hr className="bg-darkBlue h-36 w-0.5" />
              )}
            </>
          ))}
        </div>
        <div className="laptop:hidden largeLaptop:hidden largeDesktop:hidden">
          {MobileData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center gap-10 laptop:gap-6 mobile:gap-10"
            >
              <div
                dir="ltr"
                className="flex justify-center items-center gap-2 mobile:mt-6"
              >
                <CountUp
                  className="text-5xl pt-2 font-black justify-center text-darkBlue"
                  end={item.animateTo}
                  useGrouping={false}
                />
                <p className="text-5xl text-darkBlue pt-2 font-black justify-center">
                  {item.string}
                </p>
              </div>
              <p
                className={`laptop:text-lg text-cyan font-arBold px-4 ${
                  i18n.language === "ar"
                    ? "text-2xl"
                    : "text-xl laptop:text-base laptop:px-0"
                }`}
              >
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
