import { Form, Formik, useFormik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import BackGroundTitleComponent from "src/components/user/back-ground-title-component/back-ground-title-component";
import InputFiledComponent from "src/components/user/input-filed-component/input-filed-component";
import { getHintText } from "src/core/helpers/example.helper";
import { radioDataInformationSpace } from "src/core/statics/radio-results/radio-results";
import { InformationSpace } from "src/core/types/user.type";

import { initialValues } from "./Information-space-container.data";
import { validationSchemaGeneralInformation } from "./Information-space-container.validation";
function InformationSpaceContainer() {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      sessionStorage.setItem("informationSpace", JSON.stringify(values));
      navigate(appRoutesObj.getPaymentInformationPath());
    },
    validationSchema: validationSchemaGeneralInformation,
  });

  const { t } = useTranslation();
  useEffect(() => {
    try {
      const savedDataString = sessionStorage.getItem("generalInformation");
      if (savedDataString) {
        const savedData: { [key: string]: any } = JSON.parse(savedDataString);
        formik.setValues((prevValues) => ({ ...prevValues, ...savedData }));
      }
    } catch (error) {
      //   console.error("Error parsing saved data:", error);
    }
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div className="flex flex-col items-center justify-center mobile:gap-0">
      <BackGroundTitleComponent
        pading="px-28"
        title={"container.GeneralInformationContainer.order.here.title"}
      />
      <div className="w-full px-24 mobile:px-0">
        <div className="flex flex-col items-center w-full mt-[52px] shadow mobile:shadow-none rounded-lg">
          <Formik
            initialValues={initialValues}
            onSubmit={() => formik.submitForm()}
            validationSchema={formik.initialValues}
            enableReinitialize
          >
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <div className="flex justify-center items-center pt-[105px] mobile:pt-6 largeLaptop:px-6">
                <div className="flex items-center">
                  <div className="w-10 h-10 p-2 text-center text-white rounded-full bg-sky-500 font-verdana">
                    1
                  </div>
                  <div className="bg-sky-500 w-[360px] laptop:w-[260px] mobile:w-[100px] tablet:w-[230px] h-1.5"></div>
                </div>
                <div className="w-10 h-10 p-2 text-white text-center bg-sky-500 rounded-full font-verdana">
                  2
                </div>
                <div className="flex items-center">
                  <div>
                    <div className="bg-gray-200 w-[360px] laptop:w-[260px] mobile:w-[100px] tablet:w-[230px] h-1.5"></div>
                  </div>
                  <div className="w-10 h-10 p-2 text-center bg-gray-200 rounded-full font-verdana">
                    3
                  </div>
                </div>
              </div>
              <div className="flex text-[19px] justify-between mobile:px-1 mobile:text-[11px] laptop:text-base font-verdana font-bold pt-4">
                <p className="text-sky-500 mobile:w-[130px]">
                  {t("app.shared.general.information")}
                </p>
                <p className="text-sky-500 mobile:w-[130px]">
                  {t("app.shared.Area.information")}
                </p>

                <p className="text-cyan">
                  {t("app.shared.payment.information")}
                </p>
              </div>
              <div className="flex gap-2 mobile:items-center pt-[115px] mobile:px-3">
                <div className="mobile:justify-center mobile:items-center">
                  <div className="flex flex-col items-start justify-start gap-4 pt-5 mobile:justify-center">
                    {radioDataInformationSpace[0].contentsWithTitle.map(
                      (question, index) => (
                        <div
                          className="flex flex-col items-start justify-start gap-3 text-start px-2"
                          key={index}
                        >
                          <div className="text-[19px] text-sky-500 font-normal font-verdana">
                            {t(`${question.title}`)}
                          </div>
                          {question.contents.map((option, innerIndex) => (
                            <div
                              className="flex justify-start gap-2 items-center font-normal font-verdana"
                              key={option}
                            >
                              {" "}
                              <input
                                type="radio"
                                className="appearance-none border-gray-700 border-opacity-50 border ring-white checked:border-[6px] checked:border-sky-500 w-5 h-5 rounded-full ml-2"
                                id={`${question.name}-${innerIndex}`}
                                name={t(`${question.name}`)!}
                                value={option}
                                checked={
                                  formik.values[
                                    question.name as keyof InformationSpace
                                  ] === option
                                }
                                onChange={formik.handleChange}
                              />
                              <label
                                className="font-verdana"
                                htmlFor={`${question.name}-${innerIndex}`}
                              >
                                {t(`${option}`)}
                              </label>
                            </div>
                          ))}
                          {formik.errors[
                            question.name as keyof InformationSpace
                          ] &&
                            formik.touched[
                              question.name as keyof InformationSpace
                            ] && (
                              <p className="text-red-500 text-[13px] font-normal font-verdana">
                                {" "}
                                {t("app.shared.thisFieldIsRequired")}
                              </p>
                            )}
                        </div>
                      ),
                    )}
                    <div className="px-0 pt-4 mobile:px-0">
                      <InputFiledComponent
                        name="watertank"
                        onChange={(e) => formik.handleChange(e)}
                        isValid={
                          formik.touched.watertank &&
                          Boolean(!formik.errors.watertank)
                        }
                        hint={getHintText(
                          formik.errors.watertank,
                          formik.touched.watertank,
                          formik.submitCount,
                        )}
                        aria-label="container.InformationSpaceContainer.How.big.is.each.water.tank"
                        placeholder="container.InformationSpaceContainer.Please.enter.the.water.tank.size"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                onClick={scrollToTop}
                className="flex items-center justify-center mt-10 mb-20"
              >
                <button className="flex text-white ml-16 bg-sky-500 w-[49%] mobile:w-[333px] mobile:ml-0 h-14 rounded-lg items-center justify-center">
                  {t("app.shared.next")}
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default InformationSpaceContainer;
