import { useTranslation } from "react-i18next";

export default function EmptyProjectsComponent() {
  const { t } = useTranslation();

  return (
    <div className="py-24 mobile:py-12 mobile:px-0 px-[520px] tablet:px-0 laptop:px-0 space-y-8 items-center justify-center flex flex-col gap-9">
      <img
        src="/assets/images/empty.png"
        alt=""
        className="h-[258px] w-[400px] mobile:h-[158px] mobile:w-[245px]"
      />
      <p className="text-center text-2xl leading-8 font-normal">
        {t("component.EmptyProjectsComponent.title")}
      </p>
    </div>
  );
}
