import { t } from "i18next";
import React, { ChangeEvent } from "react";
import { MdOutlineFileUpload } from "react-icons/md";

export const FileInputComponent: React.FC<{
  isValid?: boolean;
  hint?: string;
  name?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFileSelect: (file: File) => void;
}> = ({ hint, isValid, onChange, onFileSelect, name, ...props }) => {
  const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      onFileSelect(file);
    }
  };

  const getValidationClass = () => {
    switch (isValid) {
      case true:
        return "";
      case false:
        return "border border-red-500 border-dashed";
      default:
        return "";
    }
  };

  const getHintClass = () => {
    switch (isValid) {
      case true:
        return "text-success";
      case false:
        return "text-red-500";
      default:
        return "text-muted";
    }
  };

  return (
    <div id="dropzone-file">
      <label
        id="dropzone-file"
        className={`flex flex-col items-center justify-center w-[344px] bg-colorWhite1 h-[50px]  border-2 border-sky-500 border-dashed${getValidationClass()} rounded-lg cursor-pointer`}
      >
        <div className="flex items-center justify-center gap-2">
          <p className="text-[13px] text-sky-500 ">
            {t("component.FileInputComponent.placeholder")}
          </p>{" "}
          <MdOutlineFileUpload className="w-6 h-6 text-sky-500" />
        </div>
        <input
          {...props}
          name={name}
          onChangeCapture={handleFileInput}
          onChange={onChange}
          id="dropzone-file"
          type="file"
          className="hidden"
        />
      </label>
      {hint && (
        <p
          className={`mobile:px-2 pt-1 font-light text-[13px] px-2 ${getHintClass()}`}
        >
          {hint}
        </p>
      )}
    </div>
  );
};

export default FileInputComponent;
