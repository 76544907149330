import React from "react";
import { useTranslation } from "react-i18next";
import { HiChevronDown } from "react-icons/hi";

interface SearchPlaceComponentProps {
  title: string;
  currentValue: string;
  onClick: (value: string) => void;
  selectionList: string[];
}

export default function SearchPlaceComponent(props: SearchPlaceComponentProps) {
  const { i18n, t } = useTranslation();
  const [showList, setShowList] = React.useState<boolean>(false);
  const [selectedValue, setSelectedValue] = React.useState<string>(
    props.currentValue,
  );

  const handleSelection = (value: string) => {
    setSelectedValue(value);
    setShowList(false);
    props.onClick(value);
  };

  return (
    <div
      className={`flex ${
        i18n.language == "en" ? "justify-start" : "justify-end"
      }   mobile:w-full tablet:w-full`}
    >
      <div
        onClick={() => setShowList(!showList)}
        className={`${
          i18n.language === "ar" ? "flex-row-reverse" : "flex-row-reverse"
        } flex w-[280px] mobile:w-[250px] tablet:w-full items-center relative justify-between gap-2 bg-searchBg border border-searchBorder mobile:rounded-lg rounded-t-lg shadow-md px-2 py-2 cursor-pointer`}
      >
        <HiChevronDown
          className={`${
            showList ? "rotate-180 duration-500" : "duration-500"
          } ${
            i18n.language === "ar" ? "mobile:ml-3" : "mobile:mr-3"
          } text-searchText text-2xl`}
        />
        <div
          className={`${
            i18n.language === "ar" ? "mobile:mr-3" : "mobile:ml-3"
          } flex flex-col mobile:gap-2 tablet:gap-2`}
        >
          <div
            className={`${
              i18n.language === "ar" ? "text-base" : "text-base"
            } font-arMedium mobile:text-base text-searchText font-verdana`}
          >
            {t(selectedValue) ? <></> : t(props.title)}
          </div>
          <div
            className={`${
              i18n.language === "ar" ? "text-base" : "text-base"
            } font-arMedium mobile:text-base text-zinc900 font-verdana`}
          >
            {t(selectedValue)}
          </div>
        </div>
        {showList && (
          <div
            className={`${
              i18n.language === "ar"
                ? "mobile:-mr-0 tablet:-mr-0"
                : "mobile:-ml-0 tablet:-ml-0"
            } absolute top-12 inset-0 w-[280px] h-fit font-verdana max-h-[336px] bg-searchBg rounded z-40 mobile:w-full mobile:h-[250px] mobile:top-14 tablet:top-14 tablet:w-full tablet:h-[216px] tablet:top-[75px] shadow-md py-2 px-3.5 overflow-y-auto`}
          >
            <div className="flex flex-col items-end">
              {props.selectionList.map((item, index) => (
                <React.Fragment key={index}>
                  <p
                    onClick={() => handleSelection(item)}
                    className={`${
                      i18n.language === "ar" ? "pr-4" : "pl-4"
                    } flex flex-col items-end justify-center font-ar text-M3 text-zinc900 text-base w-full h-10 hover:bg-searchHover cursor-pointer`}
                  >
                    {t(item)}
                  </p>
                  {index !== props.selectionList.length - 1 && (
                    <hr className="bg-slate-300 w-full h-[0.3px]" />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
