import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import { newsMockData } from "src/core/statics/news-mock-data/news";
import { IGallery, INews } from "src/core/types/user.type";

import BreadCrumbsComponent from "./bread-crumbs-component";
import ImageGalleryComponent from "./image-news-gallery-component";

interface NewsDetailsComponentProp {
  data: INews[];
  img?: IGallery[];
}
export default function NewsDetailsComponent({
  data,
  img,
}: NewsDetailsComponentProp) {
  const { t } = useTranslation();
  const { id = "" } = useParams();
  const newData = newsMockData.find((a) => a.id.toString() === id);
  const navigate = useNavigate();
  const [itemsNumber, SetItemsNumber] = useState<number>(5);
  const [itemsNumberMob, SetItemsNumberMob] = useState<number>(2);

  function pagination() {
    if (data!.length >= 6) {
      SetItemsNumber(itemsNumber + 6);
    }
  }
  function paginationMob() {
    if (data!.length >= 3) {
      SetItemsNumberMob(itemsNumberMob + 3);
    }
  }
  return (
    <div>
      <div className="flex mobile:flex-col tablet:flex-col gap-8 h-full">
        <div className="bg-detailsNews py-6 mobile:px-4 space-y-3 w-full h-auto rounded-lg">
          <div className="largeLaptop:hidden laptop:hidden largeDesktop:hidden mb-6">
            <BreadCrumbsComponent newName={newData?.title} />
          </div>
          <div className="flex flex-col w-full px-4">
            <div className="flex flex-col gap-4 w-full">
              <p className="font-arLight text-3xl mobile:text-3xl text-darkBlue font-normal">
                {t(`${newData?.title}`)}
              </p>
              <p className="text-[23px] font-normal font-Verdana text-zinc-400">
                {t(`${newData?.date}`)}
              </p>
            </div>
          </div>

          <div className="w-full">
            <ImageGalleryComponent slides={img!} />
          </div>
          <p className="px-4 text-justify text-[19px] font-normal font-verdana text-zinc-600">
            {t(`${newData?.paragraph}`)}
          </p>
        </div>
        <div className="bg-colorWhite1 mobile:mt-4 rounded-lg mobile:rounded-none mobile:px-0 mobile:space-y-10 py-4 mobile:py-10 flex flex-col items-center laptop:w-full w-2/3 desktop:w-[49.666667%] h-auto mobile:w-full tablet:w-full mobile:order-last">
          <p className="text-[32px] font-normal font-arLight text-darkBlue mobile:text-4xl">
            {t("component.newsDetails.latestNews")}
          </p>
          <div className="mobile:hidden tablet:hidden">
            {data?.slice(0, itemsNumber).map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() =>
                    navigate(
                      appRoutesObj.getNewsDetailsPageUrl(item.id.toString()),
                    )
                  }
                >
                  <div className="flex py-2 mt-5 gap-4 cursor-pointer">
                    <img
                      src={item.img}
                      alt="img"
                      className="w-[140px] rounded-lg h-[117px] mobile:w-[119px]"
                    />
                    <div className="flex flex-col gap-4 w-[260px]">
                      <p className="font-medium w-[260px] font-['Lama Sans'] leading-[18.20px] text-sm mobile:text-sm text-darkBlue">
                        {t(`${item.title}`)}
                      </p>
                      <p className="text-zinc-400 line-clamp-2 text-[11px] font-normal items-stretch h-[43px] text-ellipsis">
                        {t(`${item.content}`).slice(0, 50) + ".."}
                      </p>
                      <p className="text-zinc-400 text-[11px] font-normal text-end">
                        {t(`${item.date}`)}
                      </p>
                    </div>
                  </div>
                  <hr className="w-full h-3 text-black" />
                </div>
              );
            })}
          </div>
          <div className="largeLaptop:hidden laptop:hidden largeDesktop:hidden flex flex-col mobile:gap-3 w-full space-y-6">
            {data?.slice(0, itemsNumberMob).map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col w-full cursor-pointer gap-10 mobile:gap-2"
                  onClick={() =>
                    navigate(
                      appRoutesObj.getNewsDetailsPageUrl(item.id.toString()),
                    )
                  }
                >
                  <div className="flex w-full gap-9 mobile:gap-4 px-4">
                    <img
                      src={item.img}
                      alt="img"
                      className="rounded-lg w-[140px] mobile:h-[117px]"
                    />
                    <div className="flex flex-col space-y-1 w-full">
                      <p className="font-bold mobile:w-[176px] font-arRegular text-2xl mobile:text-sm text-darkBlue pb-2">
                        {t(`${item.title}`)}
                      </p>
                      <p className="text-zinc-400 text-sm mobile:overflow-ellipsis mobile:line-clamp-2">
                        {t(`${item.content}`).slice(0, 50) + ".."}
                      </p>
                      <p
                        className={`text-[11px] font-normal px-2 text-end font-['Verdana'] leading-none text-zinc-400`}
                      >
                        {t(`${item.date}`)}
                      </p>
                    </div>
                  </div>
                  <hr className="w-full h-3 mobile:h-0 text-black mobile:items-stretch mobile:stroke-2" />
                </div>
              );
            })}
          </div>
          <div
            className={`${
              itemsNumber >= 10 || itemsNumberMob >= 10 ? "hidden" : ""
            }
            text-base items-start px-10 largeDesktop:w-[189px] h-14 py-3 mt-1 w-[189px] rounded-lg bg-darkBlue text-white cursor-pointer
            mobile:items-center mobile:justify-center mobile:py-4 mobile:px-4`}
            onClick={() => {
              pagination();
              paginationMob();
            }}
          >
            <p className="font-bold text-base flex justify-center font-cairo">
              {t("component.button.showMore")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
