import LandingContainer from "./containers/user/landing-container/landing-container";

function App() {
  return (
    <div>
      <LandingContainer />
    </div>
  );
}

export default App;
