import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TbArrowNarrowLeft, TbArrowNarrowRight } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import BackGroundTitleComponent from "src/components/user/back-ground-title-component/back-ground-title-component";
import i18n from "src/core/configs/i18n";
import { list } from "src/core/statics/challenges-mock-data/challenges-mock-data";

function TrainingAndDevelopmentContainer() {
  const { t } = useTranslation();
  const [visibleCount, setVisibleCount] = useState(9);
  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 2); // Increase by 6 each time
  };
  const currentPools = list.slice(0, visibleCount);

  return (
    <div className="flex flex-col gap-[35px] mobile:gap-0 justify-center items-center">
      <BackGroundTitleComponent
        pading="px-28"
        title={"app.shared.solvillionChalange"}
      />
      <div
        className={`${
          i18n.language === "en" ? "h-[352px]" : "h-[256px]"
        } mobile:h-[100%] mobile:mt-7 w-full flex justify-center items-center bg-whiteSolv`}
      >
        <p
          className={` py-16 ${
            i18n.language == "en" ? "w-[926px] tablet:text-lg" : "w-[800px]"
          }  text-justify mobile:text-[19px] mobile:w-[329px] tablet:w-[529px] mobile:leading-relaxed text-[23px] font-normal font-verdana text-cyan`}
        >
          {t("container.TrainingAndDevelopmentContainer.solv.challenge.breif")}
        </p>
      </div>
      <div className="bg-white flex-col  w-[1264px] laptop:w-[1000px] tablet:w-[740px] mobile:w-[361px] mobile:mb-10 mobile:mt-10 mobile:h-auto  h-auto flex justify-center items-center shadow mb-[35px]">
        <div
          className={`grid grid-cols-3 mobile:grid-cols-1 tablet:grid-cols-2 gap-10 ${
            visibleCount < list.length ? "mt-24" : "mt-20"
          }`}
        >
          {currentPools?.map((item, index) => (
            <NavLink
              key={index}
              to={appRoutesObj.getChallengeUrl(item.id.toString())}
            >
              <div className="group grid items-center rounded-lg w-[283px] h-[231px] bg-cyan">
                <p className="flex z-10 text-sky-500 flex-col font-arBold items-center gap-2 rounded-xl text-2xl text-center pt-6 font-bold font-['Lama Sans'] leading-[33.60px]">
                  {t(`${item.title}`)}
                  <p className="h-1 bg-sky-500 w-[129px] rounded-xl"></p>
                </p>
                <div className="flex justify-start items-start text-start pt-5">
                  <p className="flex z-10 items-center gap-2 px-5 h-[38px] text-sky-50 text-base font-normal font-verdana leading-snug">
                    <p className="h-full bg-white group-hover:bg-sky-500 w-[1px]"></p>{" "}
                    {t(`${item.subTitle}`).length > 48
                      ? `${t(`${item.subTitle}`).substring(0, 48)}...`
                      : t(`${item.subTitle}`)}
                  </p>
                </div>
                <div
                  className={`${
                    i18n.language === "ar" ? "ml-4" : "mr-4"
                  } flex justify-end items-end`}
                >
                  <button className="w-8 z-10 h-8 flex justify-center mb-4 items-center rounded bg-sky-500">
                    {i18n.language === "ar" ? (
                      <TbArrowNarrowLeft className="w-4 h-4 relative flex-col justify-start items-start flex text-white" />
                    ) : (
                      <TbArrowNarrowRight className="w-4 h-4 relative flex-col justify-start items-start flex text-white" />
                    )}
                  </button>
                </div>
                <img
                  src={item.img}
                  className="absolute w-[283px] opacity-10 group-hover:opacity-40 h-[231px] rounded-lg"
                  alt=""
                />
              </div>
            </NavLink>
          ))}
        </div>
        {visibleCount < list.length ? (
          <button
            onClick={loadMore}
            className="w-[441px] mobile:w-[200px] mb-20 h-14 bg-sky-500 rounded-lg text-white font-cairo mt-6"
          >
            {t("component.button.showMore")}
          </button>
        ) : (
          <div className="mb-20"></div>
        )}
      </div>
    </div>
  );
}

export default TrainingAndDevelopmentContainer;
