import { Form, Formik, useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiFile } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import BackGroundTitleComponent from "src/components/user/back-ground-title-component/back-ground-title-component";
import InputFiledComponent from "src/components/user/input-filed-component/input-filed-component";
import PopupComponent from "src/components/user/success-popup-component/success-popup-component";
import FileInputComponent from "src/components/user/uplaod-file-input-component/uplaod-file-input-component";
import { getHintText } from "src/core/helpers/example.helper";
import { postData } from "src/core/services/post-data-service";
import { radioDataPaymentInformation } from "src/core/statics/radio-results/radio-results";
import { PaymentInformation } from "src/core/types/user.type";

import { initialValues } from "./payment-information-container.data";
import { validationSchemaPaymentInformation } from "./payment-information-container.validation";

function PaymentInformationContainer() {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const generalInformationString =
      sessionStorage.getItem("generalInformation");
    const generalInformation = generalInformationString
      ? JSON.parse(generalInformationString)
      : {};
    const informationSpaceString = sessionStorage.getItem("informationSpace");
    const informationSpace = informationSpaceString
      ? JSON.parse(informationSpaceString)
      : {};
    const allData = {
      ...generalInformation,
      ...informationSpace,
      ...formik.values,
    };
    try {
      await postData({
        data: [allData],
      });
      setShowPopup(true);
      sessionStorage.removeItem("generalInformation");
      sessionStorage.removeItem("informationSpace");
    } catch (error) {
      // console.error("Error sending data:", error);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async () => {
      await handleSubmit();
    },
    validationSchema: validationSchemaPaymentInformation(),
  });

  const handleFileSelect = (file: File) => {
    setSelectedFile(file);
    setUploadProgress(0);
    handleUpload(file);
  };
  const handleUpload = async (selectedFile: File) => {
    if (!selectedFile) return;
    const formData = new FormData();
    formData.append("file", selectedFile);
    const uploadSimulation = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(uploadSimulation);
          return 100;
        }
        return prevProgress + 10;
      });
    }, 100);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    navigate(appRoutesObj.getBasePath()); // Navigate after closing the popup
  };
  const handleDelete = () => {
    setSelectedFile(null);
    setUploadProgress(0);
  };

  return (
    <div className="flex flex-col items-center justify-center mobile:gap-0">
      <BackGroundTitleComponent
        pading="px-28"
        title={"container.GeneralInformationContainer.order.here.title"}
      />
      <div className="w-full px-24 mobile:px-0">
        <div className="flex flex-col items-center w-full mt-[52px] shadow mobile:shadow-none rounded-lg">
          <Formik
            initialValues={initialValues}
            onSubmit={() => formik.submitForm()}
            validationSchema={formik.initialValues}
            validateOnChange
          >
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <div className="flex justify-center items-center pt-[105px] mobile:pt-6 largeLaptop:px-6">
                <div className="flex items-center">
                  <div className="w-10 h-10 p-2 text-center text-white rounded-full bg-sky-500 font-verdana">
                    1
                  </div>
                  <div className="bg-sky-500 w-[360px] laptop:w-[260px] mobile:w-[100px] tablet:w-[230px] h-1.5"></div>
                </div>
                <div className="w-10 h-10 p-2 text-white text-center bg-sky-500 rounded-full font-verdana">
                  2
                </div>
                <div className="flex items-center">
                  <div className="">
                    <div className="bg-sky-500 w-[360px] laptop:w-[260px] mobile:w-[100px] tablet:w-[230px] h-1.5"></div>
                  </div>
                  <div className="w-10 h-10 p-2 text-center bg-sky-500 text-white rounded-full font-verdana">
                    3
                  </div>
                </div>
              </div>
              <div className="flex text-[19px] justify-between mobile:px-1 mobile:text-[11px] laptop:text-base font-verdana font-bold pt-4">
                <p className="text-sky-500 mobile:w-[130px]">
                  {t("app.shared.general.information")}
                </p>
                <p className="text-sky-500 mobile:w-[130px]">
                  {t("app.shared.Area.information")}
                </p>

                <p className="text-sky-500">
                  {t("app.shared.payment.information")}
                </p>
              </div>
              <div className="flex justify-start items-start text-start gap-2 pt-[30px] px-3">
                <div>
                  <div className="flex flex-col items-start justify-start gap-4 pt-5">
                    {radioDataPaymentInformation[0].contentsWithTitle.map(
                      (question, index) => (
                        <div
                          className="flex flex-col items-start justify-start gap-4 text-start mobile:px-0"
                          key={index}
                        >
                          <div className="text-[19px] largeLaptop:w-[734px] desktop:w-[734px] laptop:w-[734px] w-auto mobile:text-base text-sky-500 font-normal font-verdana">
                            {t(`${question.title}`)}
                          </div>
                          {question.contents.map((option, innerIndex) => (
                            <div
                              className="flex justify-start gap-2 items-center font-normal font-verdana"
                              key={option}
                            >
                              {" "}
                              <input
                                type="radio"
                                className="appearance-none border-gray-700 border-opacity-50 border ring-white checked:border-[6px] checked:border-sky-500 w-5 h-5 rounded-full ml-2"
                                id={`${question.name}-${innerIndex}`}
                                name={t(`${question.name}`)!}
                                value={option}
                                checked={
                                  formik.values[
                                    question.name as keyof PaymentInformation
                                  ] === option
                                }
                                onChange={formik.handleChange}
                              />
                              <label
                                className="font-verdana"
                                htmlFor={`${question.name}-${innerIndex}`}
                              >
                                {t(`${option}`)}
                              </label>
                            </div>
                          ))}
                          {formik.errors[
                            question.name as keyof PaymentInformation
                          ] &&
                            formik.touched[
                              question.name as keyof PaymentInformation
                            ] && (
                              <p className="text-red-500 text-[13px] font-normal font-verdana">
                                {" "}
                                {t("app.shared.thisFieldIsRequired")}
                              </p>
                            )}
                        </div>
                      ),
                    )}
                  </div>
                  <div className="flex flex-col items-start text-sans text-sky-500 text-[19px] justify-start mt-3 mobile:px-0">
                    <p className="flex mobile:static -top-1 pb-5 relative mobile:text-base">
                      {t(
                        "container.PaymentInformationContainer.Please.attach.a.photo.of.the.most.recent.water.bill",
                      )}
                    </p>
                    {!selectedFile && (
                      <div className="pt-0 mobile:pt-0">
                        <FileInputComponent
                          onChange={formik.handleChange}
                          name="upload"
                          isValid={
                            formik.touched.upload &&
                            Boolean(!formik.errors.upload)
                          }
                          hint={getHintText(
                            formik.errors.upload,
                            formik.touched.upload,
                            formik.submitCount,
                          )}
                          onFileSelect={handleFileSelect}
                        />
                      </div>
                    )}
                    <div className="mb-4" onClick={() => handleUpload}>
                      {uploadProgress > 0 && (
                        <div className="flex gap-2 w-[344px] bg-colorWhite1 h-[50px] border border-sky-500 border-dashed rounded-lg cursor-pointer justify-center items-center relative">
                          <FiFile
                            onClick={handleDelete}
                            className="w-4 h-4 text-neutral-400"
                          />
                          {uploadProgress === 100 && (
                            <>
                              {" "}
                              <div
                                onChange={formik.handleChange}
                                className={`flex absolute gap-2 w-[344px] bg-colorWhite1 h-[50px] border border-sky-500 border-dashed rounded-lg cursor-pointer justify-between px-4 items-center`}
                              >
                                <p className="flex gap-2 text-[13px] text-neutral-400">
                                  <FiFile className="w-4 h-4 text-neutral-400" />
                                  {selectedFile?.name}
                                </p>
                                <IoClose
                                  onClick={handleDelete}
                                  className="w-4 h-4 text-neutral-400"
                                />
                              </div>
                            </>
                          )}
                          <progress
                            className="progress-bar"
                            value={uploadProgress}
                            max={100}
                          />
                          <p className="text-[13px] text-neutral-400">
                            m{uploadProgress}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <p className="text-[19px] text-cyan font-bold font-sans leading-relaxed">
                    {t(
                      "container.PaymentInformationContainer.Would.you.like.to.purchase.the.system.via.installments",
                    )}
                  </p>
                  <div className="flex flex-col px-0 mobile:px-0 pt-[32px] gap-4 mt-0"></div>
                  <InputFiledComponent
                    name="watertank"
                    onChange={(e) => formik.handleChange(e)}
                    isValid={
                      formik.touched.watertank &&
                      Boolean(!formik.errors.watertank)
                    }
                    hint={getHintText(
                      formik.errors.watertank,
                      formik.touched.watertank,
                      formik.submitCount,
                    )}
                    aria-label="container.PaymentInformationContainer.How.much.can.you.pay.monthly.in.Jordanian.dinars"
                    placeholder="container.PaymentInformationContainer.Please.enter.the.expected.amount"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center mt-10 mb-20">
                <button
                  type="submit"
                  className="flex text-white ml-16 bg-sky-500 w-[49%] mobile:w-[333px] mobile:ml-0 h-14 rounded-lg items-center justify-center"
                >
                  {t("app.shared.send")}
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      {showPopup && <PopupComponent onClose={handleClosePopup} />}
    </div>
  );
}

export default PaymentInformationContainer;
