import { t } from "i18next";
import { FiCalendar, FiMic } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import BackGroundTitleComponent from "src/components/user/back-ground-title-component/back-ground-title-component";
import i18n from "src/core/configs/i18n";
import { ChallengesList1 } from "src/core/types/user.type";

interface Props {
  challenge: ChallengesList1;
}

const NewChallengeDeatilsContainer: React.FC<Props> = ({ challenge }) => {
  return (
    <div className="flex flex-col gap-5 mobile:gap-0">
      <BackGroundTitleComponent pading="px-28" title={challenge?.title} />
      <div className="px-32 mobile:px-0">
        <div className="flex gap-2 mobile:px-4 mt-10">
          <Link to={appRoutesObj.gettrainingAndDevelopmentPath()}>
            <p className="text-sm font-normal font-verdana text-neutral-400 leading-relaxed">
              {t("container.challenges.title")}
            </p>
          </Link>
          <div className="text-sm flex font-normal font-verdana leading-relaxed gap-3">
            <p> {`>`}</p>
            {` ${t(challenge?.title)}`}
          </div>
        </div>
        <div className="h-auto mobile:h-auto pt-4 mobile:pt-2 mb-[68px] mobile:mb-2 mobile:px-4 tablet:pt-2 tablet:mb-2 tablet:px-4">
          <div className="h-[345px] flex justify-end items-end relative bg-cyan rounded-t-lg">
            {" "}
            <div
              className={`flex flex-col items-center bg-cyan absolute z-10  ${
                i18n.language === "ar" ? "rounded-tl-lg " : "rounded-tr-lg"
              } mobile:items-end mobile:px-4 opacity-90 w-[802px] laptop:w-[600px] tablet:w-[420px] mobile:w-[307px] h-full`}
            >
              <div className="flex px-6 mobile:px-0 tablet:mt-2 flex-col mt-[28px] gap-2 mobile:text-end">
                {" "}
                <p
                  className={`text-[22px] text-sky-500 mb-2 font-arBold ${
                    i18n.language == "ar"
                      ? "pt-[53px]"
                      : "mobile:pt-4 pt-[53px]"
                  }`}
                >
                  {t(challenge?.subTitle)}
                  <p className="h-1 bg-sky-500 w-full rounded-xl mt-2"></p>
                </p>
                <p className="flex gap-2 w-[400px] mobile:w-auto mobile:gap-6 text-white font-normal font-verdana leading-relaxed text-[19px] mobile:text-base">
                  <FiCalendar className="w-6 h-6 mt-1 text-sky-500 absolute" />{" "}
                  <div
                    className={` ${
                      i18n.language === "ar"
                        ? "w-6 mobile:w-0"
                        : "w-20 mobile:w-2"
                    }`}
                  ></div>
                  {t(challenge?.date)}
                </p>
                <p className="flex gap-2 text-white font-normal font-verdana leading-relaxed text-[19px]">
                  <HiOutlineLocationMarker className="w-6 h-6 mt-1 text-sky-500" />
                  {t(challenge?.locticon)}
                </p>
                <p className="flex leading-relaxed gap-2 text-white font-normal font-verdana text-[19px]">
                  <FiMic className="w-6 h-6 text-sky-500 mt-1" />{" "}
                  {t(challenge?.speaker)}
                </p>
              </div>
            </div>
            <img
              src={challenge?.img}
              className="rounded-t-lg opacity-55 h-[345px] w-full"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewChallengeDeatilsContainer;
