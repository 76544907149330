interface IProps {
  className?: string;
  onMouseOver?: (event: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
}

function Zarqa(props: IProps) {
  return (
    <path
      className={props.className}
      onMouseOver={props.onMouseOver}
      d="M280.9 338.1l-3.6-2.1-1.3-0.6-1.5-1.4-0.9-1.2-1.1-5.6 7-9.4 2.1-1.7 3-1.7 2.4-1.2 1.6-1.6 0.9-1.9 0.6-4.7 3.8 1.3 1.6 1.7 0.6 1.5-0.3 1.5-0.8 1.3-0.5 1.6 0.1 2.1 0.9 1.5 1.9 1.3 2 0.4 2.2-0.5 1.9-1 2.5-2.6 1.9 0.3 2.3 1.4 4.5 3.5 2.9 0.8 2.5-0.3 3.9-1.5 1.9-0.5 2.3-0.3 7.6 0.7 7.4 3.7 3.3 2.4 2.9 0.9 2.8-0.1 2.3-1.1 2-1.5 2.4-1.1 3.4-0.3 10.1 1 3.2 1.8 5.9 5.5 6.6 3.9 230.5 80.1 0.3 0.1 0.1 0.1-10.9 3.3-46 13.8-46 13.8-38.9 11.7-0.1-0.1-0.6-0.3-52-53.3-3.4-0.8-3.2 0.9-8.8 5.4-3.5 0.1-4.3-1.6-8.1-5.6-8.3-7.7-5-5.8-1.2-2.4 0.3-2 1.4-2 4.1-3.4 5.3-3.3 1.7-2.6-0.4-3.5-5.3-7-10.5-11.6-3.9-3.2-3.9-1.8-19.6-1.7-4 0.9-2.2 1.9-3.5 4.3-2.4 1.5-3.8 0.7-12-0.1-9.1 3-3 0.2-2.3 0.5-3.6 2.1-2.5-0.8-3.6-2.6-6.2-7.6-4.5-4.2-4.3-5.6z"
      id="JOAZ"
      name="Zarqa"
    />
  );
}

export default Zarqa;
