import { t } from "i18next";
import * as Yup from "yup";

export const validationSchemaGeneralInformation = () =>
  Yup.object({
    watertank: Yup.string()
      .trim()
      .required(t("app.shared.thisFieldIsRequired").toString()),
    IsYourHomeOrFacilityConnectedToTheSewageSystem: Yup.string().required(
      "Please select an option for question 1",
    ),
    WhatIsTheDurationBetweenEachLeachingProcessForTheAbsorptionPit:
      Yup.string().required("Please select an option for question 2"),
    HowManyWaterTanksAreProvidedByTheAuthorityOrMinistryOfWater:
      Yup.string().required("Please select an option for question 3"),
    AreGreywaterPipesSinksShowersSeparatedFromBlackwaterToilets:
      Yup.string().required("Please select an option for question 4"),
    HowFarIsTheGreenAreaFromTheAbsorptionPit: Yup.string().required(
      "Please select an option for question 5",
    ),
    WhatIsTheDurationBetweenEachIrrigationProcessForTheAbsorptionPit:
      Yup.string().required("Please select an option for question 6"),
    TheSizeOfTheAbsorptionPitOnSite: Yup.string().required(
      "Please select an option for question 6",
    ),
  });
