import { makeRequest } from "../data-access/http.service";
import { HttpMethods } from "../enums/httpMethods.enum";

export const postData = async (data: object) => {
  return await makeRequest({
    url: "https://api.apispreadsheets.com/data/4ntZfVn0pIxaQsll/",
    method: HttpMethods.POST,
    data,
  });
};
