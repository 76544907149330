import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import { SystemEnum } from "src/core/enums/example.enum";
import { IProjects, IProjGall } from "src/core/types/user.type";

import ImageGalleryComponent from "../image-project-gallery-component/image-project-gallery-component";

interface ListProjectComponentProps {
  data: IProjects[];
  imgProj?: IProjGall[];
  itemsNumber: number;
  itemsNumberMob: number;
  systemType: SystemEnum | string;
  openTab?: string;
}
export default function ListProjectComponent({
  data,
  itemsNumber,
  itemsNumberMob,
  systemType,
  imgProj,
  openTab,
}: ListProjectComponentProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const galleryRef = useRef<HTMLDivElement>(null);
  const selectedProgramData = data?.find((h) => h.systemType === systemType);
  const [isOpen, setIsOpen] = React.useState(false);
  const toDetails = (item: IProjects) => {
    navigate(appRoutesObj.getProjectDetailsPageUrl(item.id.toString()));
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        galleryRef.current &&
        !galleryRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div>
      <div className="mobile:hidden tablet:hidden space-y-8">
        {openTab === "" ? (
          <>
            {data?.slice(0, itemsNumber).map((item, index) => {
              return (
                <div key={index} className="flex items-center">
                  <div className="w-full bg-whiteSolv items-center gap-16 laptop:gap-12 rounded-lg flex">
                    <div>
                      <img
                        onClick={() => toDetails(item)}
                        src={item.img}
                        alt="img"
                        className="object-contain max-w-[428px]"
                      />
                    </div>
                    <div className="w-fit h-[187px] flex flex-col gap-7 laptop:gap-4">
                      <p className="text-2xl text-darkBlue font-arBold font-bold laptop:text-xl">
                        {t(`${item.title}`)}
                      </p>
                      <div className="flex flex-col gap-6">
                        <div className="flex gap-4 items-center text-base laptop:text-sm">
                          <p className="font-bold">
                            {t("container.ourProjectContainer.location")}
                          </p>
                          <p className="font-normal">{t(`${item.location}`)}</p>
                        </div>

                        <div className="flex gap-4 items-center text-base laptop:text-sm">
                          <p className="font-bold">
                            {t("container.ourProjectContainer.partners")}{" "}
                          </p>
                          <p className="font-normal">
                            {" "}
                            {t(`${item.partners}`)}
                          </p>
                        </div>

                        <div className="flex gap-4 items-center text-base laptop:text-sm">
                          <p className="font-bold">
                            {t("container.ourProjectContainer.system")}
                          </p>
                          <p className="font-normal">{t(`${item.system}`)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : null}
        {selectedProgramData ? (
          <>
            {data.map((item, index) => {
              if (item.systemType === systemType) {
                return (
                  <div key={index} className="flex items-center gap-16">
                    <div className="w-full bg-whiteSolv items-center gap-12 px-1 rounded-lg flex">
                      <div>
                        <img
                          onClick={() => toDetails(item)}
                          src={item.img}
                          alt="img"
                          className="object-contain max-w-[428px]"
                        />
                      </div>
                      <div className="w-fit flex flex-col gap-7">
                        <p className="text-2xl text-darkBlue font-arBold font-bold">
                          {t(`${item.title}`)}
                        </p>
                        <div className="flex flex-col gap-6">
                          <div className="flex gap-4 items-center text-base">
                            <p className="font-bold font-verdana">
                              {t("container.ourProjectContainer.location")}
                            </p>
                            <p className="font-normal font-verdana">
                              {t(`${item.location}`)}
                            </p>
                          </div>

                          <div className="flex gap-4 items-center text-base">
                            <p className="font-bold font-verdana">
                              {t("container.ourProjectContainer.partners")}{" "}
                            </p>
                            <p className="font-normal font-verdana">
                              {t(`${item.partners}`)}
                            </p>
                          </div>

                          <div className="flex gap-4 items-center text-base">
                            <p className="font-bold font-verdana">
                              {t("container.ourProjectContainer.system")}
                            </p>
                            <p className="font-normal">{t(`${item.system}`)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </>
        ) : null}
      </div>
      <div className="largeLaptop:hidden laptop:hidden largeDesktop:hidden space-y-5 mobile:px-4">
        {openTab === "" ? (
          <>
            {data?.slice(0, itemsNumberMob).map((item, index) => {
              return (
                <div key={index} className="flex flex-col items-start gap-8">
                  <div className="w-full bg-whiteSolv items-center rounded-lg flex flex-col-reverse gap-6">
                    <div className="flex flex-col w-full h-full gap-0 px-4">
                      <p className="text-darkBlue font-arBold text-xl">
                        {t(`${item.title}`)}
                      </p>
                      <div className="flex flex-col gap-6 py-4">
                        <div className="flex gap-1 text-base flex-col-reverse">
                          <p className="font-normal">{t(`${item.location}`)}</p>
                          <p className="font-bold">
                            {t("container.ourProjectContainer.location")}
                          </p>
                        </div>

                        <div className="flex justify-start gap-1 text-base flex-col-reverse">
                          <p className="font-normal">{t(`${item.partners}`)}</p>
                          <p className="font-bold">
                            {t("container.ourProjectContainer.partners")}{" "}
                          </p>
                        </div>

                        <div className="flex justify-start gap-1 text-base flex-col-reverse">
                          <p className="font-normal">{t(`${item.system}`)}</p>
                          <p className="font-bold">
                            {t("container.ourProjectContainer.system")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="rounded-t-lg rounded-none">
                      <img
                        onClick={() => toDetails(item)}
                        src={item.img}
                        alt="img"
                        className="rounded-t-lg rounded-none"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <></>
        )}
        {selectedProgramData ? (
          <>
            {data.map((item, index) => {
              if (item.systemType === systemType) {
                return (
                  <div key={index} className="flex flex-col items-start gap-8">
                    <div className="w-full bg-whiteSolv items-center rounded-lg flex flex-col-reverse gap-6">
                      <div className="flex flex-col w-full h-full gap-0 px-4">
                        <p className="text-darkBlue font-arBold text-xl">
                          {t(`${item.title}`)}
                        </p>
                        <div className="flex flex-col gap-6 py-4">
                          <div className="flex gap-1 text-base flex-col-reverse">
                            <p className="font-normal">
                              {t(`${item.location}`)}
                            </p>
                            <p className="font-bold">
                              {t("container.ourProjectContainer.location")}
                            </p>
                          </div>

                          <div className="flex justify-start gap-1 text-base flex-col-reverse">
                            <p className="font-normal">
                              {t(`${item.partners}`)}
                            </p>
                            <p className="font-bold">
                              {t("container.ourProjectContainer.partners")}{" "}
                            </p>
                          </div>

                          <div className="flex justify-start gap-1 text-base flex-col-reverse">
                            <p className="font-normal">{t(`${item.system}`)}</p>
                            <p className="font-bold">
                              {t("container.ourProjectContainer.system")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="rounded-t-lg rounded-none">
                        <img
                          onClick={() => toDetails(item)}
                          src={item.img}
                          alt="img"
                          className="object-contain"
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </>
        ) : null}
      </div>
      {isOpen && (
        <div
          className={`fixed z-50 inset-0 flex w-screen h-screen mobile:h-full mobile:w-full ease-in-out transition-all duration-300 
          ${isOpen ? "bg-opacityPopup" : "invisible"}`}
        >
          <div className="flex-1 flex justify-center items-center">
            <div
              ref={galleryRef}
              className="w-[1118px] h-[838px] flex justify-center items-center content-center laptop:w-[800px] tablet:w-[600px] tablet:h-[700px] mobile:w-11/12 mobile:h-1/3"
            >
              <ImageGalleryComponent slides={imgProj!} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
