import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";

interface BreadCrumbsProps {
  newName?: string;
}

export default function BreadCrumbsComponent(props: BreadCrumbsProps) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-row gap-2.5 mobile:gap-2">
      <p
        className="font-normal font-verdana text-sm text-zinc-500 mobile:text-base cursor-pointer"
        onClick={() => navigate(appRoutesObj.getNewsPath())}
      >
        {t("app.shared.news")}
      </p>
      {i18n.language === "ar" ? (
        <div className="font-normal mobile:mb-0.5 font-verdana text-sm text-zinc-500 mobile:text-sm">
          {" > "}
        </div>
      ) : (
        <div className="font-normal font-verdana text-sm text-zinc-500 mobile:text-sm">
          {" > "}
        </div>
      )}
      <p className="font-normal font-verdana text-sm text-darkBlue1 text-mint-500 mobile:text-base">
        {t(`${props.newName}`).slice(0, 30) + ".."}
      </p>
    </div>
  );
}
