interface IProps {
  className?: string;
  onMouseOver?: (event: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
}

function Maan(props: IProps) {
  return (
    <path
      className={props.className}
      onMouseOver={props.onMouseOver}
      d="M296.3 616.4l21.7-40.7 9.5-30.3-4.3-12.1-3.1-21.3 198.7 0.3 23.8 25.9 24.1 26.2 0.6 0.7 0.6 0.7 0.6 0.7 0.6 0.7 20.3 24.1 20.4 24 20.4 24 20.4 24 9.2 10.8 0 0.2 0 0.1-0.2 0-1.2 0.8-13.6 7.6-22.5 12.7-20.2 11.4-4.3 3.6-2.4 3.8-5.4 13.4-6.7 16.3-6.1 14.9-8.3 20.2-4 3.6-21.9 4.5-24.8 5.2-26.5 5.6-26.8 5.6-16.5 3.4-16.1 3.3-5.1 2.6-4.4 4.8-10.3 17.5-8.5 14.5-11.6 19.7-11.9 20.2-14.4 11.9-21.5 17.9-19.7 16.3-20.1 16.7-4.8 1.9-5.1 0.2-19.3-3.3-21.4-3.7-10.6-1.8 0-0.1-0.4-1.8-9.2-67.5-12.1-49.2-9.7-22.2-2.8-4.3-3.3-3.6-3.6-3.1-3.7-2.1-3.4-1.3-5.6-1.1-4.7 0.2-2.4 0.5-2.4 0.8-2.8 0.4-3-0.1-3-1-2.6-1.5-2.2-2.2-1.7-2.2-1.5-2.5-0.9-2.1-0.9-4 0.4-5.6 2.2-10.7 10.8-21.5 1.5-6.3-0.4-24.2 8.3-26.2 3.8-17.1 1.1-13.5 7.4 0 7.6 1.2 18.4-0.6 6.4 0.5 17.3-2.3 13.2-3.2 2.6-1.6 1.8-1.9 2.3-1.6 2-0.4 2 0.2 5 1.9 6.8-2.5 14.1-23.9z"
      id="JOMN"
      name="Ma`an"
    />
  );
}

export default Maan;
