interface IProps {
  className?: string;
  onMouseOver?: (event: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
}

function Karak(props: IProps) {
  return (
    <path
      className={props.className}
      onMouseOver={props.onMouseOver}
      d="M170.6 582.7l5-4.8 2.1-4 0-6.8 1.2-6.3 8.7-17.3 0.9-6.2-1.3-5.9-2.8-5.4-2-4.4-1.8-5.6-1-5.9 2.4-5.3 2.6-4.6 0.3-4.7-1.4-1.5 3.4-6.3 3.3-8.8 0.8-4.9 0-2.2 4-1 14.1 0.5 18.6 6.3 3.5 0.7 2.6 0 5.7-1.8 6.3-0.7 4 0.1 8.3 1.9 4.5 8 1.2 4.2 0.5 2.8-0.3 2-1.5 4.3-0.7 3.1-0.5 4.2 0.7 2.6 1.8 1.7 56.3 1.3 3.1 21.3 4.3 12.1-9.5 30.3-21.7 40.7-8-8-7.8-5.9-1.5-1.6-1.9-3.3-1.5-1.6-1.9-1.2-6.8-3.1-2.3-1.7-1.7-1.6-0.8-1.6-0.5-1.3-1.3-1.1-29.7-11.9-15.9-0.8-2.6-1-2.6-1.7-2.1-1.9-2.1-0.6-2.4-0.2-3.3 0.5-2.3-0.1-1.8 0.6-1.1 1.7-0.8 4.4-1.9 5.6-1.2 8.8-1.2 2.1-2.1 1.3-3.3-0.3-2.6-0.7-9.3-6.5-1.4-1z"
      id="JOKA"
      name="Karak"
    />
  );
}

export default Karak;
