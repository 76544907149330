import { useState } from "react";
import { useTranslation } from "react-i18next";

import TitleComponent from "../title-component/title-component";

export default function ServicesComponent() {
  const [activeIndex, setActiveIndex] = useState(false);
  const [index, setIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(0);
  const { t } = useTranslation();

  const background = ["bg-serv1", "bg-serv2", "bg-serv3"].map((element) => {
    return { element };
  });

  return (
    <div className={`bg-cover ${background} bg-serv${hoverIndex + 1}`}>
      <div className="flex flex-col items-center justify-center bg-blueBlur py-44 w-full mobile:py-48 mobile:p-2">
        <div className="flex item-center justify-center font-bold mb-24">
          <TitleComponent
            title={t("component.ServicesComponent.our.services")}
            width={24}
            widthMobile={24}
          />
        </div>
        <div className="overflow-x-auto no-scrollbar w-screen">
          <div className="flex flex-row-reverse items-center justify-center gap-8 mobile:gap-4 mobile:w-fit tablet:w-fit mobile:px-6 tablet:px-10">
            <div className="inline-block cursor-pointer">
              {activeIndex && index === 2 ? (
                <div
                  onMouseEnter={() => setHoverIndex(2)}
                  onClick={() => {
                    setActiveIndex(!activeIndex);
                    setIndex(2);
                  }}
                  className="p-4 rounded-lg overflow-hidden flex flex-col font-light w-[400px] largeLaptop:w-[330px] laptop:w-[260px] tablet:w-[330px] mobile:w-[320px] mobile:h-[330px] gap-4 justify-between items-center content-center bg-blueServ h-[442px] transition-[height] duration-700 ease-in-out"
                >
                  <div className="flex flex-col items-center">
                    <svg
                      className="text-white mb-4"
                      width="64"
                      height="65"
                      viewBox="0 0 64 65"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_55_487)">
                        <path
                          d="M63.7265 19.2686H53.8752V31.0555H42.0882V42.8713H30.2724V54.6583H18.4854V64.5096H14.5468V50.7197H26.3338V38.9327H38.1496V27.1169H49.9365V15.33H63.7265V19.2686ZM27.4123 0.5H41.2023V14.2514H37.2637V7.19275L3.05842 41.3884L0.275391 38.6053L34.471 4.40009H27.4123V0.5Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_55_487">
                          <rect
                            width="63.4511"
                            height="64"
                            fill="currentColor"
                            transform="translate(0.275391 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <h3 className="text-lightBlue text-2xl text-center font-arRegular font-semibold border-b-2 border-lightBlue pb-1">
                      {t("component.ServicesComponent.card1.title")}
                    </h3>
                  </div>
                  <p className="text-white text-base px-5 font-light">
                    {t("component.ServicesComponent.card1.description")}
                  </p>
                  <button className="w-full font-cairo text-white bg-lightBlue p-3 rounded-lg mobile:font-arRegular">
                    {t("component.button.learnMore")}
                  </button>
                </div>
              ) : (
                <div
                  onMouseEnter={() => setHoverIndex(2)}
                  onClick={() => {
                    setActiveIndex(!activeIndex);
                    setIndex(2);
                  }}
                  className="group hover:bg-white transition-colors duration-650 ease-in-out p-4 mt-0 rounded-lg flex flex-col font-light w-[400px] h-[175px] largeLaptop:w-[330px] laptop:w-[260px] tablet:w-[330px] mobile:w-[320px] gap-4 justify-center items-center content-center bg-blueServ"
                >
                  <svg
                    className="text-white group-hover:text-lightBlue"
                    width="64"
                    height="65"
                    viewBox="0 0 64 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_55_487)">
                      <path
                        d="M63.7265 19.2686H53.8752V31.0555H42.0882V42.8713H30.2724V54.6583H18.4854V64.5096H14.5468V50.7197H26.3338V38.9327H38.1496V27.1169H49.9365V15.33H63.7265V19.2686ZM27.4123 0.5H41.2023V14.2514H37.2637V7.19275L3.05842 41.3884L0.275391 38.6053L34.471 4.40009H27.4123V0.5Z"
                        fill="currentColor"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_55_487">
                        <rect
                          width="63.4511"
                          height="64"
                          fill="currentColor"
                          transform="translate(0.275391 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                  <h3 className="text-lightBlue group-hover:text-cyan text-2xl text-center font-arRegular font-semibold border-b-2 border-lightBlue group-hover:border-cyan pb-1">
                    {t("component.ServicesComponent.card1.title")}
                  </h3>
                </div>
              )}
            </div>
            <div className="inline-block cursor-pointer">
              {activeIndex && index === 1 ? (
                <div
                  onMouseEnter={() => setHoverIndex(1)}
                  onClick={() => {
                    setActiveIndex(!activeIndex);
                    setIndex(1);
                  }}
                  className="p-4 overflow-hidden rounded-lg flex flex-col w-[400px]  mobile:h-[330px] largeLaptop:w-[330px] laptop:w-[260px] tablet:w-[330px] mobile:w-[320px] gap-3 justify-between items-center content-center bg-blueServ h-[442px] transition-[height] duration-700 ease-in-out"
                >
                  <div className="flex flex-col items-center">
                    <svg
                      className="text-white mb-4"
                      width="64"
                      height="65"
                      viewBox="0 0 64 65"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_55_495)">
                        <path
                          d="M48.5527 19.2585V19.6794C48.5527 20.7734 47.9726 21.7744 47.0551 22.2631C46.6784 22.4633 46.2638 22.5673 45.8445 22.5673H18.1574C17.7365 22.5673 17.3219 22.4633 16.9468 22.2631C16.0293 21.7744 15.4492 20.7734 15.4492 19.6794V19.2585"
                          stroke="currentColor"
                          strokeWidth="3.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M42 7.5C44.4459 9.97978 45.9816 13.5467 46 17.5"
                          stroke="currentColor"
                          strokeWidth="3.5"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18 17.5C18 13.5458 19.5385 9.97659 22 7.5"
                          stroke="currentColor"
                          strokeWidth="3.5"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M43.4545 26.9827L43.1676 29.2668C42.4614 34.8944 37.6724 39.1206 32.0007 39.1206C26.3289 39.1206 21.54 34.8944 20.8338 29.2668L20.5469 26.9827"
                          stroke="currentColor"
                          strokeWidth="3.5"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M26 10.5V5.24719C26 4.21067 26.6694 3.3129 27.6311 3.03703C28.5866 2.7628 29.916 2.5 31.5 2.5C33.084 2.5 34.4134 2.7628 35.3689 3.03703C36.3306 3.3129 37 4.20904 37 5.24719V10.5"
                          stroke="currentColor"
                          strokeWidth="3.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M23.1738 18.155H40.829"
                          stroke="currentColor"
                          strokeWidth="3.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M62 63.5C62 63.5 62 56.1914 56.7496 52.0151C54.519 50.2416 46.7019 47.8149 42.3801 46.2652C41.5475 45.9669 41 45.1823 41 44.3023V36.5"
                          stroke="currentColor"
                          strokeWidth="3.5"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2 63.5C2 63.5 2 56.1914 7.25037 52.0151C9.48103 50.2416 17.2981 47.8149 21.6199 46.2652C22.4525 45.9669 23 45.1823 23 44.3023V36.5"
                          stroke="currentColor"
                          strokeWidth="3.5"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.2412 63.3964L13 54.5"
                          stroke="currentColor"
                          strokeWidth="3.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M24.2753 63.3967L19.9277 49.4822"
                          stroke="currentColor"
                          strokeWidth="3.5"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M50.7588 63.3964L51 54.5"
                          stroke="currentColor"
                          strokeWidth="3.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M39.7246 63.3967L44.0722 49.4822"
                          stroke="currentColor"
                          strokeWidth="3.5"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M26 49.5H26.0094C27.0488 50.2239 29.2787 51.5 32 51.5C34.7213 51.5 36.9512 50.2239 37.9906 49.5H38"
                          stroke="currentColor"
                          strokeWidth="3.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_55_495">
                          <rect
                            width="64"
                            height="64"
                            fill="currentColor"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <h3 className="text-lightBlue text-2xl text-center font-arRegular font-semibold border-b-2 border-lightBlue pb-1">
                      {t("component.ServicesComponent.card2.title")}
                    </h3>
                  </div>
                  <p className="text-white px-[7px]">
                    {t("component.ServicesComponent.card2.description")}
                  </p>
                  <button className="w-full font-cairo text-white bg-lightBlue p-3 rounded-lg mobile:font-arRegular">
                    {t("component.button.learnMore")}
                  </button>
                </div>
              ) : (
                <div
                  onMouseEnter={() => setHoverIndex(1)}
                  onClick={() => {
                    setActiveIndex(!activeIndex);
                    setIndex(1);
                  }}
                  className="group hover:bg-white transition-colors duration-650 ease-in-out p-4 mt-0 rounded-lg flex flex-col w-[400px] h-[175px] largeLaptop:w-[330px] laptop:w-[260px] tablet:w-[330px] mobile:w-[320px] gap-3 justify-center items-center content-center bg-blueServ"
                >
                  <svg
                    className="text-white group-hover:text-lightBlue"
                    width="64"
                    height="65"
                    viewBox="0 0 64 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_55_495)">
                      <path
                        d="M48.5527 19.2585V19.6794C48.5527 20.7734 47.9726 21.7744 47.0551 22.2631C46.6784 22.4633 46.2638 22.5673 45.8445 22.5673H18.1574C17.7365 22.5673 17.3219 22.4633 16.9468 22.2631C16.0293 21.7744 15.4492 20.7734 15.4492 19.6794V19.2585"
                        stroke="currentColor"
                        strokeWidth="3.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M42 7.5C44.4459 9.97978 45.9816 13.5467 46 17.5"
                        stroke="currentColor"
                        strokeWidth="3.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18 17.5C18 13.5458 19.5385 9.97659 22 7.5"
                        stroke="currentColor"
                        strokeWidth="3.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M43.4545 26.9827L43.1676 29.2668C42.4614 34.8944 37.6724 39.1206 32.0007 39.1206C26.3289 39.1206 21.54 34.8944 20.8338 29.2668L20.5469 26.9827"
                        stroke="currentColor"
                        strokeWidth="3.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M26 10.5V5.24719C26 4.21067 26.6694 3.3129 27.6311 3.03703C28.5866 2.7628 29.916 2.5 31.5 2.5C33.084 2.5 34.4134 2.7628 35.3689 3.03703C36.3306 3.3129 37 4.20904 37 5.24719V10.5"
                        stroke="currentColor"
                        strokeWidth="3.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M23.1738 18.155H40.829"
                        stroke="currentColor"
                        strokeWidth="3.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M62 63.5C62 63.5 62 56.1914 56.7496 52.0151C54.519 50.2416 46.7019 47.8149 42.3801 46.2652C41.5475 45.9669 41 45.1823 41 44.3023V36.5"
                        stroke="currentColor"
                        strokeWidth="3.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2 63.5C2 63.5 2 56.1914 7.25037 52.0151C9.48103 50.2416 17.2981 47.8149 21.6199 46.2652C22.4525 45.9669 23 45.1823 23 44.3023V36.5"
                        stroke="currentColor"
                        strokeWidth="3.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.2412 63.3964L13 54.5"
                        stroke="currentColor"
                        strokeWidth="3.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M24.2753 63.3967L19.9277 49.4822"
                        stroke="currentColor"
                        strokeWidth="3.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M50.7588 63.3964L51 54.5"
                        stroke="currentColor"
                        strokeWidth="3.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M39.7246 63.3967L44.0722 49.4822"
                        stroke="currentColor"
                        strokeWidth="3.5"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M26 49.5H26.0094C27.0488 50.2239 29.2787 51.5 32 51.5C34.7213 51.5 36.9512 50.2239 37.9906 49.5H38"
                        stroke="currentColor"
                        strokeWidth="3.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_55_495">
                        <rect
                          width="64"
                          height="64"
                          fill="currentColor"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                  <h3 className="text-lightBlue group-hover:text-cyan group-hover:border-cyan text-2xl text-center font-arRegular font-semibold border-b-2 border-lightBlue pb-1">
                    {t("component.ServicesComponent.card2.title")}
                  </h3>
                </div>
              )}
            </div>
            <div className="inline-block cursor-pointer">
              {activeIndex && index === 0 ? (
                <div
                  onMouseEnter={() => setHoverIndex(0)}
                  onClick={() => {
                    setActiveIndex(!activeIndex);
                    setIndex(0);
                  }}
                  className="p-4 rounded-lg overflow-hidden flex flex-col w-[400px] mobile:h-[330px] largeLaptop:w-[330px] laptop:w-[260px] tablet:w-[330px] mobile:w-[320px] gap-3 justify-between items-center content-center bg-blueServ  h-[442px] transition-[height] duration-700 ease-in-out"
                >
                  <div className="flex flex-col items-center">
                    <svg
                      className="text-white mb-4"
                      width="98"
                      height="65"
                      viewBox="0 0 98 65"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M83.7572 64.5001H14.2431C6.69171 64.5001 0.785156 53.7151 0.785156 39.9393C0.785156 26.1636 6.69171 15.3599 14.2431 15.3599H83.7572C91.3086 15.3599 97.2152 26.1449 97.2152 39.9206C97.2152 53.6964 91.3086 64.4814 83.7572 64.4814V64.5001ZM14.2431 19.0982C9.55152 19.0982 4.52348 27.472 4.52348 39.9206C4.52348 52.3693 9.55152 60.7431 14.2431 60.7431H83.7572C88.4488 60.7431 93.4769 52.3693 93.4769 39.9206C93.4769 27.472 88.4488 19.0982 83.7572 19.0982H14.2431Z"
                        fill="currentColor"
                      />
                      <path
                        d="M62.9336 19.0983H35.0645V6.74316H62.9336V19.0983ZM38.8028 15.36H59.1953V10.4815H38.8028V15.36Z"
                        fill="currentColor"
                      />
                      <path
                        d="M48.9906 52.2011C43.7383 52.2011 39.4766 47.9394 39.4766 42.6871C39.4766 38.0142 46.2242 29.8833 47.57 28.3132L48.9906 26.6497L50.4112 28.3132C51.757 29.902 58.5046 38.0142 58.5046 42.6871C58.5046 47.9394 54.2429 52.2011 48.9906 52.2011ZM48.9906 32.4814C46.2429 35.9768 43.2149 40.631 43.2149 42.6871C43.2149 45.8646 45.813 48.4628 48.9906 48.4628C52.1682 48.4628 54.7663 45.8646 54.7663 42.6871C54.7663 40.631 51.7383 35.9768 48.9906 32.4814Z"
                        fill="currentColor"
                      />
                      <path
                        d="M65.9445 0.5H32.0566V4.23832H65.9445V0.5Z"
                        fill="currentColor"
                      />
                      <path
                        d="M27.9818 56.145H17.5332V15.3599H21.2715V52.4066H27.9818V56.145Z"
                        fill="currentColor"
                      />
                      <path
                        d="M27.9818 44.6499H17.5332V48.3882H27.9818V44.6499Z"
                        fill="currentColor"
                      />
                      <path
                        d="M27.9818 37.3411H17.5332V41.0794H27.9818V37.3411Z"
                        fill="currentColor"
                      />
                      <path
                        d="M27.9818 30.0142H17.5332V33.7525H27.9818V30.0142Z"
                        fill="currentColor"
                      />
                      <path
                        d="M27.9818 22.6873H17.5332V26.4256H27.9818V22.6873Z"
                        fill="currentColor"
                      />
                    </svg>
                    <h3 className="text-lightBlue text-2xl text-center font-arRegular font-semibold border-b-2 border-lightBlue pb-1">
                      {t("component.ServicesComponent.card3.title")}
                    </h3>
                  </div>
                  <p className="text-white font-light ">
                    {t("component.ServicesComponent.card3.description")}
                  </p>
                  <button className="w-full text-white font-cairo bg-lightBlue p-3 rounded-lg mobile:font-arRegular">
                    {t("component.button.learnMore")}
                  </button>
                </div>
              ) : (
                <div
                  onMouseEnter={() => setHoverIndex(0)}
                  onClick={() => {
                    setActiveIndex(!activeIndex);
                    setIndex(0);
                  }}
                  className="group hover:bg-white transition-colors duration-650 ease-in-out p-4 mt-0 rounded-lg flex flex-col w-[400px] h-[175px] largeLaptop:w-[330px] laptop:w-[260px] tablet:w-[330px] mobile:w-[320px] gap-3 justify-center items-center content-center bg-blueServ"
                >
                  <svg
                    className="text-white group-hover:text-lightBlue"
                    width="98"
                    height="65"
                    viewBox="0 0 98 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M83.7572 64.5001H14.2431C6.69171 64.5001 0.785156 53.7151 0.785156 39.9393C0.785156 26.1636 6.69171 15.3599 14.2431 15.3599H83.7572C91.3086 15.3599 97.2152 26.1449 97.2152 39.9206C97.2152 53.6964 91.3086 64.4814 83.7572 64.4814V64.5001ZM14.2431 19.0982C9.55152 19.0982 4.52348 27.472 4.52348 39.9206C4.52348 52.3693 9.55152 60.7431 14.2431 60.7431H83.7572C88.4488 60.7431 93.4769 52.3693 93.4769 39.9206C93.4769 27.472 88.4488 19.0982 83.7572 19.0982H14.2431Z"
                      fill="currentColor"
                    />
                    <path
                      d="M62.9336 19.0983H35.0645V6.74316H62.9336V19.0983ZM38.8028 15.36H59.1953V10.4815H38.8028V15.36Z"
                      fill="currentColor"
                    />
                    <path
                      d="M48.9906 52.2011C43.7383 52.2011 39.4766 47.9394 39.4766 42.6871C39.4766 38.0142 46.2242 29.8833 47.57 28.3132L48.9906 26.6497L50.4112 28.3132C51.757 29.902 58.5046 38.0142 58.5046 42.6871C58.5046 47.9394 54.2429 52.2011 48.9906 52.2011ZM48.9906 32.4814C46.2429 35.9768 43.2149 40.631 43.2149 42.6871C43.2149 45.8646 45.813 48.4628 48.9906 48.4628C52.1682 48.4628 54.7663 45.8646 54.7663 42.6871C54.7663 40.631 51.7383 35.9768 48.9906 32.4814Z"
                      fill="currentColor"
                    />
                    <path
                      d="M65.9445 0.5H32.0566V4.23832H65.9445V0.5Z"
                      fill="currentColor"
                    />
                    <path
                      d="M27.9818 56.145H17.5332V15.3599H21.2715V52.4066H27.9818V56.145Z"
                      fill="currentColor"
                    />
                    <path
                      d="M27.9818 44.6499H17.5332V48.3882H27.9818V44.6499Z"
                      fill="currentColor"
                    />
                    <path
                      d="M27.9818 37.3411H17.5332V41.0794H27.9818V37.3411Z"
                      fill="currentColor"
                    />
                    <path
                      d="M27.9818 30.0142H17.5332V33.7525H27.9818V30.0142Z"
                      fill="currentColor"
                    />
                    <path
                      d="M27.9818 22.6873H17.5332V26.4256H27.9818V22.6873Z"
                      fill="currentColor"
                    />
                  </svg>
                  <h3 className="text-lightBlue group-hover:text-cyan group-hover:border-cyan text-2xl text-center font-arRegular font-semibold border-b-2 border-lightBlue pb-1">
                    {t("component.ServicesComponent.card3.title")}
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
