interface IProps {
  className?: string;
  onMouseOver?: (event: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
}
function Irbid(props: IProps) {
  return (
    <path
      className={props.className}
      onMouseOver={props.onMouseOver}
      d="M307.1 234.8l2.3 18-1.3 5.7-1.4 0.5-1.6-0.3-3.4-2.7-2.1-0.7-2.3 0.3-2.6 2.2-0.7 1.8 0.3 1.8 0.9 1.8 1 2.2 0.6 3.2-0.3 1.7-1.4 1.9-6.2-0.2-5.8-1.7-2.4 0.8-6.4 2.7-4-5.7-3.7-2.9-0.9-1.8-0.8-2.9-1.3-0.6-1.3 0.9-1.3 1.8-2.2 1.4-3 0.4-5.4-0.3-3.1 0.6-5.6 3.1-4.5 1.3-4.7-0.3-3.2 0.2-2.7 1.2-1.8 2.3-1.3 3.4-2.8 13.7 0.3 3.4 1.1 3.5 2.6 6 0.5 1.9-0.2 1.3-0.8 2.2-12.9-1.6-1.2 0.1 2.4-10.3-2.5 0 0.3-1.3 0.5-0.8 0.8-0.6 0.9-0.5-1.5-2.6-0.7-4.1 0.1-4.2 0.8-2.6-1.7-3.3 0.2-6.5-1-2.2 0-1.5 1.3 0 0 1.5 1.2 0-0.3-2-0.6-1.7-0.7-1.3-0.9-1-0.5-0.7-0.8-2.4 1.8-0.4 0.8-0.6-0.2-0.9-1.1-1.2 0-1.3 1.3 0-0.3-1-0.3-0.2-0.3 0.1-0.4-0.4 2.5-1.7-1.2-1.5 1.3-0.5 1.2-0.8 0-1.7-0.9-0.1-0.5-0.3-0.4-0.4-0.7-0.5 0.4-1.5 0.7-1.5 1.1-1.2 1.7-0.5 0-1.4-1.8-1.8-0.4-0.9-1.3-2-0.8 0-0.9 0.1 0-1.5 2.5 0-0.5-1.4-0.7-4.6 1.2 1.5 0.8-2.4 0.5-0.8 0.2 0.9 0.1 0.5 0.3-0.2 0.8-1.2-1.1-2.7-0.6-5.7-1-1.9 0-1.7 1.3 0 0-1.4-1.6-0.9-0.7-1.6 0-1.8 0.5-0.7 1.3-0.6 1.6-1.5 2.8-3.7 3.4-2.5 0.1 0 4.4 0.6 2.9-1.6 6.2-5.5 10.2-6.5 3.2-0.8 1.2-0.6 1.1-0.2 1 0.1 0.7 0.6 0 0.1 1.7 2.2 20 4.6 1.8 1.1 3.2 3.2 0.9 0.3 2.4 0 0.7 0.4 0.3 1.5-0.3 1.4-0.6 0.9-0.6-0.1 1.6 2.1 1.7 1.6 1.9 0.6 2.7-0.5 4.3 0.4 0.9 2.5-0.6 3.7 0.2 4.2 1.7 3.7 8.4 12.7 1 2.6 0.1 0.9 0.6 0.2 2.3 0.1z"
      id="JOIR"
      name="Irbid"
    />
  );
}

export default Irbid;
