interface IProps {
  className?: string;
  onMouseOver?: (event: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
}
function Amman(props: IProps) {
  return (
    <path
      className={props.className}
      onMouseOver={props.onMouseOver}
      d="M258.1 477.8l2-1.9 1.1-0.9 1.4-1.5 0.8-1.4 3.2-9.8 1.2-2.4 2.6-3.7 0.6-1.4-0.4-1.3-1.4-1.5-1.1-1.3-0.2-1.8 0.6-1.5 0.2-2.1-1.2-2.8-8-8.7-1.4-3.4-1.4-5.6-0.3-2.2 0.2-1.9 0.9-1.6 3.2-3.9 1.1-2 0.4-2-0.4-15.8-1.3-1.3-1.3-0.2-2.3 1-2.8 0.7-9.4 0-7 1.8 0.3-3.9 0.5-2.3 2.3-6.7-0.3-1.8-1.1-1.2-4.2-0.7-2.5-0.7-2-1-1.3-1.6-0.4-2 1.3-2.4 2.8-2.5 10.8-5.6 4.7-3.3 8.3-10 2.8-2.3 7.1-2.1 2.9-1.7 5.8-5.7 5.4-1.8 4.3 5.6 4.5 4.2 6.2 7.6 3.6 2.6 2.5 0.8 3.6-2.1 2.3-0.5 3-0.2 9.1-3 12 0.1 3.8-0.7 2.4-1.5 3.5-4.3 2.2-1.9 4-0.9 19.6 1.7 3.9 1.8 3.9 3.2 10.5 11.6 5.3 7 0.4 3.5-1.7 2.6-5.3 3.3-4.1 3.4-1.4 2-0.3 2 1.2 2.4 5 5.8 8.3 7.7 8.1 5.6 4.3 1.6 3.5-0.1 8.8-5.4 3.2-0.9 3.4 0.8 52 53.3 0.6 0.3 0.1 0.1-7.1 2.1 24.1 26.3 24.2 26.3 0.4 0.4-198.7-0.3-56.3-1.3-1.8-1.7-0.7-2.6 0.5-4.2 0.7-3.1 1.5-4.3 0.3-2-0.5-2.8-1.2-4.2-4.5-8z"
      id="JOAM"
      name="Amman"
    />
  );
}

export default Amman;
