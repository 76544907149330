import { Layout } from "src/components/common/layout/layout";
import GeneralInformationContainer from "src/containers/user/order-here-container/general-information-container/general-information-container";

function GeneralInformationPage() {
  return (
    <Layout>
      <div>
        <GeneralInformationContainer />;
      </div>
    </Layout>
  );
}

export default GeneralInformationPage;
