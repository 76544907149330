import { Layout } from "src/components/common/layout/layout";
import OurProjectsContainer from "src/containers/user/our-project-container/our-project-container";

export default function OurProjectPage() {
  return (
    <Layout>
      <OurProjectsContainer />
    </Layout>
  );
}
