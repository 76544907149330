import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TbArrowNarrowLeft, TbArrowNarrowRight } from "react-icons/tb";
import i18n from "src/core/configs/i18n";

import TitleComponent from "../../title-component/title-component";
import CardComponent from "../card-component/card-component";
import Carroussel from "../carousel-component/carousel-component";
export default function NewsComponent() {
  const { t } = useTranslation();

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNextClick = () => {
    setCurrentIndex((index) => (index === cards.length - 1 ? 0 : index + 1));
  };

  const handlePrevClick = () => {
    setCurrentIndex((index) => (index === 0 ? cards.length - 1 : index - 1));
  };

  const cards = [
    {
      key: "1",
      content: <CardComponent imagen="./assets/images/new2.svg" />,
    },
    {
      key: "2",
      content: <CardComponent imagen="./assets/images/news1.svg" />,
    },
    {
      key: "3",
      content: <CardComponent imagen="./assets/images/news1.svg" />,
    },
  ];

  return (
    <div className="container mx-auto mobile:mx-0 pb-16 mb-20 tablet:mb-36">
      <div>
        <div className="flex justify-center items-center pt-20">
          <TitleComponent
            title={t("component.NewsComponent.title")}
            width={24}
            widthMobile={0}
          />
        </div>
        <div className="flex justify-center items-center mt-20 mobile:mt-0">
          {/* for mobile */}
          <div className="laptop:hidden largeLaptop:hidden largeDesktop:hidden">
            <Carroussel
              cards={cards}
              height="400px"
              width="50%"
              margin="10px"
              offset={200}
              showArrows={false}
              currentIndex={currentIndex}
            />
          </div>
        </div>
        {/* Aroows for mobile */}
        <div className="container absolute z-40 tablet:mt-28 items-center justify-center laptop:hidden largeLaptop:hidden largeDesktop:hidden gap-10 inline-flex">
          <span
            onClick={() => {
              handlePrevClick();
            }}
            className={`flex justify-center cursor-pointer items-center w-10 h-10 border border-sky-400 text-sky-400 hover:text-white hover:bg-sky-400 rounded-md`}
          >
            {i18n.language === "en" ? (
              <TbArrowNarrowLeft size={16} />
            ) : (
              <TbArrowNarrowRight size={16} />
            )}
          </span>
          <span
            onClick={() => {
              handleNextClick();
            }}
            className={`flex justify-center cursor-pointer items-center w-10 h-10 border border-sky-400 text-sky-400 hover:text-white hover:bg-sky-400 rounded-md `}
          >
            {i18n.language === "en" ? (
              <TbArrowNarrowRight size={16} />
            ) : (
              <TbArrowNarrowLeft size={16} />
            )}
          </span>
        </div>
        {/* for desktop */}
        <div className="relative h-5 w-full">
          <div className="absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full left-1/2">
            <div className="flex flex-row justify-between mx-60 laptop:mx-10 mt-96">
              <span
                onClick={() => {
                  handlePrevClick();
                }}
                className="flex justify-center cursor-pointer items-center laptop:-translate-y-2 mobile:hidden tablet:hidden top-1/2 w-12 h-12 border border-sky-400 text-sky-400 hover:text-white hover:bg-sky-400 rounded"
              >
                {i18n.language == "en" ? (
                  <TbArrowNarrowLeft className="w-4 h-4" />
                ) : (
                  <TbArrowNarrowRight className="w-4 h-4" />
                )}
              </span>

              <span
                onClick={() => {
                  handleNextClick();
                }}
                className={`flex justify-center cursor-pointer items-center laptop:-translate-y-2 mobile:hidden tablet:hidden top-1/2 w-12 h-12 border border-sky-400 text-sky-400 hover:text-white hover:bg-sky-400 rounded`}
              >
                {i18n.language == "en" ? (
                  <TbArrowNarrowRight className="w-4 h-4" />
                ) : (
                  <TbArrowNarrowLeft className="w-4 h-4" />
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center -mt-10 mobile:hidden tablet:hidden">
          <Carroussel
            cards={cards}
            height="500px"
            width="50%"
            margin="0 auto"
            offset={200}
            showArrows={false}
            currentIndex={currentIndex}
          />
        </div>
      </div>
    </div>
  );
}
