import React from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction } from "react-router-dom";
import { SystemEnum } from "src/core/enums/example.enum";
import { Tabs } from "src/core/statics/our-project.ts/our-project";

interface SystemWaterComponentProps {
  openTab: string;
  setOpenTab: React.Dispatch<React.SetStateAction<string>>;
  navigate: NavigateFunction;
  setSelectedSystem: React.Dispatch<
    React.SetStateAction<SystemEnum | string | undefined>
  >;
}
export default function SystemWaterComponent(props: SystemWaterComponentProps) {
  const { i18n, t } = useTranslation();

  return (
    <div className="space-y-8 mobile:space-y-0">
      <div
        className={`overflow-auto no-scrollbar ${
          i18n.language == "en" ? " mobile:gap-0" : " mobile:gap-6"
        } mobile:text-nowrap flex items-start flex-row-reverse`}
      >
        {Tabs.map((tab) => (
          <div key={tab.id}>
            <div
              onClick={() => {
                props.setSelectedSystem(tab.systemType);
                props.setOpenTab(tab.id);
                props.navigate({ search: `${tab.systemType}` });
              }}
              className={`p-3 mobile:p-2 gap-3 font-verdana mobile:gap-2 mobile:text-base flex text-cyan text-[23px] laptop:text-base tablet:text-base font-normal 
                         ${
                           props.openTab === tab.id
                             ? "text-darkBlue border border-transparent border-b-2 text-[23px] font-normal font-verdana border-b-darkBlue"
                             : ""
                         }`}
            >
              <div>{t(tab.lable)}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
