import { t } from "i18next";
import * as Yup from "yup";

export const validationSchemaPaymentInformation = () =>
  Yup.object({
    watertank: Yup.string()
      .trim()
      .required(t("app.shared.thisFieldIsRequired").toString()),
    upload: Yup.string().required(
      t("app.shared.thisFieldIsRequired").toString(),
    ),
    ask1: Yup.string().required("Please select an option for question 1"),
    ask2: Yup.string().required("Please select an option for question 2"),
    ask3: Yup.string().required("Please select an option for question 3"),
  });
