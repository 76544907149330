import { t } from "i18next";
import * as Yup from "yup";

export const validationSchemaGeneralInformation = () =>
  Yup.object({
    mobilenum: Yup.string()
      .matches(/^[0-9]+$/, "Please enter a valid mobile number") // Ensure only numbers are allowed
      .min(9, "Please enter your mobile number") // Minimum length of 9
      .trim()
      .required(t("app.shared.thisFieldIsRequired").toString()),
    quadName: Yup.string()
      .trim()
      .required(t("app.shared.thisFieldIsRequired").toString()),
    city: Yup.string()
      .trim()
      .required(t("app.shared.thisFieldIsRequired").toString()),
    loction: Yup.string()
      .trim()
      .required(t("app.shared.thisFieldIsRequired").toString()),
    HowManyOccupantsAreThereInYourHouseOrFacility: Yup.string().required(
      "Please select an option for question 1",
    ),
    IsYourHouseOrFacility: Yup.string().required(
      "Please select an option for question 2",
    ),
    HowManyResidentsAreInYourHomeOrFacility: Yup.string().required(
      "Please select an option for question 3",
    ),
    WhatIsTheAreaOfYourHomeOrFacility: Yup.string().required(
      "Please select an option for question 4",
    ),
    IsThereaGreenAreaOrAgriculturalSpace: Yup.string().required(
      "Please select an option for question 5",
    ),
    WhatIsTheAreaOfTheGreenSpace: Yup.string().required(
      "Please select an option for question 6",
    ),
  });
