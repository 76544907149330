import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import { newsMockData } from "src/core/statics/news-mock-data/news";

export default function ListNewsComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [visibleCount, setVisibleCount] = useState<number>(6);

  const currentNewsMockData = newsMockData.slice(0, visibleCount);
  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 6); // Increase by 6 each time
  };

  return (
    <div className="mx-20 my-10 mobile:my-4 relative mobile:mx-4 items-center flex flex-col gap-8">
      <div className="bg-listNews rounded-lg px-12 mobile:px-0 mobile:py-4 py-6 w-full">
        <div className="flex flex-col mobile:gap-3 mobile:items-end">
          {currentNewsMockData?.map((item, index) => {
            return (
              <div
                key={index}
                className="flex flex-col w-full cursor-pointer mobile:gap-0"
                onClick={() =>
                  navigate(
                    appRoutesObj.getNewsDetailsPageUrl(item.id.toString())
                  )
                }
              >
                <div className="flex w-full gap-9 mobile:gap-2 pt-6">
                  <img
                    src={item.img}
                    alt="img"
                    className="w-40 h-[117px] mobile:w-[119px] mobile:h-[117px]"
                  />
                  <div className="flex flex-col space-y-4 w-full">
                    <p className="font-arRegular text-2xl font-medium mobile:text-sm text-darkBlue pb-2">
                      {t(item.title)}
                    </p>
                    <p className="text-zinc-400 text-[11px] mobile:overflow-ellipsis mobile:line-clamp-2">
                      {t(`${item.content}`)}
                    </p>
                    <p className="items-end justify-end flex text-[11px] text-gray600 pb-10 mobile:pb-2">
                      {t(`${item.date}`)}
                    </p>
                  </div>
                </div>
                <hr className="w-full h-3 text-black mobile:items-stretch mobile:stroke-2" />
              </div>
            );
          })}
        </div>
      </div>
      {visibleCount < newsMockData.length ? (
        <button
          onClick={loadMore}
          className="w-[441px] mobile:w-[361px] h-14 bg-sky-500 rounded-lg text-white font-cairo"
        >
          {t("component.button.showMore")}
        </button>
      ) : (
        <div className="mb-20"></div>
      )}
    </div>
  );
}
