import { Layout } from "src/components/common/layout/layout";
import NewsContainer from "src/containers/user/our-news-container/news-container";

export default function NewsPage() {
  return (
    <Layout>
      <NewsContainer />
    </Layout>
  );
}
