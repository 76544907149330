import { FormikProps } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import i18n from "src/core/configs/i18n";
import { DirectionEnum } from "src/core/enums/design-system.enum";
import { GeneralInformation } from "src/core/types/user.type";

export interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isValid?: boolean;
  hint?: string;
  label?: string;
  searchTerm: string;
  formik: FormikProps<GeneralInformation>;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  cities: string[];
}
function CityDropDwonComponent({
  hint,
  isValid,
  searchTerm,
  setSearchTerm,
  formik,
  ...props
}: InputFieldProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState<string | null>("");

  const [filteredCities, setFilteredCities] = useState<string[]>(props.cities);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (city: string) => {
    setIsOpen(false);
    setSelectedCity(city);
    setSearchTerm(city);
    formik.setFieldValue("city", city);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    formik.setFieldValue("city", term);
    const filtered = props.cities.filter((city) =>
      city.toLowerCase().includes(term),
    );
    setFilteredCities(filtered);
  };
  const getValidationPaddingClass = () => {
    switch (isValid) {
      case true:
        return props.dir === DirectionEnum.RTL ? "pr-4 pl-10" : "pl-4 pr-10";
      case false:
        return props.dir === DirectionEnum.RTL ? "pr-4 pl-10" : "pl-4 pr-10";
      default:
        return "px-4";
    }
  };
  const getValidationClass = () => {
    switch (isValid) {
      case true:
        return "";
      case false:
        return "border border-red-500  outline-none";
      default:
        return "";
    }
  };
  const getHintClass = () => {
    switch (isValid) {
      case true:
        return "text-success";
      case false:
        return "text-red-500 text-start mobile:px-2 px-2 pt-2 text-[13px]";
      default:
        return "text-muted";
    }
  };

  const { t } = useTranslation();

  return (
    <div>
      <label className="block px-2 text-start mobile:px-2 mb-2 text-[19px] mobile:text-base text-sky-500 font-normal font-verdana">
        {t(`${props["aria-label"]}`)}
      </label>
      <div>
        <span className="relative w-[385px] mobile:w-[361px] flex mobile:justify-center mobile:items-center">
          <input
            type="text"
            onClick={handleToggle}
            value={t(`${searchTerm}`) || t(`${selectedCity}`) || ""}
            onChange={handleSearchChange}
            placeholder={
              t(`${selectedCity}`) ||
              t("component.CityDropDwonComponent.choose.your.state")!
            }
            className={`justify-center w-[385px] bg-darkWhite pr-2 ${getValidationClass()} ${getValidationPaddingClass()} mobile:w-[361px] text-start h-11 placeholder:text-start px-2 font-openSans bg-darkGray text-[13px] text-gray-700 focus:outline-none
            ${isOpen ? "rounded-lg" : "rounded-lg"}
            `}
          />
          <div
            className={`absolute inset-y-0 ${
              i18n.language == "ar" ? "left-0" : "right-0"
            }   flex items-center px-2 mobile:px-2 pointer-events-none`}
          >
            {isOpen ? (
              <FiChevronUp size={19} className="text-neutral-400" />
            ) : (
              <FiChevronDown size={19} className="text-neutral-400" />
            )}
          </div>
        </span>
      </div>
      {isOpen && (
        <div className="absolute bg-darkWhite px-1 mt-[8px] rounded-lg z-30 w-[385px] mobile:w-[361px] shadow-lg">
          <div className=" mt-4" role="menu">
            {filteredCities.map((city) => (
              <button
                key={city}
                onClick={() => handleSelect(city)}
                className="flex text-start border-b border-opacity-65 border-slate-300 w-full flex-col p-2 text-zinc-900"
                role="menuitem"
              >
                {t(`${city}`)}
              </button>
            ))}
          </div>
        </div>
      )}
      {hint && <p className={getHintClass()}>{hint}</p>}
    </div>
  );
}

export default CityDropDwonComponent;
