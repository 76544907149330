import { useTranslation } from "react-i18next";
import i18n from "src/core/configs/i18n";

import TitleComponent from "../title-component/title-component";

function OurInfluenceComponent() {
  const { t } = useTranslation();

  return (
    <div className="bg-white h-[40vh] mobile:h-[45vh] mt-3 flex mobile:mt-0 flex-col items-center justify-center px-8 space-y-14">
      <TitleComponent
        title={t("component.ourInfluenceComponent.ourInfluence")}
        width={24}
        widthMobile={0}
      />
      <div
        className={` ${
          i18n.language === "ar" ? "w-[82%]" : "w-[100%] tablet:text-base"
        } px-28 text-center mobile:text-justify mobile:text-base mobile:px-1 items-center text-[23px] font-normal font-verdana leading-snug text-cyan`}
      >
        {t("component.ourInfluenceComponent.description")}
      </div>
    </div>
  );
}

export default OurInfluenceComponent;
