export const radioDataGeneralInformation = [
  {
    contentsWithTitle: [
      {
        name: "HowManyOccupantsAreThereInYourHouseOrFacility",
        title:
          "container.GeneralInformationContainer.How.many.residents.do.your.home.or.facility.have",
        contents: [
          "container.GeneralInformationContainer.cash",
          "container.GeneralInformationContainer.in.installments",
        ],
      },
      {
        name: "IsYourHouseOrFacility",
        title:
          "container.GeneralInformationContainer.Is.it.your.home.or.facility",
        contents: ["app.shared.yes", "app.shared.no"],
      },
      {
        name: "HowManyResidentsAreInYourHomeOrFacility",
        title:
          "container.GeneralInformationContainer.How.many.residents.do.your.home.or.facility.have",
        contents: [
          "container.GeneralInformationContainer.less.than.5",
          "container.GeneralInformationContainer.Between.5-10",
          "container.GeneralInformationContainer.more.than.10",
        ],
      },
      {
        name: "WhatIsTheAreaOfYourHomeOrFacility",
        title:
          "container.GeneralInformationContainer.What.is.the.area.of.​​your.home.or.facility",
        contents: [
          "container.GeneralInformationContainer.less.than.100.square.metres",
          "container.GeneralInformationContainer.between.100.to.250.square.metres",
          "container.GeneralInformationContainer.more.then.250.square.meters",
          "app.shared.other",
        ],
      },
      {
        name: "IsThereaGreenAreaOrAgriculturalSpace",
        title:
          "container.GeneralInformationContainer.Is.there.a.green.yard.or.a.yard.for.agriculture",
        contents: ["app.shared.yes", "app.shared.no"],
      },
      {
        name: "WhatIsTheAreaOfTheGreenSpace",
        title:
          "container.GeneralInformationContainer.What.is.the.area.of.​​the.green.square",
        contents: [
          "container.GeneralInformationContainer.less.than.100.square.metres",
          "container.GeneralInformationContainer.more.than.100.square.metres",
        ],
      },
    ],
  },
];

export const radioDataInformationSpace = [
  {
    contentsWithTitle: [
      {
        name: "IsYourHomeOrFacilityConnectedToTheSewageSystem",
        title:
          "container.InformationSpaceContainer.Is.your.home.or.facility.connected.to.the.sewage.system",
        contents: ["app.shared.yes", "app.shared.no"],
      },
      {
        name: "WhatIsTheDurationBetweenEachLeachingProcessForTheAbsorptionPit",
        title:
          "container.InformationSpaceContainer.What.is.the.period.of.time.that.separates.each.perfusion.of.the.absorption.pit",
        contents: [
          "container.InformationSpaceContainer.evrey.two.weeks",
          "container.InformationSpaceContainer.per.month",
          "container.InformationSpaceContainer.two.months",
          "container.InformationSpaceContainer.more.than.two.6.months",
          "container.InformationSpaceContainer.more.than.6.m",
        ],
      },
      {
        name: "HowManyWaterTanksAreProvidedByTheAuthorityOrMinistryOfWater",
        title:
          "container.InformationSpaceContainer.How.many.water.tanks.are.provided.by.the.Authority.or.the.Ministry.of.Water",
        contents: ["1", "2", "3", "4", "5"],
      },
      {
        name: "AreGreywaterPipesSinksShowersSeparatedFromBlackwaterToilets",
        title:
          "container.InformationSpaceContainer.Are.the.gray.water.pipes.(sinks/showers).separated.from.the.black.water.(bathrooms)",
        contents: ["app.shared.yes", "app.shared.no"],
      },
      {
        name: "HowFarIsTheGreenAreaFromTheAbsorptionPit",
        title:
          "container.InformationSpaceContainer.How.far.is.the.green.area.from.the.cesspit",
        contents: [
          "container.InformationSpaceContainer.Less.than.10.metres",
          "container.InformationSpaceContainer.Between.10-20.metres",
          "container.InformationSpaceContainer.More.than.20.metres",
        ],
      },

      {
        name: "WhatIsTheDurationBetweenEachIrrigationProcessForTheAbsorptionPit",
        title:
          "container.InformationSpaceContainer.What.is.the.period.of.time.that.separates.each.perfusion.of.the.absorption.pit",
        contents: [
          "container.InformationSpaceContainer.evrey.two.weeks",
          "container.InformationSpaceContainer.per.month",
          "container.InformationSpaceContainer.two.months",
          "container.InformationSpaceContainer.more.than.two.6.months",
          "container.InformationSpaceContainer.more.than.6.m",
        ],
      },
      {
        name: "TheSizeOfTheAbsorptionPitOnSite",
        title: "container.InformationSpaceContainer.Size.of.the.site's.cesspit",
        contents: [
          "container.InformationSpaceContainer.Less.than.10.cubic.metres",
          "container.InformationSpaceContainer.10-25.cubic.metres",
          "container.InformationSpaceContainer.25-50.cubic.metres",
          "container.InformationSpaceContainer.More.than.50.cubic.meters",
        ],
      },
    ],
  },
];
export const radioDataPaymentInformation = [
  {
    contentsWithTitle: [
      {
        name: "ask1",
        title:
          "container.PaymentInformationContainer.What.is.the.cost.of.percolating.your.home's.cesspit",
        contents: [
          "container.PaymentInformationContainer.I.don’t.pay.anything",
          "container.PaymentInformationContainer.Less.than.10.dinars",
          "container.PaymentInformationContainer.Between.10.dinars.to.20.dinars",
          "container.PaymentInformationContainer.Between.20.dinars.to.50.dinars",
          "container.PaymentInformationContainer.More.than.50.dinars",
        ],
      },
      {
        name: "ask2",
        title:
          "container.PaymentInformationContainer.What.is.the.value.of.your.water.bill",
        contents: [
          "container.PaymentInformationContainer.Less.than.10.dinars",
          "container.PaymentInformationContainer.Between.10.dinars.to.20.dinars",
          "container.PaymentInformationContainer.Between.20.dinars.to.50.dinars",
          "container.PaymentInformationContainer.More.than.50.dinars",
        ],
      },
      {
        name: "ask3",
        title:
          "container.PaymentInformationContainer.Are.you.interested.in.benefiting.from.the.revolving.loan.(installment.system).offered.by.the.Blue.Neighborhood.Initiative.to.purchase.the.decentralized.wastewater.treatment.system",
        contents: ["app.shared.yes", "app.shared.no"],
      },
    ],
  },
];
