import { Collapse } from "@mui/material";
import { useState } from "react";
import { IoChevronDown } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import i18n from "src/core/configs/i18n";
import { IMapRoutes } from "src/core/types/user.type";
type ItemCollapseProps = {
  item: IMapRoutes;
  isActive: boolean;
  setaActiveIndex: () => void;
};

export default function NavBarItemDropDown(props: ItemCollapseProps) {
  const [isActiveItemDropDown, setisActiveItemDropDown] = useState(-1);
  const handeleClick = (index: number) => {
    setisActiveItemDropDown(isActiveItemDropDown === index ? -1 : index);
  };

  return (
    <>
      <div
        className={`flex items-center justify-center gap-2 leading-5 text-base w-full hover:text-darkBlue font-arRegular `}
        onClick={() => props.setaActiveIndex()}
        data-te-dropdown-ref
      >
        <div className={`mt-4 -translate-y-1/2`}>
          <IoChevronDown
            className={`w-4 h-4 transition-transform ${
              props.isActive ? "rotate-180 duration-500" : "duration-500"
            } ${props.isActive ? "text-darkBlue" : "text-white"}`}
          />
        </div>
        <button
          className={`whitespace-nowrap text-base leading-9 ${
            i18n.language === "ar" ? "ml-4" : "mr-4"
          } ${props.isActive ? "text-lightBlue" : "text-white"}`}
        >
          {props.item.title}
        </button>
      </div>

      <Collapse
        in={props.isActive}
        className="flex items-center pt-3 px-4"
        timeout="auto"
        style={{ marginTop: 0 }}
      >
        <div className="justify-center flex flex-col gap-4 relative bg-cyan rounded-md py-3 items-center">
          {props.item.child?.map((route, index) => (
            <div key={index} className="flex w-full flex-col">
              <li
                className="items-center flex justify-center flex-col"
                onClick={() => handeleClick(index)}
                key={index}
              >
                <NavLink
                  to={route.path!}
                  key={route.title}
                  className={({ isActive }) =>
                    isActive ? "text-darkBlue" : "text-white"
                  }
                >
                  <nav
                    className={` ${
                      i18n.language ? "font-arRegular" : ""
                    } leading-5 whitespace-nowrap cursor-pointer w-full ${
                      index === 0 && "text-darkBlue"
                    }`}
                  >
                    {route.title}
                  </nav>
                </NavLink>
              </li>
              {index === 0 && <p className="w-full mt-1 h-[1px] bg-darkBlue" />}
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
}
