import { animated, useSpring } from "react-spring";

interface CardProps {
  imagen: string;
}

function CardComponent({ imagen }: CardProps) {
  const props3 = useSpring({
    opacity: 1,
  });

  return (
    <div className="flex items-center justify-center">
      <animated.div style={props3}>
        <div className="card laptop:w-[14rem] tablet:w-[40rem] mobile:w-[21rem]">
          <img src={imagen} alt="" className="mobile:scale-[0.6]" />
        </div>
      </animated.div>
    </div>
  );
}

export default CardComponent;
