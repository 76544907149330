interface IProps {
  className?: string;
  onMouseOver?: (event: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
}

function Jarash(props: IProps) {
  return (
    <path
      className={props.className}
      onMouseOver={props.onMouseOver}
      d="M274.3 273.8l6.4-2.7 2.4-0.8 5.8 1.7 6.2 0.2-2.7 5.4-8.4 9.6-1.8 3.4-0.1 2.2 0.9 1.5 1.5 1 1.3 1.1 2.4 4.1 1.9 4.5-0.6 4.7-0.9 1.9-1.6 1.6-2.4 1.2-3 1.7-2.1 1.7-7 9.4-6.8-5.1-1.4-1.9-2.1-2.1-2.6-1.1-3.6-0.7-16.2 0.4-2.5-0.2-1.9-0.7-0.2-1.9 7.7-8.8 12.7-5.8 1.7-1.9 0.4-2-0.2-2 0.6-3.1 1.3-3.6 3.1-5 2.9-2.9 2.9-2 2-0.8 4-2.2z"
      id="JOJA"
      name="Jarash"
    />
  );
}

export default Jarash;
