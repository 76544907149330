import { useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BackGroundTitleComponent from "src/components/user/back-ground-title-component/back-ground-title-component";
import EmptyProjectsComponent from "src/components/user/our-projects-component/empty-project-component/empty-project-component";
import ListProjectComponent from "src/components/user/our-projects-component/list-project-component/list-project-component";
import SearchPlaceComponent from "src/components/user/our-projects-component/search-places-component/search-places-component";
import TabsComponent from "src/components/user/our-projects-component/tabs-component/tabs-component";
import { SystemEnum } from "src/core/enums/example.enum";
import { selectionListPlaces } from "src/core/statics/our-project.ts/our-project";
import { projectsMockData } from "src/core/statics/projects-mock-data/projects-mock-data";
import { IProjects } from "src/core/types/user.type";

export default function OurProjectsContainer() {
  const { i18n } = useTranslation();
  const [filteredProjects, setFilteredProjects] =
    useState<IProjects[]>(projectsMockData);
  const { id = "" } = useParams();
  const dummyData = projectsMockData;
  const projData =
    dummyData.length > 0
      ? dummyData.find((a) => a.id.toString() === id) || { images: [] }
      : null;

  const { t } = useTranslation();
  const location = useLocation();
  const systemWater =
    new URLSearchParams(location.search).get("systemWater") || "";
  const [openTab, setOpenTab] = React.useState<SystemEnum | string>(
    systemWater,
  );

  const navigate = useNavigate();
  const [valuePlace, setValuePlace] = React.useState<string>("");
  const [itemsNumber, setItemsNumber] = useState<number>(6);
  const [itemsNumberMob, setItemsNumberMob] = React.useState<number>(6);
  const [selectedSystem, setSelectedSystem] = React.useState<
    SystemEnum | string
  >();

  const handleFilter = (place: string) => {
    if (place === "app.shared.all.places") {
      setFilteredProjects(projectsMockData);
    } else {
      const filtered = projectsMockData.filter((project) =>
        project.type.includes(place),
      );
      setFilteredProjects(filtered);
    }
    setItemsNumber(5);
    setItemsNumberMob(5);
  };

  const paginationMob = () => {
    const newItemsNumberMob = itemsNumberMob + 6;
    setItemsNumberMob(newItemsNumberMob);
  };

  const pagination = () => {
    const newItemsNumber = itemsNumber + 6;
    setItemsNumber(newItemsNumber);
  };

  const filteredGraywaterProjects = filteredProjects.filter(
    (project) => project.systemType === SystemEnum.GRAYWATER,
  );

  const filteredBlackwaterProjects = filteredProjects.filter(
    (project) => project.systemType === SystemEnum.BLACKWATER,
  );

  return (
    <div className="mobile:h-full">
      <BackGroundTitleComponent
        mobileHighet="absolute mobile:h-[150px]"
        title="app.shared.ourProjects"
        pading="px-20"
      />
      <div
        className={`mb-14 ${
          i18n.language === "en"
            ? "text-left pl-[80px] mobile:pl-4 mobile:text-[19px]"
            : "text-right mobile:mb-0"
        }  text-black font-verdana w-full text-[19px] mobile:mb-6 font-light pr-20 mobile:p-5 mobile:pl-16 mobile:items-stretch  mobile:pb-0`}
      >
        {t("container.ourProjectContainer.description")}
      </div>

      <div className="pb-6 mobile:pb-0 mx-20 mobile:mx-0">
        <div className="flex mobile:p-4 flex-row items-center justify-between w-full mobile:flex-col-reverse tablet:flex-col-reverse tablet:p-4">
          <div className="mobile:w-[340px] tablet:w-full overflow-auto no-scrollbar px-2">
            <TabsComponent
              valuePlace={valuePlace}
              setValuePlace={setValuePlace}
              openTab={openTab}
              setSelectedSystem={setSelectedSystem}
              setOpenTab={setOpenTab}
              navigate={navigate}
            />
          </div>
          <div className="flex h-full mobile:w-full tablet:w-full">
            <SearchPlaceComponent
              title="component.SearchPlaceComponent.place"
              currentValue={valuePlace}
              selectionList={selectionListPlaces}
              onClick={handleFilter}
            />
          </div>
        </div>
      </div>
      {(openTab === SystemEnum.GRAYWATER &&
        filteredGraywaterProjects.length === 0) ||
      (openTab === SystemEnum.BLACKWATER &&
        filteredBlackwaterProjects.length === 0) ||
      filteredProjects.length === 0 ? (
        <EmptyProjectsComponent />
      ) : (
        <div className="flexflex-col mobile:pt-0 p-0 items-stretch justify-between pb-10 mx-20 space-y-4 mobile:pb-0 mobile:mx-0">
          <div className="items-stretch pb-10 mobile:pb-0">
            {openTab === "" && (
              <ListProjectComponent
                itemsNumberMob={itemsNumberMob}
                itemsNumber={itemsNumber}
                data={filteredProjects}
                imgProj={projData?.images}
                systemType={selectedSystem!}
                openTab={openTab}
              />
            )}
            {openTab === SystemEnum.BLACKWATER && (
              <ListProjectComponent
                itemsNumberMob={itemsNumberMob}
                itemsNumber={itemsNumber}
                imgProj={projData?.images}
                data={filteredProjects}
                systemType={SystemEnum.BLACKWATER}
                openTab={openTab}
              />
            )}
            {openTab === SystemEnum.GRAYWATER && (
              <ListProjectComponent
                itemsNumberMob={itemsNumberMob}
                itemsNumber={itemsNumber}
                imgProj={projData?.images}
                data={filteredProjects}
                systemType={SystemEnum.GRAYWATER}
                openTab={openTab}
              />
            )}
          </div>
        </div>
      )}
      <div className="flex items-center justify-center mb-[40px] mobile:mb-6 mobile:mx-9 mobile:pt-3">
        {filteredProjects.length === 0 ||
        openTab === SystemEnum.GRAYWATER ||
        filteredProjects.length === 0 ||
        openTab === SystemEnum.BLACKWATER ||
        filteredProjects.length === 0 ? (
          <></>
        ) : (
          <div
            className={`${
              filteredProjects.length === 1 ||
              filteredProjects.length === 1 ||
              itemsNumber >= 10 ||
              itemsNumberMob >= 10
                ? "hidden"
                : ""
            } text-base self-center justify-center px-10 largeDesktop:w-[441px] h-14 py-3 w-[441px] mobile:w-full rounded-lg bg-darkBlue text-white cursor-pointer mobile:items-center mobile:justify-center mobile:py-4 mobile:px-4`}
            onClick={() => {
              pagination();
              paginationMob();
            }}
          >
            <p className="flex justify-center text-base font-cairo">
              {t("component.button.showMore")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
