import { INews } from "src/core/types/user.type";

export const newsMockData: INews[] = [
  {
    id: 1,
    img: "/assets/images/Sollvilon-next-chapter.jpeg",
    title:
      "SOLVillion won first place in the 2020 National Youth Entrepreneurs Forum for agriculture and food security.",
    content: "component.ListNewsComponent.breif1",
    date: "component.ListNewsComponent.date1",
    paragraph: "component.ListNewsComponent.desc1",
    gallary: [
      {
        detail: "/assets/images/listnews1.png",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },
  {
    id: 2,
    img: "/assets/images/SOLVillion-Joins-Toilet.jpeg",
    title: "component.ListNewsComponent.title2",
    content: "component.ListNewsComponent.breif2",
    date: "component.ListNewsComponent.date2",
    paragraph: "component.ListNewsComponent.desc2",
    gallary: [
      {
        detail: "/assets/images/listnews1.png",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },
  {
    id: 3,
    img: "/assets/images/Tadamon-Accelerator.png",
    title: "component.ListNewsComponent.title3",
    content: "component.ListNewsComponent.breif3",
    date: "component.ListNewsComponent.date3",
    paragraph: "component.ListNewsComponent.desc3",
    gallary: [
      {
        detail: "/assets/images/listnews1.png",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },
  {
    id: 4,
    img: "/assets/images/Harvesting-Progress-From-Tree-to-Bottle.png",
    title: "component.ListNewsComponent.title4",
    content: "component.ListNewsComponent.breif4",
    date: "component.ListNewsComponent.date4",
    paragraph: "component.ListNewsComponent.desc4",
    gallary: [
      {
        detail: "/assets/images/listnews1.png",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },
  {
    id: 5,
    img: "/assets/images/SOLVillion's-Wastewater-Treatment-Training-1.jpeg",
    title: "component.ListNewsComponent.title5",
    content: "component.ListNewsComponent.breif5",
    date: "component.ListNewsComponent.date5",
    paragraph: "component.ListNewsComponent.desc5",
    gallary: [
      {
        detail: "/assets/images/solv-wastewater-treatment.jpeg",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },
  {
    id: 6,
    img: "/assets/images/DWWSBOX.jpeg",
    title: "component.ListNewsComponent.title6",
    content: "component.ListNewsComponent.breif6",
    date: "component.ListNewsComponent.date6",
    paragraph: "component.ListNewsComponent.desc6",
    gallary: [
      {
        detail: "/assets/images/listnews1.png",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },
  {
    id: 7,
    img: "/assets/images/ENERGY-GLOBE-AWARD.jpeg",
    title: "component.ListNewsComponent.title7",
    content: "component.ListNewsComponent.breif7",
    date: "component.ListNewsComponent.date7",
    paragraph: "component.ListNewsComponent.desc7",
    gallary: [
      {
        detail: "/assets/images/ENERGY-GLOBE-AWARD2.png",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },
  {
    id: 8,
    img: "/assets/images/UNLEASH-1.jpeg",
    title: "component.ListNewsComponent.title8",
    content: "component.ListNewsComponent.breif8",
    date: "component.ListNewsComponent.date8",
    paragraph: "component.ListNewsComponent.desc8",
    gallary: [
      {
        detail: "/assets/images/UNLEASH-2.jpeg",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },
  {
    id: 9,
    img: "/assets/images/WISPER-PROJECT.jpeg",
    title: "component.ListNewsComponent.title9",
    content: "component.ListNewsComponent.breif9",
    date: "component.ListNewsComponent.date9",
    paragraph: "component.ListNewsComponent.desc9",
    gallary: [
      {
        detail: "/assets/images/WISPER-PROJECT2.jpeg",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },
  {
    id: 10,
    img: "/assets/images/GA_ME1.jpeg",
    title: "component.ListNewsComponent.title10",
    content: "component.ListNewsComponent.breif10",
    date: "component.ListNewsComponent.date10",
    paragraph: "component.ListNewsComponent.desc10",
    gallary: [
      {
        detail: "/assets/images/GA_ME2.jpeg",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },
  {
    id: 11,
    img: "/assets/images/WATWR-AWARE.jpeg",
    title: "component.ListNewsComponent.title11",
    content: "component.ListNewsComponent.breif11",
    date: "component.ListNewsComponent.date11",
    paragraph: "component.ListNewsComponent.desc11",
    gallary: [
      {
        detail: "/assets/images/listnews1.png",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },

  {
    id: 6,
    img: "/assets/images/mixNews.png",
    title: "component.ListNewsComponent.title6",
    content:
      "تقديم 309 مشروعًا للمشاركة في المنتدى الوطني الثاني للرياديين الشباب 2020 في ثلاثة قطاعات مختلفة: السياحة والصحة والزراعة والأمن الغذائي.",
    date: "يوليو 2020",
    paragraph:
      "تقديم 309 مشروعًا للمشاركة في المنتدى الوطني الثاني للرياديين الشباب 2020 في ثلاثة قطاعات مختلفة: السياحة والصحة والزراعة والأمن الغذائي.تنافست المشاريع بعد حضور ورش عمل تدريبية ووصل 12 مشروعًا فقط إلى المرحلة النهائية.تنافس أربعة مشاريع من كل قطاع للفوز بالمركز الأول.تقديم 309 مشروعًا للمشاركة في المنتدى الوطني الثاني للرياديين الشباب 2020 في ثلاثة قطاعات مختلفة: السياحة والصحة والزراعة والأمن الغذائي.تنافست المشاريع بعد حضور ورش عمل تدريبية ووصل 12 مشروعًا فقط إلى المرحلة النهائية.تنافس أربعة مشاريع من كل قطاع للفوز بالمركز الأول.",
    gallary: [
      {
        detail: "/assets/images/listnews1.png",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },
  {
    id: 7,
    img: "/assets/images/mixNews.png",
    title:
      "فازت شركة SOLVillion بالمركز الأول في الملتقى الوطني الثاني للرياديين الشباب 2020 في قطاع الزراعة والأمن الغذائي",
    content:
      "تقديم 309 مشروعًا للمشاركة في المنتدى الوطني الثاني للرياديين الشباب 2020 في ثلاثة قطاعات مختلفة: السياحة والصحة والزراعة والأمن الغذائي.",
    date: "يوليو 2020",
    paragraph:
      "تقديم 309 مشروعًا للمشاركة في المنتدى الوطني الثاني للرياديين الشباب 2020 في ثلاثة قطاعات مختلفة: السياحة والصحة والزراعة والأمن الغذائي.تنافست المشاريع بعد حضور ورش عمل تدريبية ووصل 12 مشروعًا فقط إلى المرحلة النهائية.تنافس أربعة مشاريع من كل قطاع للفوز بالمركز الأول.تقديم 309 مشروعًا للمشاركة في المنتدى الوطني الثاني للرياديين الشباب 2020 في ثلاثة قطاعات مختلفة: السياحة والصحة والزراعة والأمن الغذائي.تنافست المشاريع بعد حضور ورش عمل تدريبية ووصل 12 مشروعًا فقط إلى المرحلة النهائية.تنافس أربعة مشاريع من كل قطاع للفوز بالمركز الأول.",
    gallary: [
      {
        detail: "/assets/images/listnews1.png",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },
  {
    id: 8,
    img: "/assets/images/mixNews.png",
    title:
      "فازت شركة SOLVillion بالمركز الأول في الملتقى الوطني الثاني للرياديين الشباب 2020 في قطاع الزراعة والأمن الغذائي",
    content:
      "تقديم 309 مشروعًا للمشاركة في المنتدى الوطني الثاني للرياديين الشباب 2020 في ثلاثة قطاعات مختلفة: السياحة والصحة والزراعة والأمن الغذائي.",
    date: "يوليو 2020",
    paragraph:
      "تقديم 309 مشروعًا للمشاركة في المنتدى الوطني الثاني للرياديين الشباب 2020 في ثلاثة قطاعات مختلفة: السياحة والصحة والزراعة والأمن الغذائي.تنافست المشاريع بعد حضور ورش عمل تدريبية ووصل 12 مشروعًا فقط إلى المرحلة النهائية.تنافس أربعة مشاريع من كل قطاع للفوز بالمركز الأول.تقديم 309 مشروعًا للمشاركة في المنتدى الوطني الثاني للرياديين الشباب 2020 في ثلاثة قطاعات مختلفة: السياحة والصحة والزراعة والأمن الغذائي.تنافست المشاريع بعد حضور ورش عمل تدريبية ووصل 12 مشروعًا فقط إلى المرحلة النهائية.تنافس أربعة مشاريع من كل قطاع للفوز بالمركز الأول.",
    gallary: [
      {
        detail: "/assets/images/listnews1.png",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },
  {
    id: 9,
    img: "/assets/images/mixNews.png",
    title:
      "فازت شركة SOLVillion بالمركز الأول في الملتقى الوطني الثاني للرياديين الشباب 2020 في قطاع الزراعة والأمن الغذائي",
    content:
      "تقديم 309 مشروعًا للمشاركة في المنتدى الوطني الثاني للرياديين الشباب 2020 في ثلاثة قطاعات مختلفة: السياحة والصحة والزراعة والأمن الغذائي.",
    date: "يوليو 2020",
    paragraph:
      "تقديم 309 مشروعًا للمشاركة في المنتدى الوطني الثاني للرياديين الشباب 2020 في ثلاثة قطاعات مختلفة: السياحة والصحة والزراعة والأمن الغذائي.تنافست المشاريع بعد حضور ورش عمل تدريبية ووصل 12 مشروعًا فقط إلى المرحلة النهائية.تنافس أربعة مشاريع من كل قطاع للفوز بالمركز الأول.تقديم 309 مشروعًا للمشاركة في المنتدى الوطني الثاني للرياديين الشباب 2020 في ثلاثة قطاعات مختلفة: السياحة والصحة والزراعة والأمن الغذائي.تنافست المشاريع بعد حضور ورش عمل تدريبية ووصل 12 مشروعًا فقط إلى المرحلة النهائية.تنافس أربعة مشاريع من كل قطاع للفوز بالمركز الأول.",
    gallary: [
      {
        detail: "/assets/images/listnews1.png",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },
  {
    id: 10,
    img: "/assets/images/mixNews.png",
    title:
      "فازت شركة SOLVillion بالمركز الأول في الملتقى الوطني الثاني للرياديين الشباب 2020 في قطاع الزراعة والأمن الغذائي",
    content:
      "تقديم 309 مشروعًا للمشاركة في المنتدى الوطني الثاني للرياديين الشباب 2020 في ثلاثة قطاعات مختلفة: السياحة والصحة والزراعة والأمن الغذائي.",
    date: "يوليو 2020",
    paragraph:
      "تقديم 309 مشروعًا للمشاركة في المنتدى الوطني الثاني للرياديين الشباب 2020 في ثلاثة قطاعات مختلفة: السياحة والصحة والزراعة والأمن الغذائي.تنافست المشاريع بعد حضور ورش عمل تدريبية ووصل 12 مشروعًا فقط إلى المرحلة النهائية.تنافس أربعة مشاريع من كل قطاع للفوز بالمركز الأول.تقديم 309 مشروعًا للمشاركة في المنتدى الوطني الثاني للرياديين الشباب 2020 في ثلاثة قطاعات مختلفة: السياحة والصحة والزراعة والأمن الغذائي.تنافست المشاريع بعد حضور ورش عمل تدريبية ووصل 12 مشروعًا فقط إلى المرحلة النهائية.تنافس أربعة مشاريع من كل قطاع للفوز بالمركز الأول.",
    gallary: [
      {
        detail: "/assets/images/listnews1.png",
      },
      {
        detail: "/assets/images/listnews2.png",
      },
      {
        detail: "/assets/images/listnews3.png",
      },
    ],
  },
];
