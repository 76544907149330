import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMenu } from "react-icons/ai";
import { BsXLg } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import { IMapRoutes } from "src/core/types/user.type";

import NavBarItemDropDown from "../dropdown-mobile-component/dropdown-mobile-component";
interface DrawerMobileProps {
  className: string;
}
export default function DrawerMobile(prop: DrawerMobileProps) {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [opened, setopened] = useState(-1);
  const [active] = useState(true);

  const openDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const changeLanguage = () => {
    if (i18n.language === "ar") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else {
      i18n.changeLanguage("ar");
      localStorage.setItem("language", "ar");
    }
  };
  const navigation: IMapRoutes[] = [
    {
      title: t("component.NavBarComponent.contactUs"),
      path: appRoutesObj.getorderherePath(),
    },
    {
      title: t("component.NavBarComponent.newsAndAchievement"),
      child: [
        {
          title: t("component.NavBarComponent.news"),
          path: appRoutesObj.getNewsPath(),
        },
        // {
        //   title: t("component.NavBarComponent.achievements"),
        //   path: "appRoutesObj.getachievementsPath(),",
        // },
      ],
    },
    {
      title: t("component.NavBarComponent.projects"),
      path: appRoutesObj.getOurProjectsPath(),
    },
    {
      title: t("component.NavBarComponent.services"),
      child: [
        // {
        //   title: t("component.NavBarComponent.product"),
        //   path: " appRoutesObj.getproductPath(),",
        // },
        // {
        //   title: t("component.NavBarComponent.consultations"),
        //   path: "appRoutesObj.getconsultationsPath(),",
        // },
        {
          title: t("component.NavBarComponent.trainingAndDevelopment"),
          path: appRoutesObj.gettrainingAndDevelopmentPath(),
        },
      ],
    },
    // {
    //   title: t("component.NavBarComponent.aboutSolvillion"),
    //   child: [
    //     {
    //       title: t("component.NavBarComponent.aboutSolvillion"),
    //       path: " appRoutesObj.getAboutusPath(),",
    //     },
    //     {
    //       title: t("component.NavBarComponent.ourPartners"),
    //       path: "appRoutesObj.getOurPartnersPath(),",
    //     },
    //     {
    //       title: t("component.NavBarComponent.ourTeam"),
    //       path: "appRoutesObj.getOurTeamPath(),",
    //     },
    //   ],
    // },
    {
      title: t("component.NavBarComponent.main"),
      path: appRoutesObj.getBasePath(),
    },
  ].map((item, index) => {
    return {
      ...item,
      index,
    };
  });

  const reversedNavigation = navigation.reverse();

  return (
    <div
      className={`largeLaptop:hidden largeDesktop:hidden laptop:hidden relative`}
    >
      <React.Fragment>
        <div
          onClick={openDrawer}
          className={`flex mobile:gap-0 w-full ${prop.className} items-center justify-between py-6 px-5`}
        >
          <div className="pt-2 text-white cursor-pointer" onClick={openDrawer}>
            <AiOutlineMenu className="h-6 w-6" />
          </div>
          <div>
            <button className="items-start flex text-sm">
              <img
                className="h-11 w-36"
                src="/assets/images/Solvillion-logo.png"
                alt=""
              />
              {""}
            </button>
          </div>
        </div>
        <div>
          <div
            className={`fixed inset-0  flex z-40 ease-in-out transition-all 
            duration-300 ${open ? "bg-opacity" : "invisible opacity-0"}`}
            onClick={onClose}
          >
            <div className="z-0 flex-1 flex">
              <div className="bg-gradient-to-b from-navFromMobile from-45% to-navToMobile w-[55%]">
                <div className="flex justify-end items-end px-2 pt-5">
                  <BsXLg className="h-6 w-6 text-white" onClick={onClose} />
                </div>
                <div
                  className={`mx-auto text-center self-stretch space-y-12 px-2 
                ${active ? "pt-20" : "pt-10"}`}
                >
                  {reversedNavigation.map((item, index) =>
                    item.child ? (
                      <div key={index} onClick={(e) => e.stopPropagation()}>
                        <NavBarItemDropDown
                          item={item}
                          key={index}
                          isActive={opened === index}
                          setaActiveIndex={() =>
                            setopened(opened === index ? -1 : index)
                          }
                        />
                      </div>
                    ) : (
                      <div
                        key={index}
                        className="flex items-center justify-center"
                      >
                        <NavLink
                          to={item.path!}
                          key={item.title}
                          className={({ isActive }) =>
                            isActive ? "text-darkBlue" : "text-white"
                          }
                        >
                          <nav
                            className={`
                          ${i18n.language ? "font-arRegular" : ""}
                                text-base leading-6 font-normal hover:text-darkBlue`}
                          >
                            {item.title}
                          </nav>
                        </NavLink>
                      </div>
                    ),
                  )}
                  <div className="flex items-center justify-center">
                    {i18n.language === "ar" ? (
                      <h1
                        className="px-3 text-base font-medium font-arRegular cursor-pointer text-white hover:text-blue-500 hover:opacity-70"
                        onClick={() => changeLanguage()}
                      >
                        ENGLISH
                      </h1>
                    ) : (
                      <h1
                        className="px-3 text-base font-medium font-arRegular cursor-pointer text-white hover:text-blue-500 hover:opacity-70"
                        onClick={() => changeLanguage()}
                      >
                        العربية
                      </h1>
                    )}
                  </div>
                </div>
                <div className="mx-auto text-center mt-6">
                  <button>
                    <img
                      className="h-11 w-36"
                      src="/assets/images/Solvillion-logo.png"
                      alt=""
                    />
                    {""}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
}
