interface IProps {
  className?: string;
  onMouseOver?: (event: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
}
function Aqaba(props: IProps) {
  return (
    <path
      className={props.className}
      onMouseOver={props.onMouseOver}
      d="M155.1 626.6l2.8 2.1 12.7 9.1 2.9 1.5 2.4 2.2 2.2 3.9 2.3 3 2.6 1.3 2.1 0.7 4.3 0.2-1.1 13.5-3.8 17.1-8.3 26.2 0.4 24.2-1.5 6.3-10.8 21.5-2.2 10.7-0.4 5.6 0.9 4 0.9 2.1 1.5 2.5 1.7 2.2 2.2 2.2 2.6 1.5 3 1 3 0.1 2.8-0.4 2.4-0.8 2.4-0.5 4.7-0.2 5.6 1.1 3.4 1.3 3.7 2.1 3.6 3.1 3.3 3.6 2.8 4.3 9.7 22.2 12.1 49.2 9.2 67.5 0.4 1.8 0 0.1-21.9-3.9-27.6-4.7-25.8-4.5-28.9-5-22.1-3.9-20.6-3.6 2.5-1.7 1.2-19.4 1.3-5.6 4-8.7-0.1-3.4-3.9-3.9-2.5 0-0.2-0.7 0-0.6-0.2-0.3-0.9 0.1 0.8-5.8 1.3-4.7 2.5-4 1.8-5.4 0.9-12 1.4-5.3 8.6-23.4 0.8-4.3 0.2-13.1 1.4-7.2 0.7-1.9 0.9-1.6 0.7-1.9 0.1-2.6 2.2-8.5 7.9-11.9 3-7.2 0-6.8-3.8-13.2 0.1-6.1 1.3-3.7 1.8-11.2 1.1-2.7 1.2-2.2 1-2.3 0.5-3-0.6-3.1-2.6-4.3-1-2.3 0-5.1 3.3-8.8 0.9-5.1 8-26.4 10.8-22.2z"
      id="JOAQ"
      name="Aqaba"
    />
  );
}

export default Aqaba;
