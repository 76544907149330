import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import FooterComponent from "../footer-component/footer-component";
import NavBarComponent from "../nav-bar-component/nav-bar-component";

interface LayoutProps {
  children: ReactElement;
}
export function Layout({ children }: LayoutProps) {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("language") || "ar");
  }, [i18n]);
  return (
    <div
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      className="bg-Primary-950"
    >
      <NavBarComponent
        setOpen={() => setOpen(open)}
        open={open}
        className={` ${
          window.location.pathname == "/"
            ? "opacity-75 bg-navBarBlue"
            : "bg-cyan"
        }`}
      />
      {children}
      <FooterComponent />
    </div>
  );
}
