import Collapse from "@mui/material/Collapse";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import { IMapRoutes } from "src/core/types/user.type";

import DrawerMobile from "../drawer-mobile-component/drawer-mobile-component";
import MultiLevelNavbarComponent from "../multi-level-navbar-component/multi-level-navbar-component";

export interface NavBarProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  className: string;
}

function NavBarComponent({ className, ...props }: NavBarProps) {
  const { t, i18n } = useTranslation();
  const [openIndex, setOpenIndex] = useState<number>(-1);

  const openDrawer = () => {
    return props.setOpen(false);
  };
  const navigation: IMapRoutes[] = [
    {
      title: t("component.NavBarComponent.main"),
      path: appRoutesObj.getBasePath(),
    },
    // {
    //   title: t("component.NavBarComponent.aboutSolvillion"),
    //   child: [
    //     {
    //       title: t("component.NavBarComponent.ourTeam"),
    //       path: " appRoutesObj.getourteamPath(),",
    //     },
    //     {
    //       title: t("component.NavBarComponent.ourPartners"),
    //       path: "appRoutesObj.getourpartnersPath(),",
    //     },
    //     {
    //       title: t("component.NavBarComponent.aboutSolvillion"),
    //       path: "appRoutesObj.getaboutusPath(),",
    //     },
    //   ],
    // },
    {
      title: t("component.NavBarComponent.services"),
      child: [
        {
          title: t("component.NavBarComponent.trainingAndDevelopment"),
          path: appRoutesObj.gettrainingAndDevelopmentPath(),
        },
        // {
        //   title: t("component.NavBarComponent.consultations"),
        //   path: "appRoutesObj.getconsultationsPath(),",
        // },
        // {
        //   title: t("component.NavBarComponent.product"),
        //   path: "appRoutesObj.getproductPath(),",
        // },
      ],
    },
    {
      title: t("component.NavBarComponent.projects"),
      path: appRoutesObj.getOurProjectsPath(),
    },
    {
      title: t("component.NavBarComponent.newsAndAchievement"),
      child: [
        // {
        //   title: t("component.NavBarComponent.achievements"),
        //   path: "appRoutesObj.getachievementsPath(),",
        // },
        {
          title: t("component.NavBarComponent.news"),
          path: appRoutesObj.getNewsPath(),
        },
      ],
    },

    {
      title: t("component.NavBarComponent.orderHere"),
      path: appRoutesObj.getorderherePath(),
    },
  ];
  const changeLanguage = () => {
    if (i18n.language === "ar") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else {
      i18n.changeLanguage("ar");
      localStorage.setItem("language", "ar");
    }
  };

  return (
    <div className="relative z-50">
      <div
        className={`${className} w-full flex items-center justify-between h-[92px] tablet:hidden mobile:hidden`}
      >
        <div className="flex items-center px-16 laptop:px-6">
          <p className="w-[226px]">
            <img
              className="w-[126px]"
              src="/assets/images/Solvillion-logo.png"
              alt=""
            />
          </p>
          <div className="flex items-center gap-12 desktop:gap-20 laptop:gap-4">
            {navigation.map((item, index) =>
              item.child ? (
                <MultiLevelNavbarComponent
                  item={item}
                  key={index}
                  onClick={() => openDrawer()}
                  isActive={openIndex === index}
                  setaActiveIndex={() =>
                    setOpenIndex(openIndex === index ? -1 : index)
                  }
                />
              ) : (
                <NavLink
                  to={item.path!}
                  key={index}
                  className={({ isActive }) =>
                    isActive ? "text-darkBlue" : "text-white"
                  }
                >
                  <nav
                    className={` ${i18n.language ? "font-arRegular" : ""}
                     rounded-md py-2 text-base hover:opacity-70`}
                  >
                    {item.title}
                  </nav>
                </NavLink>
              ),
            )}
          </div>
        </div>
        <div className="flex items-center mobile:hidden mx-20 laptop:mx-10">
          {i18n.language === "ar" ? (
            <h1
              className="font-arRegular cursor-pointer text-white hover:text-blue-500 hover:opacity-70"
              onClick={() => changeLanguage()}
            >
              ENGLISH
            </h1>
          ) : (
            <h1
              className="font-arRegular cursor-pointer text-white hover:text-blue-500 hover:opacity-70"
              onClick={() => changeLanguage()}
            >
              العربية
            </h1>
          )}
        </div>
      </div>
      <div className="tablet:hidden absolute w-full mobile:hidden">
        {navigation.map((item, index) =>
          item.child ? (
            <Collapse
              key={index}
              in={openIndex === index}
              className={`duration-300 w-full origin-top-right shadow-lg ${
                window.location.pathname == "/"
                  ? "opacity-75 bg-navBarBlue mobile:bg-inherit"
                  : "bg-cyan"
              }`}
              timeout="auto"
            >
              <ul className="flex justify-center gap-24 h-24 items-center pl-24">
                {item.child?.map((route, index) => (
                  <li key={index} className="items-center">
                    <NavLink
                      to={route.path!}
                      key={index}
                      className={({ isActive }) =>
                        isActive ? "text-darkBlue" : "text-white"
                      }
                    >
                      <nav
                        className={`
                          ${i18n.language ? "font-arRegular" : ""}
                                cursor-pointer hover:text-darkBlue`}
                      >
                        {route.title}
                      </nav>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </Collapse>
          ) : null,
        )}
      </div>
      <DrawerMobile className={className} />
    </div>
  );
}

export default NavBarComponent;
