import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function HeroSectionComponent() {
  const { i18n, t } = useTranslation();
  const [index, setIndex] = useState<number>(0);
  const [progressValue, setProgressValue] = useState<number>(0);

  const heroCardsInfo = [
    {
      img: "./assets/images/hero3.jpeg",
      heroTitle: `${t("component.HeroSectionComponent.content.title1")}`,
      heroDescription: `${t(
        "component.HeroSectionComponent.content.description1",
      )}`,
      subtitle: `${t("component.HeroSectionComponent.overview.title1")}`,
      subtitleDescription: `${t(
        "component.HeroSectionComponent.overview.description1",
      )}`,
    },
    {
      img: "./assets/images/hero1.jpeg",
      heroTitle: `${t("component.HeroSectionComponent.content.title2")}`,
      heroDescription: `${t(
        "component.HeroSectionComponent.content.description2",
      )}`,
      subtitle: `${t("component.HeroSectionComponent.overview.title2")}`,
      subtitleDescription: `${t(
        "component.HeroSectionComponent.overview.description2",
      )}`,
    },
    {
      img: "./assets/images/hero2.jpeg",
      heroTitle: `${t("component.HeroSectionComponent.content.title3")}`,
      heroDescription: `${t(
        "component.HeroSectionComponent.content.description3",
      )}`,
      subtitle: `${t("component.HeroSectionComponent.overview.title3")}`,
      subtitleDescription: `${t(
        "component.HeroSectionComponent.overview.description3",
      )}`,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      index === 2 ? setIndex(0) : setIndex(index + 1);
    }, 5000);

    return () => clearInterval(interval);
  }, [index]);

  useEffect(() => {
    const interval = setInterval(() => {
      progressValue < 100
        ? setProgressValue(progressValue + 10)
        : setProgressValue(0);
    }, 450);

    return () => clearInterval(interval);
  }, [progressValue]);

  return (
    <div>
      <div className="relative -z-10 bg-gradient-to-t from-darkBlue2 from-50% to-transparent h-[721px] tablet:h-[758px] laptop:h-[810px] w-full"></div>
      <div className="absolute top-0 left-0 -z-50">
        <img
          src={heroCardsInfo[index].img}
          className="h-[813px] tablet:h-[1050px] laptop:h-[900px] object-cover w-screen"
        />
      </div>
      <div className="absolute top-0 right-0">
        <div className="flex flex-col mt-40 mx-32 mobile:mx-4 mobile:gap-4 justify-center content-center">
          <div
            className={`py-2 h-[190px] text-blue font-arBold font-bold text-[63px]
                           ${
                             i18n.language === "ar"
                               ? "mobile:text-[40px] mobile:leading-[67px] tablet:leading-[100px]"
                               : "mobile:text-[30px] mobile:leading-[49px] laptop:h-[300px] tablet:text-[46px]"
                           }  mobile:w-full tablet:h-full`}
          >
            {heroCardsInfo[index].heroTitle}
          </div>
          <div
            className={`text-white h-[78px] tablet:h-full text-[28px] leading-[39px] mobile:w-full
                     font-normal ${
                       i18n.language === "ar"
                         ? "mobile:text-[23px]"
                         : "mobile:text-lg tablet:text-xl"
                     }`}
          >
            {heroCardsInfo[index].heroDescription}
          </div>
          <div className="flex flex-row mt-44 tablet:mt-20 justify-between w-[calc(100vw-274px)] mobile:w-[calc(100vw-32px)] gap-3.5">
            {heroCardsInfo.map((item, key) => (
              <div
                onClick={() => {
                  setIndex(key);
                  setProgressValue(0);
                }}
                key={key}
                className="flex flex-col w-[367px] cursor-pointer"
              >
                <div className="w-full h-1.5 rounded-full bg-whiteSolv1">
                  {index === key && (
                    <div
                      className={`${
                        i18n.language === "ar"
                          ? "bg-gradient-to-l"
                          : "bg-gradient-to-r"
                      } h-1.5 rounded-full to-whiteSolv1 bg-lightBlue from-transparent from-${progressValue}%`}
                      style={{ width: `${progressValue}%` }}
                    ></div>
                  )}
                </div>
                <p
                  className={`text-[23px] mt-8 laptop:mt-4 mb-4 font-arBold font-normal tablet:text-base ${
                    i18n.language === "ar"
                      ? "mobile:text-xs"
                      : " mobile:text-[8px]"
                  } ${index === key ? "text-lightBlue" : "text-white"}`}
                >
                  {item.subtitle}
                </p>
                <p className="text-gray-50 text-base font-light mobile:hidden tablet:hidden">
                  {item.subtitleDescription}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSectionComponent;
