import { Layout } from "src/components/common/layout/layout";
import ChallengesDeattilsContainer from "src/containers/user/challenges-deatils-container/challenges-deatils-container";

function ChallengesPage() {
  return (
    <Layout>
      <div>
        {" "}
        <ChallengesDeattilsContainer />
      </div>
    </Layout>
  );
}

export default ChallengesPage;
