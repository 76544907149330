import { GeneralInformation } from "src/core/types/user.type";

export const initialValues: GeneralInformation = {
  mobilenum: "",
  quadName: "",
  city: "",
  loction: "",
  HowManyOccupantsAreThereInYourHouseOrFacility: "",
  IsYourHouseOrFacility: "",
  HowManyResidentsAreInYourHomeOrFacility: "",
  WhatIsTheAreaOfYourHomeOrFacility: "",
  IsThereaGreenAreaOrAgriculturalSpace: "",
  WhatIsTheAreaOfTheGreenSpace: "",
};
