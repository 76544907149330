import { useTranslation } from "react-i18next";

interface TitleComponentProps {
  title: string;
  width: number;
  widthMobile: number;
}
export default function TitleComponent({
  title,
  widthMobile,
}: TitleComponentProps) {
  const { i18n } = useTranslation();

  return (
    <div
      dir={i18n.language === "ar" ? "ltr" : "rtl"}
      className="grid grid-rows-2 max-h-9 grid-flow-row gap-0 items-end justify-center mobile:px-0"
    >
      <div
        className="flex whitespace-nowrap justify-center item-center text-5xl mobile:text-[40px] 
        leading-[67px] font-arBold text-darkBlue"
      >
        {title}
      </div>
      <div
        className={`h-[3px] w-[86px] mobile:w-${widthMobile} bg-darkBlue flex
         justify-center items-end rounded-3xl`}
      />
    </div>
  );
}
