import { SystemEnum } from "src/core/enums/example.enum";

export const selectionListPlaces = [
  "app.shared.all.places",
  "component.ListProjectComponent.schools",
  "component.ListProjectComponent.houses",
  "component.ListProjectComponent.universities",
  "component.ListProjectComponent.government.facilities",
  "component.ListProjectComponent.Hotels",
  "component.ListProjectComponent.institutions.of.civil.society",
  "app.shared.other",
];
export const selectionListPlacesEng = [];
export const Tabs = [
  {
    id: SystemEnum.GRAYWATER,
    lable: "container.ourProjectContainer.grayWater",
    systemType: SystemEnum.BLACKWATER,
  },
  {
    id: SystemEnum.BLACKWATER,
    lable: "container.ourProjectContainer.blackWater",
    systemType: SystemEnum.BLACKWATER,
  },
  {
    id: "",
    lable: "container.ourProjectContainer.allSystem",
    systemType: "",
  },
];
