interface IProps {
  className?: string;
  onMouseOver?: (event: React.MouseEvent<SVGPathElement, MouseEvent>) => void;
}

function Tafilah(props: IProps) {
  return (
    <path
      className={props.className}
      onMouseOver={props.onMouseOver}
      d="M155.1 626.6l1.5-5.2 0.8-5.4 0.7-2.5 1.2-2.6 1.4-1.7 3.2-2.8 1-2.1 0.7-4.8-0.1-4.8 0.5-4.9 2.3-4.9 2.3-2.2 1.4 1 9.3 6.5 2.6 0.7 3.3 0.3 2.1-1.3 1.2-2.1 1.2-8.8 1.9-5.6 0.8-4.4 1.1-1.7 1.8-0.6 2.3 0.1 3.3-0.5 2.4 0.2 2.1 0.6 2.1 1.9 2.6 1.7 2.6 1 15.9 0.8 29.7 11.9 1.3 1.1 0.5 1.3 0.8 1.6 1.7 1.6 2.3 1.7 6.8 3.1 1.9 1.2 1.5 1.6 1.9 3.3 1.5 1.6 7.8 5.9 8 8-14.1 23.9-6.8 2.5-5-1.9-2-0.2-2 0.4-2.3 1.6-1.8 1.9-2.6 1.6-13.2 3.2-17.3 2.3-6.4-0.5-18.4 0.6-7.6-1.2-7.4 0-4.3-0.2-2.1-0.7-2.6-1.3-2.3-3-2.2-3.9-2.4-2.2-2.9-1.5-12.7-9.1-2.8-2.1z"
      id="JOAT"
      name="Tafilah"
    />
  );
}

export default Tafilah;
