import { SystemEnum } from "src/core/enums/example.enum";
import { IProjects } from "src/core/types/user.type";

export const projectsMockData: IProjects[] = [
  {
    id: 1,
    img: "/assets/images/listProj-1.svg",
    title: "component.JordanMapComponent.amman.title1",
    type: "app.shared.other",
    location: "component.ListProjectComponent.rasmunaif.location",
    partners: "component.ListProjectComponent.royal.scientific.society",
    system: "component.JordanMapComponent.amman.description1",
    systemType: SystemEnum.BLACKWATER,
    images: [
      { detail: "/assets/images/listProj-1.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 2,
    img: "/assets/images/listProj-2.svg",
    title: "component.JordanMapComponent.amman.title2",
    type: "",
    location: "component.ListProjectComponent.hashemite.mosque.location",
    partners: "component.ListProjectComponent.mercy.corps.partner",
    system: "component.JordanMapComponent.amman.description2",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-2.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 3,
    img: "/assets/images/listProj-3.svg",
    title: "component.JordanMapComponent.balqa.title1",
    type: "",
    location: "component.ListProjectComponent.Alfateh.mosque.location",
    partners: "component.ListProjectComponent.UNDP.partners",
    system: "component.JordanMapComponent.balqa.description1",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-3.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 4,
    img: "/assets/images/listProj-4.svg",
    title: "component.ListProjectComponent.Riyadh.Al-Salehin.Mosque",
    type: "",
    location: "component.ListProjectComponent.Riyadh.AlSalehin.location",
    partners: "component.ListProjectComponent.mercy.corps.partner",
    system: "component.JordanMapComponent.balqa.description1",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-4.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 5,
    img: "/assets/images/listProj-2.svg",
    title: "component.ListProjectComponent.Ammar.bin.Yasser.Mosque",
    type: "",
    location: "component.ListProjectComponent.Ammar.bin.Yasser.Mosque.location",
    partners: "component.ListProjectComponent.mercy.corps.partner",
    system: "component.JordanMapComponent.balqa.description1",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-2.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 6,
    img: "/assets/images/listProj-5.svg",
    title: "component.ListProjectComponent.blue.school",
    type: "component.ListProjectComponent.schools",
    location: "component.ListProjectComponent.blue.school.location",
    system: "component.ListProjectComponent.blue.school.system",
    partners: "component.ListProjectComponent.blue.school.partner",
    systemType: SystemEnum.BLACKWATER,
    images: [
      { detail: "/assets/images/listProj-5.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 7,
    img: "/assets/images/listProj-2.svg",
    title: "component.ListProjectComponent.Alnurain.mosque",
    type: "",
    location: "component.ListProjectComponent.Alnurain.mosque.location",
    partners: "component.ListProjectComponent.UNDP.partners",
    system: "component.ListProjectComponent.Alnurain.mosque.system",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-2.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 8,
    img: "/assets/images/listProj-3.svg",
    title: "component.ListProjectComponent.salah.aldain.mosque",
    type: "",
    location: "component.ListProjectComponent.salah.aldin.location",
    partners: "component.ListProjectComponent.mercy.corps.partner",
    system: "component.ListProjectComponent.salah.aldin.system",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-3.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 9,
    img: "/assets/images/listProj-4.svg",
    title: "component.ListProjectComponent.almanarh.mosque",
    type: "",
    location: "component.ListProjectComponent.almanarh.mosque.location",
    partners: "component.ListProjectComponent.almanarh.partners",
    system: "component.ListProjectComponent.almanarh.system",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-4.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 10,
    img: "/assets/images/listProj-5.svg",
    title: "component.ListProjectComponent.hasan.alkayed.mosque",
    type: "component.ListProjectComponent.schools",
    location: "component.ListProjectComponent.hasan.alkayed.location",
    partners: "component.ListProjectComponent.hasan.alkayed.partner",
    system: "component.ListProjectComponent.hasan.alkayed.system",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-5.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 11,
    img: "/assets/images/listProj-1.svg",
    title: "component.ListProjectComponent.alzahra.school.name",
    location: "component.ListProjectComponent.alzarha.school.location",
    type: "component.ListProjectComponent.schools",
    partners: "Oxfam",
    system: "component.ListProjectComponent.alzahra.school.system",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-1.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 12,
    img: "/assets/images/listProj-2.svg",
    title: "component.ListProjectComponent.umm.al-Adham.school",
    type: "component.ListProjectComponent.schools",
    location: "component.ListProjectComponent.umm.al-Adham.school.location",
    partners: "component.ListProjectComponent.umm.al-Adham.school.partner",
    system: "component.ListProjectComponent.umm.al-Adham.school.system",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-2.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 13,
    img: "/assets/images/listProj-3.svg",
    title: "component.ListProjectComponent.khirja.school",
    type: "component.ListProjectComponent.schools",
    location: "component.ListProjectComponent.khirja.school.location",
    partners: "component.ListProjectComponent.khirja.school.partner",
    system: "component.ListProjectComponent.khirja.school.system",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-3.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 14,
    img: "/assets/images/listProj-4.svg",
    title: "مدرسة العدنانية للبنات",
    type: "component.ListProjectComponent.schools",
    location: "تخدم هذه المدرسة منطقة حيوية في محافظة الكرك جنوبي الاردن",
    partners: "Oxfam",
    system: "نظام معالجة مياه رمادية لامركزي بسعة 1 متر مكعب يوميا",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-4.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 15,
    img: "/assets/images/listProj-5.svg",
    title: "component.ListProjectComponent.alfaysaliyah.school",
    type: "component.ListProjectComponent.schools",
    location: "component.ListProjectComponent.alfaysaliyah.school.location",
    partners: "component.ListProjectComponent.alfaysaliyah.school.partner",
    system: "component.ListProjectComponent.alfaysaliyah.school.system",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-5.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 16,
    img: "/assets/images/listProj-1.svg",
    title: "component.ListProjectComponent.umm.tufaila.school",
    type: "component.ListProjectComponent.schools",
    location: "component.ListProjectComponent.umm.tufaila.school.location",
    partners: "component.ListProjectComponent.umm.tufaila.school.partner",
    system: "component.ListProjectComponent.umm.tufaila.school.system",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-1.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 17,
    img: "/assets/images/listProj-2.svg",
    title: "component.ListProjectComponent.qafqafa.school",
    type: "component.ListProjectComponent.schools",
    location: "component.ListProjectComponent.qafqafa.school.location",
    partners: "component.ListProjectComponent.qafqafa.school.partner",
    system: "component.ListProjectComponent.qafqafa.school.system",
    systemType: SystemEnum.BLACKWATER,
    images: [
      { detail: "/assets/images/listProj-2.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 18,
    img: "/assets/images/listProj-3.svg",
    title: "component.ListProjectComponent.mughayyar.al-sarhan",
    type: "component.ListProjectComponent.schools",
    location: "component.ListProjectComponent.mughayyar.al-sarhan.location",
    partners: "component.ListProjectComponent.mughayyar.al-sarhan.partner",
    system: "component.ListProjectComponent.mughayyar.al-sarhan.system",
    systemType: SystemEnum.BLACKWATER,
    images: [
      { detail: "/assets/images/listProj-3.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 19,
    img: "/assets/images/listProj-4.svg",
    title: "component.ListProjectComponent.zaatari.school",
    type: "component.ListProjectComponent.schools",
    location: "component.ListProjectComponent.zaatari.school.location",
    partners: "component.ListProjectComponent.zaatari.school.partner",
    system: "component.ListProjectComponent.zaatari.school.system",
    systemType: SystemEnum.BLACKWATER,
    images: [
      { detail: "/assets/images/listProj-4.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 20,
    img: "/assets/images/listProj-5.svg",
    title: "component.ListProjectComponent.samma.school",
    type: "component.ListProjectComponent.schools",
    location: "component.ListProjectComponent.samma.school.location",
    partners: "component.ListProjectComponent.samma.school.partner",
    system: "component.ListProjectComponent.samma.school.system",
    systemType: SystemEnum.BLACKWATER,
    images: [
      { detail: "/assets/images/listProj-5.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 21,
    img: "/assets/images/listProj-1.svg",
    title: "مدرسة خديجة بنت خويلد",
    type: "component.ListProjectComponent.schools",
    location: "تقع هذه المدرسة في محافظة اربد الواقعة شمال الاردن",
    partners: "World Vision",
    system: "نظام معالجة مياه سوداء لامركزي بسعة 1.5 متر مكعب يوميا",
    systemType: SystemEnum.BLACKWATER,
    images: [
      { detail: "/assets/images/listProj-1.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 22,
    img: "/assets/images/listProj-2.svg",
    title: "​​مدرسة فاطمة الزهراء",
    type: "component.ListProjectComponent.schools",
    location: "تقع هذه المدرسة في محافظة اربد الواقعة شمال الاردن",
    partners: "World Vision",
    system: "نظام معالجة مياه سوداء لامركزي بسعة 1.5 متر مكعب يوميا",
    systemType: SystemEnum.BLACKWATER,
    images: [
      { detail: "/assets/images/listProj-2.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 23,
    img: "/assets/images/listProj-3.svg",
    title: "جامعة آل البيت",
    type: "component.ListProjectComponent.universities",
    location:
      "تقع هذه الجامعة على مشارف مدينة المفرق على بعد 65 كيلومتر من العاصمة",
    partners: "صندوق الملك عبدالله الثاني للتنمية",
    system: "نظام معالجة مياه سوداء لامركزي بسعة 1.5 متر مكعب يوميا",
    systemType: SystemEnum.BLACKWATER,
    images: [
      { detail: "/assets/images/listProj-3.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 24,
    img: "/assets/images/listProj-4.svg",
    title: "جمعية شابات المستقبل",
    type: "",
    location:
      "تخدم هذه الجمعية منطقة ريفية في منطقة نحلة التابعة لمحافظة جرش شمال الاردن",
    partners: "المركز الوطني للبحوث الزراعية، ICU",
    system: "نظام معالجة مياه رمادية لامركزي بسعة 1 متر مكعب يوميا",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-4.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 25,
    img: "/assets/images/listProj-5.svg",
    title: "جمعية وادي بني حماد",
    type: "",
    location: "تخدم هذه الجمعية منطقة ريفية تابعة لمحافظة الكرك جنوبي الاردن",
    partners: "المركز الوطني للبحوث الزراعية، ICU",
    system: "نظام معالجة مياه رمادية لامركزي بسعة 1 متر مكعب يوميا",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-5.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 26,
    img: "/assets/images/listProj-1.svg",
    title: "component.ListProjectComponent.government.facilities",
    type: "component.ListProjectComponent.government.facilities",
    location: "يقع في ديرعلا التابعة لمحافظة البلقاء",
    partners: "لمركز الوطني للبحوث الزراعية، ICU",
    system: "نظام معالجة مياه رمادية لامركزي بسعة 1 متر مكعب يوميا",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-1.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 27,
    img: "/assets/images/listProj-2.svg",
    title: "نزل بيت جدي",
    type: "component.ListProjectComponent.houses",
    location:
      "يخدم النزل منطقة ريفية سياحية تقع في الجزازة شمال الاردن في محافظة جرش",
    partners: "لمركز الوطني للبحوث الزراعية، ICU ",
    system: "نظام معالجة مياه رمادية لامركزي بسعة 1 متر مكعب يوميا",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-2.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 28,
    img: "/assets/images/listProj-3.svg",
    title: "component.ListProjectComponent.project.in.jarash",
    type: "",
    location: "component.ListProjectComponent.project.in.jarash.location",
    partners: "component.ListProjectComponent.project.in.jarash.partenr",
    system: "component.ListProjectComponent.project.in.jarash.system",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-3.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
  {
    id: 29,
    img: "/assets/images/listProj-4.svg",
    title: "component.ListProjectComponent.project.in.irbid",
    type: "",
    location: "component.ListProjectComponent.project.in.irbid.location",
    partners: "component.ListProjectComponent.project.in.irbid.partenr",
    system: "component.ListProjectComponent.project.in.irbid.system",
    systemType: SystemEnum.GRAYWATER,
    images: [
      { detail: "/assets/images/listProj-4.svg" },
      { detail: "/assets/images/detailProj-2.png" },
    ],
  },
];
