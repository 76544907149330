import { Layout } from "src/components/common/layout/layout";
import TrainingAndDevelopmentContainer from "src/containers/user/training-and-development-container/training-and-development-container";

export default function TrainingAndDevelopmentPage() {
  return (
    <Layout>
      <div>
        {" "}
        <TrainingAndDevelopmentContainer />
      </div>
    </Layout>
  );
}
