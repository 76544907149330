import React from "react";
import { FaCircle } from "react-icons/fa";
import { IProjGall } from "src/core/types/user.type";

interface ImageGalleryComponentProp {
  slides: IProjGall[];
}
export default function ImageGalleryComponent(prop: ImageGalleryComponentProp) {
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  const goToSlide = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div
      style={{ backgroundImage: `url(${prop.slides[currentIndex].detail})` }}
      className={`flex justify-center items-center content-center w-full h-full mobile:h-full relative flex-col bg-center bg-cover duration-500`}
    >
      {/* dots */}
      <div className="flex flex-row gap-3 bg-blueImage h-20 mobile:h-20 justify-center items-center absolute py-2 w-full bottom-0">
        {prop.slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className="cursor-pointer"
          >
            <FaCircle
              className={`${
                currentIndex === slideIndex ? "text-lightBlue" : "text-white"
              }`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
