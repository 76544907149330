import React from "react";
import { NavigateFunction } from "react-router-dom";
import { SystemEnum } from "src/core/enums/example.enum";

import SystemWaterComponent from "../system-project-component/system-project-component";

interface TabsComponentProps {
  valuePlace: string;
  setValuePlace: React.Dispatch<React.SetStateAction<string>>;
  openTab: string;
  setOpenTab: React.Dispatch<React.SetStateAction<string>>;
  navigate: NavigateFunction;
  setSelectedSystem: React.Dispatch<
    React.SetStateAction<SystemEnum | string | undefined>
  >;
}
export default function TabsComponent(props: TabsComponentProps) {
  return (
    <div className="gap-8 mobile:mt-6 mobile:flex-col">
      <SystemWaterComponent
        setSelectedSystem={props.setSelectedSystem}
        openTab={props.openTab}
        setOpenTab={props.setOpenTab}
        navigate={props.navigate}
      />
    </div>
  );
}
