import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BreadCrumbsComponent from "src/components/common/news-common-component/bread-crumbs-component";
import NewsDetailsComponent from "src/components/common/news-common-component/details-news-component";
import BackGroundTitleComponent from "src/components/user/back-ground-title-component/back-ground-title-component";
import { newsMockData } from "src/core/statics/news-mock-data/news";

export default function NewDetailsContainer() {
  const { t } = useTranslation();
  const { id = "" } = useParams();
  const dummyData = newsMockData;
  const newData = dummyData.find((a) => a.id.toString() === id);

  return (
    <div>
      <div className="h-24 mobile:h-0"></div>
      <BackGroundTitleComponent
        pading="px-20 laptop:px-6"
        title={t("app.shared.news")}
      />
      <div className="mx-20 laptop:mx-6 my-10 mobile:my-6 mobile:mx-0 flex flex-col gap-8">
        <div className="mobile:hidden tablet:hidden">
          <BreadCrumbsComponent newName={newData?.title} />
        </div>
        <NewsDetailsComponent img={newData!.gallary} data={dummyData} />
      </div>
    </div>
  );
}
