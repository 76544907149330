import Ajlun from "../goverments/Ajlun";
import Amman from "../goverments/Amman";
import Aqaba from "../goverments/Aqaba";
import Balqa from "../goverments/Balqa";
import Irbid from "../goverments/Irbid";
import Jarash from "../goverments/Jarash";
import Karak from "../goverments/Karak";
import Maan from "../goverments/Maan";
import Madaba from "../goverments/Madaba";
import Mafraq from "../goverments/Mafraq";
import Tafilah from "../goverments/Tafilah";
import Zarqa from "../goverments/Zarqa";

interface MapProps {
  onCityEnter: (city: string) => void;
  activeCity: string;
}
export default function MapComponent({ onCityEnter, activeCity }: MapProps) {
  const handelHoverIn = function (e: React.MouseEvent<SVGElement>) {
    const city = e.currentTarget.getAttribute("name") as string;
    onCityEnter(city);
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#173158"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      height={"100%"}
      viewBox="0 0 1000 1000"
      className="w-[200%] mobile:w-[100%] tablet:w-[100%]"
    >
      <Madaba
        onMouseOver={handelHoverIn}
        className={`hover:fill-lightBlue transition-colors ${
          activeCity === "Madaba" ? "fill-cyan" : null
        }`}
      />
      <Irbid
        onMouseOver={handelHoverIn}
        className={`hover:fill-lightBlue transition-colors ${
          activeCity === "Irbid" ? "fill-cyan" : null
        }`}
      />
      <Tafilah
        onMouseOver={handelHoverIn}
        className={`hover:fill-lightBlue transition-colors ${
          activeCity === "Tafilah" ? "fill-cyan" : null
        }`}
      />
      <Aqaba
        onMouseOver={handelHoverIn}
        className={`hover:fill-lightBlue transition-colors ${
          activeCity === "Aqaba" ? "fill-cyan" : null
        }`}
      />
      <Balqa
        onMouseOver={handelHoverIn}
        className={`hover:fill-lightBlue transition-colors ${
          activeCity === "Balqa" ? "fill-cyan" : null
        }`}
      />
      <Mafraq
        onMouseOver={handelHoverIn}
        className={`hover:fill-lightBlue transition-colors ${
          activeCity === "Mafraq" ? "fill-cyan" : null
        }`}
      />
      <Karak
        onMouseOver={handelHoverIn}
        className={`hover:fill-lightBlue transition-colors ${
          activeCity === "Karak" ? "fill-cyan" : null
        }`}
      />
      <Maan
        onMouseOver={handelHoverIn}
        className={`hover:fill-lightBlue transition-colors ${
          activeCity === "Ma`an" ? "fill-cyan" : null
        }`}
      />
      <Amman
        onMouseOver={handelHoverIn}
        className={`hover:fill-lightBlue transition-colors ${
          activeCity === "Amman" ? "fill-cyan" : null
        }`}
      />
      <Zarqa
        onMouseOver={handelHoverIn}
        className={`hover:fill-lightBlue transition-colors ${
          activeCity === "Zarqa" ? "fill-cyan" : null
        }`}
      />
      <Ajlun
        onMouseOver={handelHoverIn}
        className={`hover:fill-lightBlue transition-colors ${
          activeCity === "Ajlun" ? "fill-cyan" : null
        }`}
      />
      <Jarash
        onMouseOver={handelHoverIn}
        className={`hover:fill-lightBlue transition-colors ${
          activeCity === "Jarash" ? "fill-cyan" : null
        }`}
      />
      <circle
        cx={137.1}
        cy={910}
        className="jo_svg__29.399037437882072|35.16651581589536"
      />
      <circle
        cx={540.3}
        cy={550.4}
        className="jo_svg__31.071731183332716|37.33782282591918"
      />
      <circle
        cx={862.9}
        cy={91.9}
        className="jo_svg__33.162598365146025|39.07486843393824"
      />
      <circle cx={252.3} cy={225.3} className="jo_svg__Irbid" />
      <circle cx={232.4} cy={444.1} className="jo_svg__Madaba" />
      <circle cx={237.6} cy={534.6} className="jo_svg__Karak" />
      <circle cx={222.7} cy={611.7} className="jo_svg__Tafilah" />
      <circle cx={166.6} cy={859.5} className="jo_svg__Aqaba" />
      <circle cx={229.5} cy={343.6} className="jo_svg__Balqa" />
      <circle cx={669.7} cy={266.1} className="jo_svg__Mafraq" />
      <circle cx={416.4} cy={670.9} className="jo_svg__Ma`an" />
      <circle cx={352.6} cy={447.8} className="jo_svg__Amman" />
      <circle cx={489.3} cy={411.6} className="jo_svg__Zarqa" />
      <circle cx={243.2} cy={286} className="jo_svg__Ajlun" />
      <circle cx={271.5} cy={304.1} className="jo_svg__Jarash" />
    </svg>
  );
}
