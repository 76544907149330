import { useTranslation } from "react-i18next";
import { AiOutlineInstagram } from "react-icons/ai";
import {
  FaArrowLeft,
  FaArrowRight,
  FaLinkedinIn,
  FaXTwitter,
} from "react-icons/fa6";
import { LuFacebook } from "react-icons/lu";
import { Link } from "react-router-dom";

export default function FooterComponent() {
  const { i18n, t } = useTranslation();

  return (
    <footer className="bg-cyan py-24 mobile:py-12 tablet:py-12 w-full bg-[url('../public/assets/images/background-footer.png')] bg-no-repeat bg-contain mobile:bg-bottom tablet:bg-bottom tablet:bg-[url('../public/assets/images/background-footer-mobile.png')] mobile:bg-[url('../public/assets/images/background-footer-mobile.png')]">
      <div className="grid grid-cols-3 tablet:grid-cols-1 mobile:grid-cols-1 justify-items-center items-start">
        <div className="flex-col items-center justify-center mobile:order-2 tablet:order-2">
          <h1 className="text-white text-[32px] py-4 font-arRegular text-center">
            {t("component.FooterComponent.contactUs")}
          </h1>
          <div className="relative">
            {i18n.language === "en" ? (
              <FaArrowRight className="absolute right-3 top-1/2 -translate-y-1/2 text-cyan" />
            ) : (
              <FaArrowLeft className="absolute left-3 top-1/2 -translate-y-1/2 text-cyan" />
            )}
            <input
              dir={i18n.language === "en" ? "ltr" : "rtl"}
              type="text"
              placeholder={`${t("component.FooterComponent.enterYourEmail")}`}
              className="placeholder:text-cyan text-cyan placeholder:text-[11px] w-96 laptop:w-72 mobile:w-72 py-2 rounded-lg px-2 focus:outline-none font-verdana"
            />
          </div>
        </div>
        <div className="flex-col items-center justify-center mobile:order-1 tablet:order-1">
          <h1 className="text-white text-[32px] text-center font-arExtraLight py-4">
            {t("component.FooterComponent.address")}
            <div className="text-white whitespace-nowrap text-[19px] font-verdana">
              {t("component.FooterComponent.addressDetails")}
            </div>
            <div className="text-white whitespace-nowrap text-base pt-14 font-verdana mobile:hidden tablet:hidden">
              {t("component.FooterComponent.allRightsReserved")}
            </div>
          </h1>
        </div>
        <div className="flex-col items-center justify-center mobile:order-2 tablet:order-2">
          <h1 className="text-white text-[32px] text-center py-2 font-arExtraLight font-semibold">
            {t("component.FooterComponent.followUsOn")}
          </h1>
          <div className="flex-row flex justify-center gap-x-6">
            <Link to={"https://www.facebook.com/SOLVillion/"}>
              <LuFacebook
                size={24}
                className="text-white hover:text-teal-400 duration-300
          cursor-pointer inline-flex"
              />
            </Link>

            <Link to={"https://www.instagram.com/solvillion_co/?hl=en"}>
              <AiOutlineInstagram
                size={24}
                className="text-white hover:text-teal-400 duration-300
           cursor-pointer inline-flex"
              />
            </Link>
            <Link to={"https://www.linkedin.com/company/solvillion-co"}>
              <FaLinkedinIn
                size={24}
                className="text-white hover:text-teal-400 duration-300
         cursor-pointer inline-flex"
              />{" "}
            </Link>
            <Link to={"https://twitter.com/solvillion"}>
              <FaXTwitter
                size={24}
                className="text-white hover:text-teal-400 duration-300
         cursor-pointer inline-flex"
              />
            </Link>
          </div>
          <div className="justify-between gap-4 mobile:gap-24 tablet:gap-24 flex-row flex text-white pt-16">
            <span className="hover:text-darkBlue text-base font-verdana">
              {t("component.FooterComponent.terms")}
            </span>
            <span className="hover:text-darkBlue text-base font-verdana">
              {t("component.FooterComponent.privacyPolicy")}
            </span>
          </div>
          <div className="text-white whitespace-nowrap text-base pt-14 text-center font-verdana desktop:hidden largeLaptop:hidden laptop:hidden largeDesktop:hidden">
            {t("component.FooterComponent.allRightsReserved")}
          </div>
        </div>
      </div>
    </footer>
  );
}
