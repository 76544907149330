import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import TitleComponent from "../../title-component/title-component";
import MapComponent from "../map-component/map-component";

export default function JordanMapComponent() {
  const { i18n, t } = useTranslation();
  const [activeCity, setActiveCity] = useState<string>("");

  return (
    <div className="py-32 flex flex-col justify-center items-center largeDesktop:px-32 mobile:pt-20 mobile:pb-3 mobile:px-5 mobile:h-fit mobile:space-y-6 mobile:flex-col mobile:justify-start mobile:items-center tablet:py-20 tablet:px-5 tablet:h-[70vh] tablet:space-y-6 tablet:flex-col tablet:justify-start tablet:items-center">
      <div>
        <TitleComponent
          title={t("component.JordanMapComponent.title")}
          width={24}
          widthMobile={24}
        />
      </div>
      <div className="gap-0 flex justify-between items-center mobile:flex-col mobile:w-full mobile:gap-0 tablet:flex-col tablet:w-full tablet:gap-0">
        <div className="flex w-full h-full justify-center items-center mobile:h-auto">
          <div
            className={` ${
              i18n.language == "en"
                ? "mr-40 tablet:mr-0 mobile:mr-0"
                : "ml-60 tablet:ml-0 mobile:ml-0"
            }   z-20 relative mobile:pr-0 mobile:inset-0`}
          >
            <MapComponent onCityEnter={setActiveCity} activeCity={activeCity} />
          </div>
          <img
            src="/assets/images/jordan-map-component.png"
            alt=""
            className="absolute w-[1060px] h-[548px] pt-5 mobile:pt-5 mobile:h-[318.375px] mobile:w-[430px] tablet:pt-5 tablet:h-[318.375px] tablet:w-[430px]"
          />
        </div>
        <div
          className={`absolute z-10 mobile:z-10 mobile:static px-16 mobile:px-0 tablet:relative tablet:px-0 ${
            i18n.language == "en"
              ? "left-0 tablet:-pt-0"
              : "right-0 tablet:pt-8"
          }`}
        >
          {activeCity === "Amman" ? (
            <div className="flex flex-col gap-y-24 w-full tablet:w-[400px] mt-3 mobile:pt-0 mobile:mt-0 mobile:flex-row mobile:gap-y-0 mobile:gap-x-4 tablet:pt-0 tablet:mt-0 tablet:flex-row tablet:gap-y-0 tablet:gap-x-4">
              <div className="flex flex-col p-4 justify-center mobile:items-center tablet:items-center items-end px-4 gap-3 rounded-xl bg-whiteSolv space-y-3 w-[472px] laptop:w-[340px] space-x-3 mb-1 mobile:w-full mobile:mb-2 text-[13px]">
                <h3 className="text-2xl text-lightBlue font-arBold w-full mobile:text-center tablet:text-center mobile:text-sm tablet:text-sm">
                  {t("component.JordanMapComponent.balqa.title1")}
                </h3>
                <p className="text-cyan mobile:text-[13px] tablet:text-[13px] text-[23px] font-normal font-sans mobile:text-start tablet:text-center mobile:font-normal tablet:font-normal mobile:font-sans mobile:leading-[18.20px]">
                  {t("component.JordanMapComponent.balqa.description1")}
                </p>
              </div>
              <div className="flex flex-col p-4 justify-center mobile:items-center tablet:items-center items-end px-4 gap-3 rounded-xl bg-whiteSolv space-y-3 w-[472px] laptop:w-[340px] space-x-3 mb-1 mobile:w-full mobile:mb-2 text-[13px]">
                <h3 className="text-2xl text-lightBlue font-arBold w-full mobile:text-center tablet:text-center mobile:text-sm tablet:text-sm">
                  {t("component.JordanMapComponent.balqa.title2")}
                </h3>
                <p className="text-cyan mobile:text-[13px] tablet:text-[13px] text-[23px] font-normal font-sans mobile:text-start tablet:text-center mobile:font-normal tablet:font-normal mobile:font-sans mobile:leading-[18.20px]">
                  {t("component.JordanMapComponent.balqa.description1")}
                </p>
              </div>
            </div>
          ) : null}
          {activeCity === "Ajlun" ? (
            <div className="flex flex-col gap-y-24 w-full tablet:w-[400px] mt-3 mobile:pt-0 mobile:mt-0 mobile:flex-row mobile:gap-y-0 mobile:gap-x-4 tablet:pt-0 tablet:mt-0 tablet:flex-row tablet:gap-y-0 tablet:gap-x-4">
              <div className="flex flex-col p-4 justify-center mobile:items-center tablet:items-center items-end px-4 gap-3 rounded-xl bg-whiteSolv space-y-3 w-[472px] laptop:w-[340px] space-x-3 mb-1 mobile:w-full mobile:mb-2 text-[13px]">
                <h3 className="text-2xl text-lightBlue font-arBold w-full mobile:text-center tablet:text-center mobile:text-sm tablet:text-sm">
                  {t("component.JordanMapComponent.Ajlun.title1")}
                </h3>
                <p className="text-cyan mobile:text-[13px] tablet:text-[13px] text-[23px] font-normal font-sans mobile:text-start tablet:text-center mobile:font-normal tablet:font-normal mobile:font-sans mobile:leading-[18.20px]">
                  {t("component.JordanMapComponent.amman.description1")}
                </p>
              </div>
              <div className="flex flex-col p-4 justify-center mobile:items-center tablet:items-center items-end px-4 gap-3 rounded-xl bg-whiteSolv space-y-3 w-[472px] laptop:w-[340px] space-x-3 mb-1 mobile:w-full mobile:mb-2 text-[13px]">
                <h3 className="  text-2xl text-lightBlue font-arBold w-full mobile:text-center tablet:text-center mobile:text-sm tablet:text-sm">
                  {t("component.JordanMapComponent.Ajlun.title2")}
                </h3>
                <p className="text-cyan mobile:text-[13px] tablet:text-[13px] text-[23px] font-normal font-sans mobile:text-start tablet:text-center mobile:font-normal tablet:font-normal mobile:font-sans mobile:leading-[18.20px]">
                  {t("component.JordanMapComponent.Ajlun.description2")}
                </p>
              </div>
            </div>
          ) : null}
          {activeCity === "Jarash" ? (
            <div className="flex flex-col gap-y-24 w-full tablet:w-[400px] mt-3 mobile:pt-0 mobile:mt-0 mobile:flex-row mobile:gap-y-0 mobile:gap-x-4 tablet:pt-0 tablet:mt-0 tablet:flex-row tablet:gap-y-0 tablet:gap-x-4">
              <div className="flex flex-col p-4 justify-center mobile:items-center tablet:items-center items-end px-4 gap-3 rounded-xl bg-whiteSolv space-y-3 w-[472px] laptop:w-[340px] space-x-3 mb-1 mobile:w-full mobile:mb-2 text-[13px]">
                <h3 className="text-2xl text-lightBlue font-arBold w-full mobile:text-center tablet:text-center mobile:text-sm tablet:text-sm">
                  {t("component.JordanMapComponent.amman.title2")}
                </h3>
                <p className="text-cyan mobile:text-[13px] tablet:text-[13px] text-[23px] font-normal font-sans mobile:text-start tablet:text-center mobile:font-normal tablet:font-normal mobile:font-sans mobile:leading-[18.20px]">
                  {t("component.JordanMapComponent.amman.description2")}
                </p>
              </div>
              <div className="flex flex-col p-4 justify-center mobile:items-center tablet:items-center items-end px-4 gap-3 rounded-xl bg-whiteSolv space-y-3 w-[472px] laptop:w-[340px] space-x-3 mb-1 mobile:w-full mobile:mb-2 text-[13px]">
                <h3 className="text-2xl text-lightBlue font-arBold w-full mobile:text-center tablet:text-center mobile:text-sm tablet:text-sm">
                  {t("component.JordanMapComponent.amman.title1")}
                </h3>
                <p className="text-cyan mobile:text-[13px] tablet:text-[13px] text-[23px] font-normal font-sans mobile:text-start tablet:text-center mobile:font-normal tablet:font-normal mobile:font-sans mobile:leading-[18.20px]">
                  {t("component.JordanMapComponent.amman.description1")}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
