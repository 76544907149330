import { Layout } from "src/components/common/layout/layout";
import PaymentInformationContainer from "src/containers/user/order-here-container/payment-information-container/payment-information-container";

function PaymentInformationPage() {
  return (
    <Layout>
      <div>
        <PaymentInformationContainer />;
      </div>
    </Layout>
  );
}

export default PaymentInformationPage;
