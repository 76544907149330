import { t } from "i18next";
import i18n from "src/core/configs/i18n";
interface Iprops {
  title: string;
  mobileHighet?: string;
  pading?: string;
}
function BackGroundTitleComponent(props: Iprops) {
  return (
    <div
      className={`w-full h-[379px] mobile:h-20 tablet:h-32 tablet:mt-[60px] mobile:pt-5 mobile:items-center flex justify-between items-end`}
    >
      <p
        className={`mobile:w-full text-5xl mb-[30px] mobile:mb-0 mobile:pt-6 ${props.pading} mobile:text-[32px] tablet:text-2xl text-sky-500 mobile:px-4 font-arBold`}
      >
        {t(props.title)}
      </p>
      <img
        src="/assets/images/back-ground.png"
        className="h-[479.84px] w-[534px] tablet:w-[400px] mobile:hidden"
        alt=""
      />
      <img
        src="/assets/images/back-ground-mobile.png"
        className={`w-[180px]  ${props.mobileHighet} laptop:hidden absolute ${
          i18n.language === "ar" ? "left-0" : "right-0"
        } largeLaptop:hidden desktop:hidden largeDesktop:hidden tablet:hidden
         `}
        alt=""
      />
    </div>
  );
}

export default BackGroundTitleComponent;
